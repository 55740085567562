import { FC, useState } from 'react';
import { TuneOutlined } from '@mui/icons-material';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import TeamManagementDialog from './TeamManagementDialog';

export interface IAgentRosterHeaderProps {
  handleViewPlan: () => void;
  handleOpenFilterModal: () => void;
}

const AgentRosterHeader: FC<IAgentRosterHeaderProps> = (props) => {
  const { handleViewPlan, handleOpenFilterModal } = props;
  const [manageTeamDialogOpen, setManageTeamDialogOpen] = useState(false);

  const handleOpenManageTeamDialog = () => {
    setManageTeamDialogOpen(true);
  };

  const handleCloseManageTeamDialog = () => {
    setManageTeamDialogOpen(false);
  };

  return (
    <Grid container spacing={3} justifyContent="space-between" sx={{ mb: 4 }}>
      <Grid item>
        <Typography variant="h2">Agent Roster</Typography>
      </Grid>
      <Grid item></Grid>
      <Grid
        item
        sx={{ p: 0, paddingLeft: '0px !important', paddingTop: '0px !important' }}
        alignContent={'center'}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button size="small" variant="contained" onClick={() => handleViewPlan()}>
              View plan
            </Button>
          </Grid>
          <Grid item>
            <Button size="small" variant="contained" onClick={() => handleOpenManageTeamDialog()}>
              Manage teams
            </Button>
          </Grid>

          <Grid item>
            <IconButton onClick={handleOpenFilterModal}>
              <TuneOutlined sx={{ color: '#D9D9D9' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <TeamManagementDialog onClose={handleCloseManageTeamDialog} open={manageTeamDialogOpen} />
    </Grid>
  );
};

export default AgentRosterHeader;
