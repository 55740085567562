//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { motion, AnimatePresence } from 'framer-motion';
import DashboardIcon from '../../assets/icons/sidenav-dashboard-icon.svg';
import JobcardIcon from '../../assets/icons/sidenav-jobcard-icon.svg';
import CustomersIcon from '../../assets/icons/sidenav-customer-icon.svg';
import TechnicianIcon from '../../assets/icons/sidenav-technician-icon.svg';
import AgentRosterIcon from '../../assets/icons/sidenav-agent-roster-icon.svg';
import ServicesIcon from '../../assets/icons/sidenav-services-icon.svg';
import ClientsIcon from '../../assets/icons/sidenav-clients-icon.svg';
import TeleCMIIcon from '../../assets/icons/sidenav-telecmi-icon.svg';
import SettingsIcon from '../../assets/icons/sidenav-settings-icon.svg';
import { AbilityContext } from 'common/casl/abilityContext';
import { SUBJECTS } from 'common/casl/ability';
import { useAbility } from '@casl/react';

const sideNavItems = [
  {
    title: 'Dashboard',
    icon: DashboardIcon,
    path: '/',
    key: 'dashboard',
    permission: SUBJECTS.HOME
  },
  {
    title: 'Jobcard',
    icon: JobcardIcon,
    path: '/jobs',
    key: 'jobcard',
    permission: SUBJECTS.JOBS,
    children: [
      {
        title: 'Jobs',
        path: '/jobs',
        key: 'jobs',
        permission: SUBJECTS.JOBS
      },
      {
        title: 'Inspection',
        path: '/jobs/inspections',
        key: 'inspections',
        permission: SUBJECTS.INSPECTIONS
      },
      {
        title: 'Agent Dashboard',
        path: '/jobs/agent',
        key: 'agent',
        permission: SUBJECTS.AGENT_DASHBOARD
      }
    ]
  },
  {
    title: 'Customer',
    icon: CustomersIcon,
    path: '/customers',
    key: 'customer',
    permission: SUBJECTS.CUSTOMERS
  },
  {
    title: 'Technician',
    icon: TechnicianIcon,
    path: '/technicians',
    key: 'technician',
    permission: SUBJECTS.TECHNICIANS
  },
  {
    title: 'CRM Leads',
    icon: AgentRosterIcon,
    path: '/crm',
    key: 'crm',
    permission: SUBJECTS.CRM
  },
  {
    title: 'Agent Roster',
    icon: AgentRosterIcon,
    path: '/agent-roster',
    key: 'agent-roster',
    permission: SUBJECTS.SHIFTS
  },
  {
    title: 'Services',
    icon: ServicesIcon,
    path: '/services-category',
    key: 'services-categories',
    permission: SUBJECTS.SERVICE_CATEGORIES
  },
  {
    title: 'Client/Partners',
    icon: ClientsIcon,
    path: '/clients-and-partners',
    key: 'clients-and-partners',
    permission: SUBJECTS.CLIENTS
  },
  {
    title: 'Payments',
    icon: ClientsIcon,
    path: '/payments',
    key: 'payments',
    permission: SUBJECTS.PAYMENTS,
    children: [
      //customer payments & technician payments
      {
        title: 'Customer Payments',
        path: '/payments/customer',
        key: 'customer-payments',
        permission: SUBJECTS.PAYMENTS
      },
      {
        title: 'Technician Payments',
        path: '/payments/technician',
        key: 'technician-payments',
        permission: SUBJECTS.PAYMENTS
      }
    ]
  },
  {
    title: 'TeleCMI',
    icon: TeleCMIIcon,
    path: '/telecmi',
    key: 'telecmi',
    permission: SUBJECTS.TELECMI
  },
  {
    title: 'Report',
    icon: ClientsIcon,
    path: '/reports',
    key: 'reports',
    permission: SUBJECTS.REPORTS,
    children: [
      {
        title: 'Overview',
        path: '/reports',
        key: 'overview',
        permission: SUBJECTS.REPORTS
      },
      {
        title: 'Earnings',
        path: '/reports/earnings',
        key: 'earnings',
        permission: SUBJECTS.REPORTS
      },
      {
        title: 'KPI',
        path: '/reports/kpi',
        key: 'kpi',
        permission: SUBJECTS.KPI
      },
      {
        title: 'Monthwise',
        path: '/reports/monthwise',
        key: 'monthwise',
        permission: SUBJECTS.MONTHLY_WISE_REPORT
      }
    ]
  },
  {
    title: 'Settings',
    icon: SettingsIcon,
    path: '/settings',
    key: 'settings',
    permission: SUBJECTS.SETTINGS,
    children: [
      {
        title: 'Configuration',
        path: '/settings',
        key: 'configuration',
        permission: SUBJECTS.SETTINGS
      },
      {
        title: 'Agreements',
        path: '/settings/agreements',
        key: 'agreements',
        permission: SUBJECTS.AGREEMENTS
      },
      {
        title: 'Shift Management',
        path: '/settings/shifts',
        key: 'shifts',
        permission: SUBJECTS.SHIFTS
      },
      {
        title: 'Vehicles',
        path: '/settings/vehicles',
        key: 'vehicles',
        permission: SUBJECTS.VEHICLES
      },
      {
        title: 'Vehicles Brand',
        path: '/settings/vehicles-brand',
        key: 'vehicles-brand',
        permission: SUBJECTS.VEHICLE_BRANDS
      },
      {
        title: 'Document',
        path: '/settings/document',
        key: 'document',
        permission: SUBJECTS.DOCUMENTS
      },
      {
        title: 'Push Notification',
        path: '/settings/push-notification',
        key: 'push-notification',
        permission: SUBJECTS.PUSH_NOTIFICATIONS
      },
      {
        title: 'Banners',
        path: '/settings/banners',
        key: 'banners',
        permission: SUBJECTS.BANNERS
      },
      {
        title: 'Staffs',
        path: '/staffs',
        key: 'staffs',
        permission: SUBJECTS.USERS
      },
      {
        title: 'Clients/Partners',
        path: '/settings/clients-partners',
        key: 'Clients-Partners',
        permission: SUBJECTS.USERS
      },
      {
        title: 'Languages',
        path: '/settings/languages',
        key: 'languages',
        permission: SUBJECTS.LANGUAGES
      },
      {
        title: 'States',
        path: '/settings/states',
        key: 'states',
        permission: SUBJECTS.STATES
      },
      {
        title: 'Subscription Plans',
        path: '/settings/subscription-plans',
        key: 'subscription-plans',
        permission: SUBJECTS.SETTINGS
      }
    ]
  }
];

const SidebarMenu = () => {
  const [menuList, setMenuList] = useState<any>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [activeChildKey, setActiveChildKey] = useState<string | null>(null);
  const ability = useAbility(AbilityContext);
  const navigate = useNavigate();
  const location = useLocation();

  const activeGradient = 'linear-gradient(90deg, #005278 23%, #009BDE 95%)';

  const updateMenuItems = () => {
    const filteredMenuList = sideNavItems
      .map((item) => {
        const filteredChildren = item.children
          ? item.children.filter((child) => ability.can('READ', child.permission || ''))
          : null;

        if (
          ability.can('READ', item.permission || '') ||
          (filteredChildren && filteredChildren.length > 0)
        ) {
          return {
            ...item,
            children: filteredChildren
          };
        }
        return null;
      })
      .filter(Boolean);

    setMenuList(filteredMenuList);
  };

  useEffect(() => {
    updateMenuItems();
  }, [ability.rules?.length, ability]);

  useEffect(() => {
    let foundActive = false;

    menuList.forEach((item: any) => {
      if (item.children) {
        const activeChild = item.children.find((child: any) => child.path === location.pathname);
        if (activeChild) {
          setActiveKey(item.key);
          setActiveChildKey(activeChild.key);
          setOpenKeys([item.key]);
          foundActive = true;
        }
      } else if (item.path === location.pathname) {
        setActiveKey(item.key);
        setActiveChildKey(null);
        foundActive = true;
      }
    });

    if (!foundActive) {
      setActiveKey(null);
      setActiveChildKey(null);
      setOpenKeys([]);
    }
  }, [location.pathname, menuList]);

  const handleNavigation = (path: string, parentKey: string, childKey?: string) => {
    navigate(path);
    setActiveKey(parentKey);
    setActiveChildKey(childKey || null);

    if (!childKey) {
      setOpenKeys([]);
    }
  };

  const toggleMenu = (key: string, children?: any[]) => {
    if (!openKeys.includes(key)) {
      setOpenKeys([key]);

      if (children && children.length > 0) {
        const firstChild = children[0];
        handleNavigation(firstChild.path, key, firstChild.key);
      }
    } else {
      setOpenKeys(openKeys.filter((k) => k !== key));
    }
  };

  return (
    <div style={{ width: '250px', background: '#f9f9f9', padding: '10px', userSelect: 'none' }}>
      {menuList.map((item: any) => (
        <motion.div
          key={item.key}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '10px',
              cursor: 'pointer',
              borderRadius: '12px',
              background: activeKey === item.key ? activeGradient : 'transparent',
              color: activeKey === item.key ? '#FFFFFF' : '#333333',
              transition: 'background 0.3s ease-in-out',
              marginBottom: '.7rem',
            }}
            onClick={() =>
              item.children
                ? toggleMenu(item.key, item.children)
                : handleNavigation(item.path, item.key)
            }>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: '35px',
                  height: '35px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '12px',
                  background: '#FFFFFF',
                  boxShadow: '3px 4px 23px rgba(0, 0, 0, 0.1)'
                }}>
                <img src={item.icon} alt={item.title} style={{ width: '35px', height: '35px' }} />
              </div>
              <span
                style={{
                  marginLeft: '10px',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '21px',
                  fontFamily: 'OpenSans'
                }}>
                {item.title}
              </span>
            </div>
            <motion.div
              animate={{
                rotate: item.children && openKeys.includes(item.key) ? 90 : 0
              }}
              transition={{ duration: 0.3 }}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ChevronRightIcon />
            </motion.div>
          </div>
          {item.children && openKeys.includes(item.key) && (
            //@ts-ignore
            <AnimatePresence>
              <motion.div
                key={`${item.key}-children`}
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                style={{
                  overflow: 'hidden',
                  paddingLeft: '30px',
                  marginTop: '5px'
                }}>
                {item.children.map((child: any) => (
                  <motion.div
                    key={child.key}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -10 }}
                    transition={{ duration: 0.3 }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '5px 0',
                      cursor: 'pointer',
                      fontSize: '12px',
                      fontWeight: activeChildKey === child.key ? 600 : 400,
                      color: activeChildKey === child.key ? '#005278' : '#555',
                      transition: 'color 0.3s ease-in-out'
                    }}
                    onClick={() => handleNavigation(child.path, item.key, child.key)}>
                    <span
                      style={{
                        width: '6px',
                        height: '6px',
                        borderRadius: '50%',
                        backgroundColor: '#555',
                        marginRight: '10px',
                        fontFamily: 'OpenSans'
                      }}></span>
                    {child.title}
                  </motion.div>
                ))}
              </motion.div>
            </AnimatePresence>
          )}
        </motion.div>
      ))}
    </div>
  );
};

export default SidebarMenu;
