import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_LIMIT } from 'utils/constants';
import { useListLanguagesQuery } from 'app/services/language';
import { selectAllLanguage, setLanguageData } from 'pages/languages/languageSlice';

const useLanguageList = () => {
  const [total, setTotal] = useState(DEFAULT_LIMIT);
  const dispatch = useDispatch();

  const { data, isLoading } = useListLanguagesQuery({
    limit: total,
    offset: 0
  });

  useEffect(() => {
    if (data) {
      dispatch(
        setLanguageData({
          total: data?.total,
          items: data?.items
        })
      );
      setTotal(data?.total > DEFAULT_LIMIT ? data?.total : DEFAULT_LIMIT);
    }

    return () => {};
  }, [dispatch, data]);

  const languageList = useSelector(selectAllLanguage);

  return { languageList, languageLoading: isLoading };
};

export default useLanguageList;
