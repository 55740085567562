import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

type AdminLoginType = {
  adminTypeID: number;
  adminTypeLabel: string;
  enabled: 'Y' | 'N';
};

export type AdminLoginUser = {
  adminID: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  phone: string;
  adminOldID: string | null;
  adminPhone: string | null;
  enabled: 'Y' | 'N';
  adminTypeID: number;
  AdminTypeID: number;
  adminType: AdminLoginType;
};

type AdminLoginResponseDTO = {
  user: AdminLoginUser | null;
  accessToken: string | null;
};

// initialize token from local storage
const accessToken = localStorage.getItem('accessToken') ?? null;
const user = localStorage.getItem('user') ?? null;

const initialState = {
  user: user,
  accessToken
} as unknown as AdminLoginResponseDTO;

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      state,
      { payload: { user, accessToken } }: PayloadAction<{ user: any; accessToken: string }>
    ) => {
      state.user = user;
      state.accessToken = accessToken;
    },
    logout: (state) => {
      state.accessToken = null;
      state.user = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      localStorage.removeItem('shiftEndTime');
      localStorage.clear();
    }
  }
});

export const { setCredentials, logout } = slice.actions;

export default slice.reducer;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const logInCheck = (state: RootState) => !!state.auth.accessToken;
export const getToken = (state: RootState) => state.auth.accessToken;
