import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import authReducer from 'pages/auth/authSlice';
import garageReducer from 'pages/garage/garageSlice';
import vehicleReducer from 'pages/vehicle/vehicleSlice';
import vehicleBrandReducer from 'pages/vehicle/brands/vehicleBrandSlice';
import serviceReducer from 'pages/services/service/serviceSlice';
import serviceCategoryReducer from 'pages/services/category/categorySlice';
import locationReducer from './slices/locationSlice';
import bannerReducer from 'pages/promotion/banner/bannerSlice';
import documentReducer from 'pages/document/documentSlice';
import settingReducer from 'pages/settings/settingsSlice';
import toastMessageReducer from './slices/toastMessageSlice';
import technicianReducer from 'pages/technicians/technicianSlice';
import customerReducer from 'pages/customers/customerSlice';
import jobReducer from 'pages/jobs/jobSlice';
import reportReducer from 'pages/report/reportSlice';
import paymentSlice from 'pages/payments/paymentSlice';
import staffSlice from 'pages/staffs/staffSlice';
import agreementSlice from 'pages/agreements/agreementSlice';
import inspectionSlice from 'pages/inspections/inspectionSlice';
import languageSlice from 'pages/languages/languageSlice';
import stateSlice from 'pages/states/stateSlice';
import shiftSlice from 'pages/shifts/shiftSlice';
import clientSlice from 'pages/client-partner/clientSlice';
import profileSlice from 'layout/header/ProfileSlice';
import subscriptionPlanReducer from 'pages/settings/SubPages/SubscriptionPlans/subscriptionPlanSlices';
import crmSlice from "pages/crm/crmSlice";
import { api } from './services/api';

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      garage: garageReducer,
      auth: authReducer,
      location: locationReducer,
      banner: bannerReducer,
      document: documentReducer,
      vehicle: vehicleReducer,
      service: serviceReducer,
      subscriptionPlan: subscriptionPlanReducer,
      vehicleBrand: vehicleBrandReducer,
      serviceCategory: serviceCategoryReducer,
      settings: settingReducer,
      toastMessage: toastMessageReducer,
      technician: technicianReducer,
      customer: customerReducer,
      job: jobReducer,
      report: reportReducer,
      payment: paymentSlice,
      staff: staffSlice,
      agreement: agreementSlice,
      inspection: inspectionSlice,
      language: languageSlice,
      state: stateSlice,
      shift: shiftSlice,
      profile: profileSlice,
      crm: crmSlice,
      client: clientSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
    ...options
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
