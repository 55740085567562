import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress
} from '@mui/material';

import { CRM_LEAD_STATUS } from 'utils/constants';

const activityTypeOptions = ['CALL', 'MESSAGE', 'EMAIL', 'MEETING', 'OTHER'];

type AddRemarkDialogProps = {
  open: boolean;
  onClose: () => void;

  remarkText: string;
  setRemarkText: (val: string) => void;
  selectedActivityType: string;
  setSelectedActivityType: (val: string) => void;
  newStatus: string;
  setNewStatus: (val: string) => void;

  onSubmit: () => void;
  isSubmitting: boolean;
};

export const AddRemarkDialog: React.FC<AddRemarkDialogProps> = ({
  open,
  onClose,
  remarkText,
  setRemarkText,
  selectedActivityType,
  setSelectedActivityType,
  newStatus,
  setNewStatus,
  onSubmit,
  isSubmitting
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="add-remark-dialog-title">
      <DialogTitle id="add-remark-dialog-title">Add an Activity</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="activity-type-dialog-label">Activity Type</InputLabel>
              <Select
                labelId="activity-type-dialog-label"
                label="Activity Type"
                value={selectedActivityType}
                onChange={(e) => setSelectedActivityType(e.target.value)}>
                {activityTypeOptions.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="status-dialog-label">Status (Optional)</InputLabel>
              <Select
                labelId="status-dialog-label"
                label="Status (Optional)"
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}>
                <MenuItem value="">No Change</MenuItem>
                {Object.values(CRM_LEAD_STATUS).map((st: string) => (
                  <MenuItem key={st} value={st}>
                    {st}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Remark"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              minRows={3}
              value={remarkText}
              onChange={(e) => setRemarkText(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmit} color="primary" disabled={isSubmitting}>
          {isSubmitting ? <CircularProgress size={24} /> : 'Save Remark'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
