import moment from 'moment';
import { FC, useState } from 'react';
import { FilterTemplateType } from '.';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogContent,
  Fade,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import ThemedSelect from 'components/ThemedSelect';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ServiceCategoryThemedSelect from './ServiceCategoryThemedSelect';
import AgentThemedSelect from './AgentThemedSelect';
import ability from 'common/casl/ability';

export const ThemedFiltersModal: FC<any> = ({
  filtersObj,
  setFiltersObj,
  modalOpen,
  closeFilterModal,
  filterTemplate,
  handleFilterClear,
  applyFilters,
  clearFilters,
  searchParams,
  setSearchParams
}) => {
  const [showMoreFilters, setShowMoreFilters] = useState<boolean>(false);

  function sortItemsByName(items: [any]) {
    return items.slice().sort((a, b) => a.label.localeCompare(b.label));
  }

  const toggleMoreFilters = () => {
    setShowMoreFilters((prev) => !prev);
  };

  const handleMultiSelectChange = (e: any, fieldKey: string) => {
    console.log(filtersObj);
    const f: any = { ...filtersObj };
    f[fieldKey] = e;

    setFiltersObj(f);
  };

  const handleDateRangeChange = (e: any, fieldKey: string, fromOrTo: string) => {
    const f: any = { ...filtersObj };
    const val = filtersObj?.[fieldKey] ? filtersObj?.[fieldKey] : [];
    let from = val?.[0] || moment();
    let to = val?.[1] || moment();

    if (fromOrTo === 'from') {
      from = e;
    }

    if (fromOrTo === 'to') {
      to = e;
    }

    f[fieldKey] = [from, to];
    setFiltersObj(f);
  };

  const handleTemplateMapping = (item: FilterTemplateType, index: number) => {
    if (item.type === 'switch') {
      return (
        <Box key={index} sx={{ my: 1.5, width: '100%' }}>
          {/* Hide the title */}
          {/* <Typography variant="h6">{item.label}</Typography> */}
          <Button
            variant={filtersObj?.[item.fieldKey] ? 'contained' : 'outlined'}
            onClick={() =>
              setFiltersObj((prev: any) => ({
                ...prev,
                [item.fieldKey]: !prev[item.fieldKey]
              }))
            }>
            {item.label}: {filtersObj?.[item.fieldKey] ? 'Enabled' : 'Disabled'}
          </Button>
        </Box>
      );
    }

    if (item.type === 'text') {
      return (
        <Box key={index} sx={{ my: 1.5, width: '100%' }}>
          <TextField
            fullWidth
            variant="outlined"
            label={item.label}
            value={filtersObj?.[item.fieldKey] || ''}
            onChange={(e) =>
              setFiltersObj((prev: any) => ({
                ...prev,
                [item.fieldKey]: e.target.value
              }))
            }
          />
        </Box>
      );
    }

    if (item.type === 'range') {
      return (
        <Box key={index} sx={{ my: 1.5, width: '100%' }}>
          {/* Hide the title */}
          {/* <Typography variant="h6">{item.label}</Typography> */}
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <input
                type="number"
                placeholder="Min"
                value={filtersObj?.[item.fieldKey]?.min || ''}
                onChange={(e) => {
                  const min = e.target.value ? parseFloat(e.target.value) : '';
                  setFiltersObj((prev: any) => ({
                    ...prev,
                    [item.fieldKey]: { ...prev[item.fieldKey], min }
                  }));
                }}
                style={{
                  width: '100%',
                  padding: '8px',
                  borderRadius: '4px',
                  border: '1px solid #ccc'
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <input
                type="number"
                placeholder="Max"
                value={filtersObj?.[item.fieldKey]?.max || ''}
                onChange={(e) => {
                  const max = e.target.value ? parseFloat(e.target.value) : '';
                  setFiltersObj((prev: any) => ({
                    ...prev,
                    [item.fieldKey]: { ...prev[item.fieldKey], max }
                  }));
                }}
                style={{
                  width: '100%',
                  padding: '8px',
                  borderRadius: '4px',
                  border: '1px solid #ccc'
                }}
              />
            </Grid>
          </Grid>
        </Box>
      );
    }

    if (item.type === 'checkbox') {
      return (
        <Box key={index} sx={{ my: 1.5, width: '100%' }}>
          <Grid container spacing={1}>
            {item.options?.map((option) => (
              <Grid item key={option.value.toString() + '-checkbox'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filtersObj?.[item.fieldKey] === 'Y'}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setFiltersObj((prev: any) => ({
                          ...prev,
                          [item.fieldKey]: checked ? 'Y' : 'N'
                        }));
                      }}
                    />
                  }
                  label={option.label}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      );
    }

    if (item.type === 'date-range') {
      return (
        <Box key={index} sx={{ my: 1.5, width: '100%' }}>
          <Grid container key={`${item.fieldKey}-${index}-container`}>
            <Grid item xs={12}>
              <Button variant="contained">{item.label}</Button>
            </Grid>

            <Grid item xs={5.5} mt={2}>
              <DateTimePicker
                name={`${item.fieldKey}-from`}
                label={'From'}
                autoFocus={false}
                sx={{
                  width: '100%',
                  '& .MuiDialog-paper': { padding: 0 },
                  '& .MuiDateTimePickerToolbar-separator': { display: 'none' }
                }}
                onChange={(e) => handleDateRangeChange(e, item.fieldKey, 'from')}
                value={filtersObj?.[item?.fieldKey]?.[0] || null}
                format="DD-MM-YYYY"
                slotProps={{
                  dialog: {
                    sx: {
                      '& .MuiDialog-paper': { padding: 0 },
                      '& .MuiDateTimePickerToolbar-separator': {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }
                    }
                  }
                }}
              />
            </Grid>

            <Grid item xs={1} mt={2} display="flex" justifyContent="center" alignItems="center">
              -
            </Grid>

            <Grid item xs={5.5} mt={2}>
              <DateTimePicker
                name={`${item.fieldKey}-to`}
                label={'To'}
                autoFocus={false}
                sx={{ width: '100%' }}
                onChange={(e) => handleDateRangeChange(e, item.fieldKey, 'to')}
                value={filtersObj?.[item?.fieldKey]?.[1] || null}
                format="DD-MM-YYYY"
                slotProps={{
                  dialog: {
                    sx: {
                      '& .MuiDialog-paper': { padding: 0 },
                      '& .MuiDateTimePickerToolbar-separator': {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        </Box>
      );
    }

    if (item.type === 'radio-button') {
      return (
        <Box key={index} sx={{ my: 1.5, width: '100%' }}>
          <Typography variant="h6">{item.label}</Typography>
          <Grid container spacing={1}>
            {item.options?.map((option) => (
              <Grid item key={option.value.toString() + 'item-key' + option.label}>
                <Button
                  variant={filtersObj?.[item.fieldKey] === option.value ? 'contained' : 'outlined'}
                  onClick={() =>
                    setFiltersObj((prev: any) => ({
                      ...prev,
                      [item.fieldKey]: option.value
                    }))
                  }
                  style={{ textTransform: 'capitalize' }}>
                  {option.label.toLowerCase().replace(/_/g, ' ')}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      );
    }

    if (item.type === 'service-category-select') {
      return (
        <Box key={index} sx={{ my: 1.5, width: '100%' }}>
          <ServiceCategoryThemedSelect
            value={filtersObj?.[item.fieldKey] || (item.isMulti ? [] : null)}
            onChange={(e) => handleMultiSelectChange(e, item.fieldKey)}
            isMulti={!!item.isMulti}
            handleClear={handleFilterClear}
            placeholder={item.label}
          />
        </Box>
      );
    }

    if (item.type === 'agent-select') {
      if (ability.can('SELF_ASSIGN', 'AGENT_DASHBOARD')) {
        return null;
      }

      return (
        <Box key={index} sx={{ my: 1.5, width: '100%' }}>
          <AgentThemedSelect
            value={filtersObj?.[item.fieldKey] || (item.isMulti ? [] : null)}
            onChange={(selected) =>
              setFiltersObj((prev: any) => ({
                ...prev,
                [item.fieldKey]: selected
              }))
            }
            isMulti={!!item.isMulti}
          />
        </Box>
      );
    }

    return (
      <Box key={index} sx={{ my: 1.5, width: '100%' }}>
        <ThemedSelect
          //@ts-ignore
          options={item.options ? sortItemsByName(item.options) : item.options}
          handleClear={handleFilterClear}
          isMulti={item.type === 'multi-select'}
          placeholder={item.label}
          onChange={(e) => handleMultiSelectChange(e, item.fieldKey)}
          value={filtersObj?.[item?.fieldKey] || []}
          isClearable={true}
          onClear={() => {}}
        />
      </Box>
    );
  };

  const hasMoreFilters = filterTemplate?.some((el: any) => el?.inShowMoreSection);

  return (
    <Dialog
      open={modalOpen}
      onClose={closeFilterModal}
      sx={{
        '.MuiPaper-root': { background: 'white' }
      }}>
      <DialogContent
        sx={{
          backgroundColor: 'white',
          borderRadius: '20px',
          minWidth: '200px',
          width: '100%',
          maxWidth: '400px'
        }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Fade in={modalOpen}>
            <Box
              sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              {filterTemplate
                ?.filter((el: any) => !el?.inShowMoreSection)
                .map(handleTemplateMapping)}

              {hasMoreFilters && (
                <Button
                  variant="text"
                  onClick={toggleMoreFilters}
                  sx={{ my: 2, fontSize: '16px', textTransform: 'capitalize' }}
                  startIcon={showMoreFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                  {showMoreFilters ? 'Hide Extra Filters' : 'More Filters'}
                </Button>
              )}

              <Collapse in={showMoreFilters} timeout={500} sx={{ width: '100%' }}>
                <Box>
                  {filterTemplate
                    ?.filter((el: any) => el?.inShowMoreSection)
                    .map(handleTemplateMapping)}
                </Box>
              </Collapse>

              <Box sx={{ my: 2, width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (searchParams && setSearchParams) {
                      searchParams.set('active-card', 'none');
                      searchParams.set('appliedFilters', JSON.stringify(filtersObj));
                      setSearchParams(searchParams);
                    }
                    applyFilters();
                  }}>
                  Apply Filter
                </Button>
              </Box>

              <Button
                variant="text"
                onClick={clearFilters}
                sx={{
                  mt: 0,
                  p: 0,
                  color: '#BABABA',
                  fontsize: '16px',
                  textTransform: 'capitalize'
                }}>
                Clear Filter
              </Button>
            </Box>
          </Fade>
        </LocalizationProvider>
      </DialogContent>
    </Dialog>
  );
};
