import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICRMLeadItem } from 'app/services/crm';
import type { RootState } from 'app/store';

interface CrmState {
  total: number | null;
  items: ICRMLeadItem[];
}

const initialState: CrmState = { total: null, items: [] };

const crmSlice = createSlice({
  name: 'crm',
  initialState,
  reducers: {
    setCrmData: (state, action: PayloadAction<{ total: number; items: ICRMLeadItem[] }>) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    }
  }
});

export const { setCrmData } = crmSlice.actions;

export const selectAllCrmLeads = (state: RootState) => state.crm.items;

export default crmSlice.reducer;
