import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Collapse,
  Snackbar,
  Alert,
  Grid,
  TextField,
  Paper,
  Divider,
  Stack,
  Chip,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Business';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useParams } from 'react-router-dom';
import {
  useGetClientDetailsQuery,
  useUpdateClientMutation,
  useCreateClientCategoryMutation,
  useUpdateClientCategoryMutation,
  useDeleteClientCategoryMutation
} from 'app/services/partner';
import SelectServiceCategory from 'components/forms/ServiceCategorySelect';
import UploadField from 'components/forms/UploadField';

// Define TypeScript interfaces
interface ServiceCategory {
  id: number;
  serviceCategoryName: string;
  serviceCategoryID: number;
}

interface Service {
  clientServiceCategoryID?: number;
  category: ServiceCategory | null;
  basePrice: string;
  kilometersCovered: string;
  additionalPricePerKm: string;
  comment: string;
}

interface ClientDetails {
  clientID: number;
  name: string;
  code: string;
  services: Service[];
  clientType?: string;
  websiteUrl?: string;
  businessRegistrationNumber?: string;
  partnerLogoUrl?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  pincode?: string;
  country?: string;
  emailAddress?: string;
  phoneNumber?: string;
  billingAddress?: string;
}

interface SnackbarState {
  open: boolean;
  message: string;
  severity: 'success' | 'error' | 'info' | 'warning';
}

const ViewClientPage: React.FC = () => {
  const { clientID } = useParams<{ clientID: string }>();
  const [isEditMode, setIsEditMode] = useState(false);
  const [expandedServices, setExpandedServices] = useState<Record<string, boolean>>({});
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: '',
    severity: 'success'
  });

  // Fetch client details
  const { data, isLoading, isError, refetch } = useGetClientDetailsQuery<any>(Number(clientID));
  const [updateClient] = useUpdateClientMutation();
  const [createCategory] = useCreateClientCategoryMutation();
  const [updateCategory] = useUpdateClientCategoryMutation();
  const [deleteCategory] = useDeleteClientCategoryMutation();

  const [formData, setFormData] = useState<ClientDetails | null>(null);
  const [isErrorInForm, setIsErrorInForm] = useState(false);
  const [FirtErrorField, setFirtErrorField] = useState<string | null>(null);

  useEffect(() => {
    if (data) {
      setFormData({
        ...data,
        name: data.clientName,
        websiteUrl: data.websiteURL,
        services:
          data.clientServiceCategories?.map((category: any) => ({
            clientServiceCategoryID: category.clientServiceCategoryID,
            category: category.serviceCategory || null,
            basePrice: String(category.basePrice || ''),
            kilometersCovered: String(category.kmCovered || ''),
            additionalPricePerKm: String(category.pricePerExtraKM || ''),
            comment: category.description || ''
          })) || []
      });
    }
  }, [data]);

  // Set all new services to expanded by default
  useEffect(() => {
    if (formData?.services) {
      const newExpandedState: Record<string, boolean> = {};
      formData.services.forEach((service, index) => {
        // For new services without an ID, use the index
        const key = service.clientServiceCategoryID
          ? `id-${service.clientServiceCategoryID}`
          : `new-${index}`;
        // If this is a new service or didn't have a state before, expand it
        if (expandedServices[key] === undefined) {
          newExpandedState[key] = true;
        } else {
          newExpandedState[key] = expandedServices[key];
        }
      });
      setExpandedServices(newExpandedState);
    }
  }, [formData?.services]);

  if (isLoading) return <Typography>Loading...</Typography>;
  if (isError) return <Typography>Error loading client details.</Typography>;

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleSaveClick = async () => {
    try {
      if (!formData) return;

      // Update client details
      await updateClient({
        id: Number(clientID),
        body: {
          clientName: formData.name,
          websiteURL: formData.websiteUrl || undefined,
          businessRegistrationNumber: formData.businessRegistrationNumber || undefined,
          partnerLogoUrl: formData.partnerLogoUrl || undefined,
          streetAddress: formData.streetAddress || undefined,
          city: formData.city || undefined,
          state: formData.state || undefined,
          pincode: formData?.pincode ? parseInt(formData.pincode) : undefined,
          country: formData.country || undefined,
          emailAddress: formData.emailAddress || undefined,
          phoneNumber: formData.phoneNumber || undefined,
          billingAddress: formData.billingAddress || undefined
        }
      }).unwrap();

      // Update or create service categories
      for (const service of formData.services) {
        if (service.clientServiceCategoryID) {
          // Update existing category
          await updateCategory({
            clientId: Number(clientID),
            categoryId: service.clientServiceCategoryID,
            body: {
              basePrice: parseInt(service.basePrice, 10) || 0,
              kmCovered: service.kilometersCovered
                ? parseInt(service.kilometersCovered, 10)
                : undefined,
              pricePerExtraKM: service.additionalPricePerKm
                ? parseInt(service.additionalPricePerKm, 10)
                : undefined,
              description: service.comment || undefined
            }
          }).unwrap();
        } else if (service.category) {
          // Create new category - only if a category is selected
          await createCategory({
            clientId: Number(clientID),
            body: {
              serviceCategoryID: service.category.serviceCategoryID || 0,
              basePrice: parseInt(service.basePrice, 10) || 0,
              kmCovered: service.kilometersCovered
                ? parseInt(service.kilometersCovered, 10)
                : undefined,
              pricePerExtraKM: service.additionalPricePerKm
                ? parseInt(service.additionalPricePerKm, 10)
                : undefined,
              description: service.comment || undefined
            }
          }).unwrap();
        }
      }

      setIsEditMode(false);
      setSnackbar({
        open: true,
        message: 'Client details updated successfully.',
        severity: 'success'
      });

      // Refresh data after successful save
      refetch();
    } catch (error) {
      console.error('Error updating client:', error);
      setSnackbar({ open: true, message: 'Failed to update client details.', severity: 'error' });
    }
  };

  const handleAddService = () => {
    setFormData((prev) => {
      if (!prev) return prev;
      const updatedServices = [
        ...(prev.services || []),
        {
          category: null,
          basePrice: '',
          kilometersCovered: '',
          additionalPricePerKm: '',
          comment: ''
        }
      ];
      return { ...prev, services: updatedServices };
    });
  };

  const handleRemoveService = async (index: number) => {
    const serviceToRemove = formData?.services[index];
    if (serviceToRemove?.clientServiceCategoryID) {
      try {
        await deleteCategory({
          clientId: Number(clientID),
          categoryId: serviceToRemove.clientServiceCategoryID
        }).unwrap();
      } catch (error) {
        setSnackbar({ open: true, message: 'Failed to delete service.', severity: 'error' });
        return;
      }
    }

    setFormData((prev) => {
      if (!prev) return prev;
      const updatedServices = [...prev.services];
      updatedServices.splice(index, 1);
      return { ...prev, services: updatedServices };
    });
  };

  const toggleServiceExpansion = (serviceId: string) => {
    setExpandedServices((prevState) => ({
      ...prevState,
      [serviceId]: !prevState[serviceId]
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleServiceCategoryChange = (
    index: number,
    serviceCategoryName: string,
    serviceCategoryID: number
  ) => {
    setFormData((prev) => {
      if (!prev) return prev;
      const updatedServices = [...prev.services];
      const selectedCategory = {
        id: serviceCategoryID,
        serviceCategoryName,
        serviceCategoryID
      };

      updatedServices[index] = {
        ...updatedServices[index],
        category: selectedCategory
      };

      return { ...prev, services: updatedServices };
    });
  };

  if (!formData) return null;

  // Function to format address for display
  const formatAddress = () => {
    const addressParts = [
      formData.streetAddress,
      formData.city,
      formData.state,
      formData.pincode,
      formData.country
    ].filter(Boolean);

    return addressParts.join(', ');
  };

  // Format currency for display
  const formatCurrency = (value: string) => {
    if (!value) return '';
    return `₹${parseInt(value, 10).toLocaleString()}`;
  };

  // Get service key for expansion tracking
  const getServiceKey = (service: Service, index: number) => {
    return service.clientServiceCategoryID
      ? `id-${service.clientServiceCategoryID}`
      : `new-${index}`;
  };

  const handleUploadSuccess = (url: string) => {
    setFormData((prev) => ({ ...prev!, partnerLogoUrl: url }));
  };

  const handleError = (conditionSatisfy: boolean, name: string) => {
    if (!isErrorInForm && conditionSatisfy) {
      setIsErrorInForm(true);
      setFirtErrorField(name);
    }
    if (!conditionSatisfy) {
      if (name === FirtErrorField) {
        setIsErrorInForm(false);
        setFirtErrorField(null);
      }
    }
    return conditionSatisfy;
  };

  return (
    <Box sx={{ p: 4 }}>
      {/* Client Details */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Box>
          <Typography variant="h4" gutterBottom>
            {formData.name}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Client Code: {data.clientCode}
          </Typography>
          {formData.clientType && (
            <Chip
              label={formData.clientType}
              size="small"
              color={formData.clientType === 'SERVICE' ? 'primary' : 'secondary'}
              sx={{ mt: 1 }}
            />
          )}
        </Box>

        {/* Edit Button */}
        {!isEditMode && (
          <Button onClick={handleEditClick} variant="contained" color="primary">
            Edit Client Details
          </Button>
        )}
      </Box>

      {/* Read-Only View */}
      {!isEditMode && formData?.partnerLogoUrl && (
        <Grid sx={{ marginBottom: '1rem' }}>
          <UploadField
            onSuccess={handleUploadSuccess}
            fileKey={formData?.partnerLogoUrl}
            readOnly={true}
          />
        </Grid>
      )}
      {!isEditMode && (
        <Grid container spacing={3}>
          {/* Client Information Card */}
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 3, height: '100%' }}>
              <Typography variant="h6" gutterBottom sx={{ pb: 1, borderBottom: '1px solid #eee' }}>
                Client Information
              </Typography>

              <Stack spacing={2} sx={{ mt: 2 }}>
                {formData.businessRegistrationNumber && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <BusinessIcon color="action" fontSize="small" />
                    <Typography variant="body2">
                      Business Reg: {formData.businessRegistrationNumber}
                    </Typography>
                  </Box>
                )}

                {formData.websiteUrl && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <LinkIcon color="action" fontSize="small" />
                    <Typography variant="body2">Website: {formData.websiteUrl}</Typography>
                  </Box>
                )}

                {formData.emailAddress && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <EmailIcon color="action" fontSize="small" />
                    <Typography variant="body2">Email: {formData.emailAddress}</Typography>
                  </Box>
                )}

                {formData.phoneNumber && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PhoneIcon color="action" fontSize="small" />
                    <Typography variant="body2">Phone: {formData.phoneNumber}</Typography>
                  </Box>
                )}
              </Stack>
            </Paper>
          </Grid>

          {/* Address Information Card */}
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 3, height: '100%' }}>
              <Typography variant="h6" gutterBottom sx={{ pb: 1, borderBottom: '1px solid #eee' }}>
                Address Information
              </Typography>

              <Stack spacing={2} sx={{ mt: 2 }}>
                {formatAddress() && (
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                    <LocationOnIcon color="action" fontSize="small" sx={{ mt: 0.5 }} />
                    <Typography variant="body2">{formatAddress()}</Typography>
                  </Box>
                )}

                {formData.billingAddress && (
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                    <ReceiptIcon color="action" fontSize="small" sx={{ mt: 0.5 }} />
                    <Box>
                      <Typography variant="body2" fontWeight="medium">
                        Billing Address:
                      </Typography>
                      <Typography variant="body2">{formData.billingAddress}</Typography>
                    </Box>
                  </Box>
                )}
              </Stack>
            </Paper>
          </Grid>

          {/* Services Information */}
          <Grid item xs={12}>
            <Paper elevation={2} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom sx={{ pb: 1, borderBottom: '1px solid #eee' }}>
                Services
              </Typography>

              {formData.services.length === 0 ? (
                <Typography variant="body2" color="text.secondary" sx={{ my: 2 }}>
                  No services available for this client.
                </Typography>
              ) : (
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {formData.services.map((service, index) => (
                    <Grid item xs={12} md={6} lg={4} key={index}>
                      <Card
                        sx={{
                          height: '100%',
                          bgcolor: '#f9f9f9',
                          border: '1px solid #ddd',
                          borderRadius: 2,
                          transition: 'all 0.2s ease-in-out',
                          '&:hover': {
                            boxShadow: 3,
                            transform: 'translateY(-2px)'
                          }
                        }}>
                        <CardHeader
                          title={service.category?.serviceCategoryName || `Service #${index + 1}`}
                          titleTypographyProps={{ variant: 'subtitle1', fontWeight: 'medium' }}
                        />
                        <Divider />
                        <CardContent>
                          <Stack spacing={1.5}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography variant="body2" color="text.secondary">
                                Base Price:
                              </Typography>
                              <Typography variant="body2" fontWeight="medium">
                                {formatCurrency(service.basePrice)}
                              </Typography>
                            </Box>

                            {service.kilometersCovered && (
                              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" color="text.secondary">
                                  Kilometers Covered:
                                </Typography>
                                <Typography variant="body2">
                                  {service.kilometersCovered} km
                                </Typography>
                              </Box>
                            )}

                            {service.additionalPricePerKm && (
                              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body2" color="text.secondary">
                                  Extra Km Price:
                                </Typography>
                                <Typography variant="body2">
                                  {formatCurrency(service.additionalPricePerKm)}/km
                                </Typography>
                              </Box>
                            )}

                            {service.comment && (
                              <Box sx={{ mt: 1 }}>
                                <Typography variant="body2" color="text.secondary">
                                  Notes:
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 0.5, fontStyle: 'italic' }}>
                                  "{service.comment}"
                                </Typography>
                              </Box>
                            )}
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      )}

      {/* Edit Mode Form */}
      {isEditMode && (
        <Box>
          <Grid
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ marginBottom: '1rem' }}>
            <Grid>
              <UploadField onSuccess={handleUploadSuccess} fileKey={formData?.partnerLogoUrl} />
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{ mt: 1, textWrap: 'nowrap' }}>
                Limit: maximum size 1MB
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Client Name"
                value={formData.name}
                onChange={(e) => setFormData((prev) => ({ ...prev!, name: e.target.value }))}
                fullWidth
                required
                error={!formData.name}
                helperText={!formData.name ? 'Client Name is required' : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Client Code" value={formData.code} fullWidth disabled />
            </Grid>
            {/* Additional Fields */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Website URL"
                value={formData.websiteUrl || ''}
                onChange={(e) => setFormData((prev) => ({ ...prev!, websiteUrl: e.target.value }))}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Business Registration Number"
                value={formData.businessRegistrationNumber || ''}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev!, businessRegistrationNumber: e.target.value }))
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email Address"
                value={formData.emailAddress || ''}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev!, emailAddress: e.target.value }))
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                value={formData.phoneNumber || ''}
                onChange={(e) => setFormData((prev) => ({ ...prev!, phoneNumber: e.target.value }))}
                fullWidth
                error={handleError(!/^\d{10}$/.test(formData?.phoneNumber ?? ''), 'phoneNumber')}
                helperText={
                  !/^\d{10}$/.test(formData?.phoneNumber ?? '')
                    ? 'Phone Number must be exactly 10 digits.'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Street Address"
                value={formData.streetAddress || ''}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev!, streetAddress: e.target.value }))
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="City"
                value={formData.city || ''}
                onChange={(e) => setFormData((prev) => ({ ...prev!, city: e.target.value }))}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="State"
                value={formData.state || ''}
                onChange={(e) => setFormData((prev) => ({ ...prev!, state: e.target.value }))}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Pincode"
                value={formData.pincode || ''}
                onChange={(e) => setFormData((prev) => ({ ...prev!, pincode: e.target.value }))}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Country"
                value={formData.country || ''}
                onChange={(e) => setFormData((prev) => ({ ...prev!, country: e.target.value }))}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Billing Address"
                value={formData.billingAddress || ''}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev!, billingAddress: e.target.value }))
                }
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
          </Grid>

          {/* Services Section */}
          <Typography variant="h6" sx={{ mt: 4 }}>
            Services
          </Typography>
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddService}
            variant="outlined"
            color="primary"
            sx={{ borderRadius: 28, mb: 2 }}>
            Add Service
          </Button>
          {formData.services.length === 0 ? (
            <Typography variant="body2" color="textSecondary">
              No services added. Click the "Add Service" button to add a service.
            </Typography>
          ) : (
            formData.services.map((service, index) => {
              const serviceKey = getServiceKey(service, index);
              const isExpanded = expandedServices[serviceKey] || false;

              return (
                <Card
                  key={index}
                  sx={{
                    bgcolor: '#f9f9f9',
                    mb: 2,
                    border: '1px solid #ddd',
                    borderRadius: 2
                  }}>
                  <CardHeader
                    title={service.category?.serviceCategoryName || `New Service`}
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    action={
                      <Box sx={{ display: 'flex' }}>
                        <IconButton
                          onClick={() => toggleServiceExpansion(serviceKey)}
                          aria-expanded={isExpanded}
                          aria-label="show more">
                          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                        <IconButton onClick={() => handleRemoveService(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    }
                  />
                  <Collapse in={isExpanded}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={7}>
                          <FormControl fullWidth>
                            <SelectServiceCategory
                              value={(service.category?.serviceCategoryID as any) || ''}
                              onChange={(e: any) => {
                                handleServiceCategoryChange(
                                  index,
                                  e.serviceCategoryName,
                                  Number(e.id)
                                );
                              }}
                              label="Service Category"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <TextField
                            label="Base Price"
                            value={service.basePrice}
                            onChange={(e) => {
                              if (/^\d*$/.test(e.target.value)) {
                                const updatedServices = [...formData.services];
                                updatedServices[index].basePrice = e.target.value;
                                setFormData((prev) => ({ ...prev!, services: updatedServices }));
                              }
                            }}
                            fullWidth
                            type="number"
                            error={handleError(
                              parseInt(service.basePrice, 10) > 200000000,
                              'basePrice' + index
                            )}
                            helperText={
                              parseInt(service.basePrice, 10) > 200000000
                                ? 'Base Price must not be greater than 200,000,000.'
                                : ''
                            }
                          />
                        </Grid>
                        {/* Kilometers Covered and Additional Price Per Km */}
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Kilometers Covered"
                            value={service.kilometersCovered}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*$/.test(value)) {
                                const updatedServices = [...formData.services];
                                updatedServices[index].kilometersCovered = value;
                                setFormData((prev) => ({ ...prev!, services: updatedServices }));
                              }
                            }}
                            fullWidth
                            type="number"
                            error={handleError(
                              parseInt(service.kilometersCovered, 10) > 200000000,
                              'kilometersCovered' + index
                            )}
                            helperText={
                              parseInt(service.kilometersCovered, 10) > 200000000
                                ? 'Kilometer Covered must not be greater than 200,000,000.'
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Additional Price Per Km"
                            value={service.additionalPricePerKm}
                            onChange={(e) => {
                              if (/^\d*$/.test(e.target.value)) {
                                const updatedServices = [...formData.services];
                                updatedServices[index].additionalPricePerKm = e.target.value;
                                setFormData((prev) => ({ ...prev!, services: updatedServices }));
                              }
                            }}
                            fullWidth
                            type="number"
                            error={handleError(
                              parseInt(service.additionalPricePerKm, 10) > 200000000,
                              'pricePerExtraKM ' + index
                            )}
                            helperText={
                              parseInt(service.additionalPricePerKm, 10) > 200000000
                                ? 'Additional Price Per Km must not be greater than 200,000,000.'
                                : ''
                            }
                          />
                        </Grid>
                        {/* Comment */}
                        <Grid item xs={12}>
                          <TextField
                            label="Comment"
                            value={service.comment}
                            onChange={(e) => {
                              const updatedServices = [...formData.services];
                              updatedServices[index].comment = e.target.value;
                              setFormData((prev) => ({ ...prev!, services: updatedServices }));
                            }}
                            fullWidth
                            multiline
                            rows={2}
                            error={handleError(service.comment.length > 255, 'comment' + index)}
                            helperText={
                              service.comment.length > 255
                                ? 'Comment must not exceed 255 characters.'
                                : ''
                            }
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Collapse>
                </Card>
              );
            })
          )}
          <Box sx={{ mt: 4 }}>
            <Button onClick={() => setIsEditMode(false)} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button
              disabled={isErrorInForm}
              onClick={handleSaveClick}
              variant="contained"
              color="primary">
              Save Changes
            </Button>
          </Box>
        </Box>
      )}

      {/* Snackbar for Notifications */}
      <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ViewClientPage;
