import { DEFAULT_LIMIT, ListResponse, QueryParams, SuccessResponce } from 'utils/constants';
import { api } from './api';

export interface GetRosterTeamsResponseDto {
  teams: RosterTeamItem[];
  unassignedAgents: UnassignedAgentItem[];
}

export interface RosterTeamItem {
  teamID: number;
  teamName: string;
  teamOrder: number;
  agents: TeamAgentItem[];
}

export interface TeamAgentItem {
  agentID: string;
  agentName: string;
  teamAgentOrder: number;
}

export interface UnassignedAgentItem {
  agentID: string;
  agentName: string;
}

export type ShiftItem = {
  shiftID: number;
  shiftStart: string;
  shiftEnd: string;
  shiftSupervisor: string;
  shiftEnded: string;
  totalAgents: number;
};
export type CreateShiftInputAgent = {
  agentID: string;
  absent?: boolean;
  absenceReason?: string;
};
export type CreateShiftInput = {
  shiftStart: string;
  shiftEnd: string;
  agents?: CreateShiftInputAgent[];
};

export type AssignableAgentsItem = {
  agentID: string;
  agentName: string;
  staffID: string;
};

export type GetShiftResponseAgent = {
  agentID: string;
  agentName: string;
  absent?: boolean;
  absenceReason?: string;
  loginTime: string;
  logoutTime: string;
  staffID: string;
  numberOfJobsHandled: number;
};

export type GetShiftDetails = {
  shiftID: number;
  shiftStart: string;
  shiftEnd: string;
  shiftSupervisor: string;
  shiftEnded: 'Y' | 'N';
  shiftStarted: 'Y' | 'N';
  totalAgents: number;
  agents: GetShiftResponseAgent[];
};

type ShiftListResponse = ListResponse<ShiftItem>;

export type AddAgentToShiftInput = {
  agentID: string;
  absenceReason?: string;
  loginTime?: string;
  logoutTime?: string;
};

export type UpdateShiftAgentInput = {
  agentID: string;
  absenceReason?: string;
  isAbsent?: boolean;
  loginTime?: string;
  logoutTime?: string;
};

export interface AgentRosterMasterDataResponse {
  duties: DutyItem[];
  shifts: ShiftDefItem[];
  leaveReasons: LeaveReasonItem[];
  workConditions: WorkConditionItem[];
  shiftDefinitions: ShiftDefinitionItem[];
  availablePlans: any[];
  plan: any;
}

export interface DutyItem {
  dutyCode: string; // Unique code for the duty
  dutyDescription: string; // Description of the duty
}

export interface ShiftDefItem {
  shiftName: string; // Name of the shift
  startTime: string; // Start time of the shift (e.g., "06:00")
  endTime: string; // End time of the shift (e.g., "13:00")
}

export interface LeaveReasonItem {
  reasonCode: string; // Unique code for the leave reason
  reasonDescription: string; // Description of the leave reason
}

export interface WorkConditionItem {
  conditionCode: string; // Unique code for the work condition
  description: string; // Description of the work condition
}

export interface ShiftDefinitionItem {
  shiftID: number; // Unique ID for the shift definition
  shiftName: string; // Name of the shift definition
  startTime: string; // Start time of the shift definition (e.g., "06:00")
  endTime: string; // End time of the shift definition (e.g., "13:00")
}

export interface DynamicEntry {
  agentID: string;
  displayText: string;
  dutyID: number;
  dutyName: string;
  leaveReason?: number;
  otherReasonText?: string;
  shiftID: number;
  shiftName: string;
  workConditionCode: string;
  workConditionName: string;
}

export interface IAdminRecordRosterEntryInputItem {
  id: string; // Constant property: formatted as DD-MMM-YYYY
  date: string; // Constant property: same as id
  dynamicEntries: Record<string, DynamicEntry>; // Dynamic properties with keys as strings
}

export interface IAdminRecordRosterEntryInput {
  planMonth: number; // Plan month
  planYear: number; // Plan year
  isCreatePlan: boolean; // Indicates if the plan is being created
  rosterEntries: IAdminRecordRosterEntryInputItem[]; // List of roster entries
}

export const shiftApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listShifts: builder.query<ShiftListResponse, QueryParams>({
      query: (params) => {
        const { limit = DEFAULT_LIMIT, offset = 0, shiftSupervisor, shiftEnded } = params;
        let queryUrl = `/admin/shifts?limit=${limit}&offset=${offset}`;

        if (typeof shiftSupervisor === 'string') {
          queryUrl += `&shiftSupervisor=${shiftSupervisor}`;
        }

        if (typeof shiftEnded === 'string') {
          queryUrl += `&shiftEnded=${shiftEnded}`;
        }

        return queryUrl;
      },
      transformResponse: (res: ShiftListResponse) => {
        let items = res?.items?.map((item: ShiftItem) => {
          return { ...item, id: item['shiftID'] };
        });
        return { ...res, items };
      },
      providesTags: (
        result: any = {
          items: [],
          total: 0
        }
      ) => {
        const items =
          result?.items?.map(({ id }: { id: number }) => ({ type: 'Shift', id: id } as const)) ??
          [];
        return [...items, { type: 'Shift' as const, id: 'LIST' }];
      }
    }),
    assignableAgents: builder.query<AssignableAgentsItem[], { filter: string | undefined }>({
      query: (params) => {
        const { filter } = params;
        let queryUrl = `/admin/shifts/assignable-agents`;

        if (typeof filter === 'string') {
          queryUrl += `?filter=${filter}`;
        }

        return queryUrl;
      }
    }),
    getShiftDetails: builder.query<GetShiftDetails, { shiftID: string }>({
      query: ({ shiftID }) => {
        let queryUrl = `/admin/shifts/${shiftID}`;
        return queryUrl;
      },
      // providesTags: [{ type: 'ShiftDetails', id: 'LIST' }],
      providesTags: (result, error, arg) => {
        return result
          ? [
              { type: 'ShiftDetails' as const, shiftID: result.shiftID } // ...result.agents.map((agent) => ({ type: 'ShiftAgent', id: agent.agentID }))
            ]
          : [{ type: 'ShiftDetails' as const, shiftID: arg.shiftID }];
      }
    }),
    createShift: builder.mutation<SuccessResponce, CreateShiftInput>({
      query(body) {
        return {
          url: `/admin/shifts`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['Shift']
    }),
    deleteShift: builder.mutation<SuccessResponce, { shiftID: number }>({
      query(body) {
        const { shiftID } = body;
        return {
          url: `/admin/shifts/${shiftID}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: ['Shift']
    }),
    endShift: builder.mutation<SuccessResponce, { shiftID: string }>({
      query(body) {
        const { shiftID } = body;
        return {
          url: `/admin/shifts/${shiftID}/end`,
          method: 'PUT'
        };
      },
      invalidatesTags: ['Shift']
    }),
    addAgentToShift: builder.mutation<
      SuccessResponce,
      { data: AddAgentToShiftInput; params: { shiftID: string } }
    >({
      query(body) {
        const {
          params: { shiftID },
          data
        } = body;
        return {
          url: `/admin/shifts/${shiftID}/agents`,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['ShiftDetails']
    }),
    deleteAgentFromShift: builder.mutation<
      SuccessResponce,
      { data: { agentID: string }; params: { shiftID: string } }
    >({
      query(body) {
        const {
          params: { shiftID },
          data
        } = body;
        return {
          url: `/admin/shifts/${shiftID}/agents`,
          method: 'DELETE',
          body: data
        };
      },
      invalidatesTags: ['ShiftDetails']
    }),
    updateAgentsOnShift: builder.mutation<
      SuccessResponce,
      { data: UpdateShiftAgentInput; params: { shiftID: string } }
    >({
      query(body) {
        const {
          params: { shiftID },
          data
        } = body;
        return {
          url: `/admin/shifts/${shiftID}/agents`,
          method: 'PUT',
          body: data
        };
      },
      invalidatesTags: ['ShiftDetails']
    }),
    getAgentRosterMasterData: builder.query<AgentRosterMasterDataResponse, void>({
      query: () => {
        const query = `/admin/agent-roster/master-data`;
        return query;
      }
    }),
    getAgentRosterPlan: builder.query<
      AgentRosterMasterDataResponse,
      { planMonth?: string; planYear?: string }
    >({
      query: ({ planMonth, planYear }) => {
        const query =
          planMonth && planYear
            ? `/admin/agent-roster/plan?planMonth=${planMonth}&planYear=${planYear}`
            : `/admin/agent-roster/plan`;
        return query;
      },
      providesTags: ['Shift']
    }),
    adminRecordRosterPlanEntries: builder.mutation<
      SuccessResponce,
      {
        input: IAdminRecordRosterEntryInput;
      }
    >({
      query(body) {
        return {
          url: `/admin/agent-roster/record-roster-entry`,
          method: 'POST',
          body: body.input
        };
      },
      invalidatesTags: ['Shift']
    }),
    adminGetAgentRoster: builder.query<any, { queryString?: string }>({
      query: (params) => {
        const query = params?.queryString
          ? `/admin/agent-roster?${params.queryString}`
          : `/admin/agent-roster`;
        return query;
      }
    }),
    adminGetRosterPlanReportUrl: builder.mutation<{}, { planID: number; reportType: string }>({
      query: (params) => {
        return {
          url: `/admin/agent-roster/download`,
          method: 'POST',
          body: params
        };
      }
    }),
    createTeam: builder.mutation<SuccessResponce, { teamName: string }>({
      query(body) {
        return {
          url: `/admin/roster-teams`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['RosterTeams']
    }),
    getRosterTeams: builder.query<GetRosterTeamsResponseDto, void>({
      query: () => `/admin/roster-teams`,
      providesTags: [{ type: 'RosterTeams', id: 'LIST' }]
    }),
    deleteTeam: builder.mutation<SuccessResponce, { teamID: number }>({
      query({ teamID }) {
        return {
          url: `/admin/roster-teams/${teamID}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: ['RosterTeams']
    }),
    updateRosterTeam: builder.mutation<
      SuccessResponce,
      { teamID: number; teamName: string; teamOrder: number; updatedBy: string }
    >({
      query(body) {
        const { teamID, ...data } = body;
        return {
          url: `/admin/roster-teams/${teamID}`,
          method: 'PUT',
          body: data
        };
      },
      invalidatesTags: ['RosterTeams']
    }),
    updateTeamMember: builder.mutation<
      SuccessResponce,
      { teamID?: number; agentID: number; order?: number }
    >({
      query(body) {
        return {
          url: `/admin/roster-teams/update-member`,
          method: 'PUT',
          body
        };
      },
      invalidatesTags: ['RosterTeams']
    })
  })
});

export const {
  useListShiftsQuery,
  useAssignableAgentsQuery,
  useGetShiftDetailsQuery,
  useCreateShiftMutation,
  useDeleteShiftMutation,
  useEndShiftMutation,
  useAddAgentToShiftMutation,
  useDeleteAgentFromShiftMutation,
  useUpdateAgentsOnShiftMutation,
  useGetAgentRosterMasterDataQuery,
  useLazyGetAgentRosterMasterDataQuery,
  useGetAgentRosterPlanQuery,
  useLazyGetAgentRosterPlanQuery,
  useAdminRecordRosterPlanEntriesMutation,
  useAdminGetAgentRosterQuery,
  useLazyAdminGetAgentRosterQuery,
  useAdminGetRosterPlanReportUrlMutation,
  useGetRosterTeamsQuery,
  useCreateTeamMutation,
  useDeleteTeamMutation,
  useUpdateRosterTeamMutation,
  useUpdateTeamMemberMutation
} = shiftApi;
