import { DEFAULT_LIMIT } from 'utils/constants';
import { api } from './api';
import { SubscriptionPlanListDto } from 'pages/settings/SubPages/SubscriptionPlans/subscriptionPlanSlices';
import QueryString from 'qs';

export type FilterSubscriptionPlansInputs = {
  isActive?: 'Y' | 'N';
  isDeleted?: 'Y' | 'N';
  planName?: string;
  limit?: number;
  offset?: number;
};

export type CreateSubscriptionPlanInputs = {
  planName: string;
  planDescription: string;
  planPrice: number;
  planDuration: number;
  durationUnit: 'DAYS' | 'MONTHS' | 'YEARS';
  eligibility?: string;
  order: 1 | 2 | 3;
  notesDisclaimer?: string;
  items: {
    category: number | null;
    title: string | null;
    usageLimit: number;
    distanceLimit: number;
  }[];
};

export const subscriptionPlansApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listSubscriptionPlans: builder.query<SubscriptionPlanListDto, FilterSubscriptionPlansInputs>({
      query: ({ limit = DEFAULT_LIMIT, offset = 0, planName, isActive, isDeleted }) => {
        const queries: Record<string, any> = { limit, offset };

        if (planName) queries.planName = planName;
        if (isActive) queries.isActive = isActive;
        if (isDeleted) queries.isDeleted = isDeleted;

        const queryString = QueryString.stringify(queries, { skipNulls: true });
        return `/subscription-plans?${queryString}`;
      },
      providesTags: ['subscriptionPlans']
    }),

    createSubscriptionPlan: builder.mutation<void, CreateSubscriptionPlanInputs>({
      query: (newPlan) => ({
        url: '/subscription-plans',
        method: 'POST',
        body: newPlan
      }),
      invalidatesTags: ['subscriptionPlans']
    }),
    deleteSubscriptionPlan: builder.mutation<void, number>({
      query: (planID: number) => ({
        url: `/subscription-plans/${planID}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['subscriptionPlans']
    }),
    updateSubscriptionPlan: builder.mutation<
      // Adjust response type as needed
      { message: string },
      { planId: number; data: CreateSubscriptionPlanInputs }
    >({
      query: ({ planId, data }) => ({
        url: `/subscription-plans/${planId}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['subscriptionPlans'] // or whichever tags are relevant
    })
  })
});

export const {
  useListSubscriptionPlansQuery,
  useLazyListSubscriptionPlansQuery,
  useCreateSubscriptionPlanMutation,
  useDeleteSubscriptionPlanMutation,
  useUpdateSubscriptionPlanMutation
} = subscriptionPlansApi;
