import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';


interface Role {
    roleID: number;
    roleName: string;
    roleDescription: string;
    roleEnabled: string;
    roleCode: number;
    createdDate: string;
    createdBy: string;
    updatedDate: string | null;
    updatedBy: string | null;
}

interface Profile {
    adminID: string;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    password: string;
    adminOldID: string | null;
    adminPhone: string;
    enabled: string;
    staffID: string;
    adminEnabled: string;
    disabledReason: string | null;
    disabledDate: string | null;
    disabledBy: string | null;
    createdBy: string;
    createdDate: string;
    updatedBy: string;
    updatedDate: string;
    roleID: number;
    districtID: number;
    stateID: number;
    role: Role;
    breakStatus: string;
}

const initialState: Profile = {
  adminID: '',
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  password: '',
  adminOldID: null,
  adminPhone: '',
  enabled: '',
  staffID: '',
  adminEnabled: '',
  disabledReason: null,
  disabledDate: null,
  disabledBy: null,
  createdBy: '',
  createdDate: '',
  updatedBy: '',
  updatedDate: '',
  roleID: 0,
  districtID: 0,
  stateID: 0,
  role: {
    roleID: 0,
    roleName: '',
    roleDescription: '',
    roleEnabled: '',
    roleCode: 0,
    createdDate: '',
    createdBy: '',
    updatedDate: null,
    updatedBy: null,
  },
  breakStatus: ''
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfileData: (
            state,
            action: PayloadAction<Profile>
        ) => {
            return { ...state, ...action.payload };
        }
    }
});

export const { setProfileData } = profileSlice.actions;

export const selectProfile = (state: RootState) => state.profile;

export default profileSlice.reducer;
