import { Box, Paper, Skeleton, Typography } from '@mui/material';
import { FC } from 'react';

export interface IThemeCardProps {
  onClick?: (e?: any) => void;
  title: string;
  count: number;
  icon: any;
  activeIcon: any;
  isLoading?: boolean;
  isActive?: boolean;
  align?: 'vertical' | 'horizontal';
  percentage?: boolean;
}

const ThemeStatCard: FC<IThemeCardProps> = ({
  title,
  count,
  icon,
  activeIcon,
  isLoading,
  isActive,
  onClick,
  align = 'horizontal',
  percentage = false
}) => {
  return (
    <Paper
      onClick={onClick}
      elevation={3}
      className={`${isActive ? 'active' : ''}`}
      sx={{
        boxShadow: '0px 6px 30px rgba(182, 186, 203, 0.3)',
        borderRadius: '10px',
        background: 'linear-gradient(to left, #FFFFFF 0%, #FFFFFF 100%)',
        p: 2.5,
        transition: 'all 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0px 10px 40px rgba(182, 186, 203, 0.4)'
        },
        userSelect: 'none',
        cursor: 'pointer',
        ...(isActive && {
          background: 'linear-gradient(to left, #00AEEF 0%, rgba(0, 82, 120, 1) 100%)'
        }),
        ...(align === 'vertical' && { height: '100%' })
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: align === 'horizontal' ? 'row' : 'column',
          ...(align === 'vertical' && { height: '100%' })
        }}>
        {/* Icon */}
        <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
          <Box
            sx={{
              width: '45px',
              height: '45px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <img
              src={isActive ? activeIcon : icon}
              alt="icon"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
            />
          </Box>

          {/* Title */}
          {align === 'horizontal' ? (
            <Typography
              sx={{
                fontsize: '16px',
                fontWeight: 600,
                fontFamily: 'Open Sans, sans-serif',
                marginLeft: '1rem',
                ...(isActive && {
                  color: 'white'
                })
              }}>
              {title}
            </Typography>
          ) : null}
        </Box>

        {align === 'vertical' ? <Box sx={{ height: '20px', width: '100%' }}></Box> : null}

        {/* Title */}
        {align === 'vertical' ? (
          <Typography
            sx={{
              fontsize: '16px',
              fontWeight: 600,
              fontFamily: 'Open Sans, sans-serif',
              textAlign: 'center',
              ...(isActive && {
                color: 'white'
              })
            }}>
            {title}
          </Typography>
        ) : null}

        {align === 'vertical' ? <Box sx={{ height: '20px', width: '100%' }}></Box> : null}

        {/* Count */}
        <Typography
          className={`${isActive ? 'active' : ''}`}
          sx={{
            fontWeight: 'bold',
            background: `linear-gradient(90deg, #00AEEF 0%, rgba(0, 82, 120, 1) 100%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontSize: '20px',
            ...(isActive && {
              color: 'white',
              WebkitTextFillColor: 'unset'
            })
          }}>
          {isLoading ? <Skeleton animation="wave" width={50} /> : `${count}${percentage ? '%' : ''}`} 
        </Typography>
      </Box>
    </Paper>
  );
};

export default ThemeStatCard;
