import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

type ClientResponseDTO = {
  clientID: number;
  clientName: string;
  clientCode: string;
  clientType: string;
  isEnabled: string | null;
  websiteURL: string | null;
  businessRegistrationNumber: string | null;
  partnerLogoUrl: string | null;
  streetAddress: string | null;
  city: string | null;
  state: string | null;
  pincode: number | null;
  country: string | null;
  emailAddress: string | null;
  phoneNumber: string | null;
  billingAddress: string | null;
  createdDate: Date | null; 
  createdBy: string | null;
  updateDate: Date | null;
  updatedBy: string | null;
  deletedDate: Date | null;
};

type ClientState = {
  total: number | null;
  items: ClientResponseDTO[];
};
const initialState: ClientState = { total: null, items: [] };

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientData: (
      state,
      action: PayloadAction<{
        total: number;
        items: ClientResponseDTO[];
      }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    },
  },
});

export const { setClientData } = clientSlice.actions;

export const selectAllClient = (state: RootState) => state.client.items;

export default clientSlice.reducer;
