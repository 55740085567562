import { DEFAULT_LIMIT, ListResponse, SuccessResponce, QueryParams } from 'utils/constants';
import { api } from './api';

export interface ICRMLeadRemark {
  activityID: number;
  agentID: number;
  notes: string;
  activityDate: string;
}

export interface ICRMGetLeadDetailsResponse extends ICRMLeadItem {
  remarks: ICRMLeadRemark[];
}

export interface ICRMContactItem {
  contactID: number;
  contactName: string;
  contactEmail?: string;
  contactPhone?: string;
}

export interface ICRMLeadItem {
  id: number;
  mobileNumber: string;
  name: string;
  caseDescription: string;
  status: string;
  source: string;
  category: string;
  createdDate: string;
  updatedDate?: string;
  updatedBy?: string;
  contact?: ICRMContactItem;
  assignedAgentName?: string;
  assignedAgentID?: string;
  updatedByAgentName?: string;
}

export interface ICRMGetLeadsResponse extends ListResponse<ICRMLeadItem> {}

export interface ICRMGetLeadDetailsResponse extends ICRMLeadItem {}

export interface ISelfAssignInput {
  leadID: number;
}

export interface ICreateCrmLeadInput {
  mobileNumber: string;
  name: string;
  caseDescription: string;
  source: string;
  category?: string;
}

export interface IAddRemarkInput {
  leadID: number;
  agentID: number;
  remarkText: string;
}

export interface ICreateCustomerAccountInput {
  leadID: number;
  leadName: string;
  leadMobileNumber: string;
  contactEmail?: string;
  address?: string;
}

export interface ICreateJobInput {
  leadID: number;
}

export interface IAddRemarkInput {
  leadID: number;
  agentID: number;
  remarkText: string;
  activityType: string;
  status?: string;
}

export interface IMarkLeadConvertedInput {
  leadID: number;
}

export const crmApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listCrmLeads: builder.query<
      ICRMGetLeadsResponse,
      QueryParams & {
        mobileNumber?: string;
        name?: string;
        status?: any;
        description?: string;
        createdDate?: string[];
        category?: string;
      }
    >({
      query: (params) => {
        const {
          limit = DEFAULT_LIMIT,
          offset = 0,
          mobileNumber,
          name,
          status,
          description,
          createdDate,
          category,
          queryString
        } = params;

        if (queryString) {
          return `/crm/leads?${queryString}`;
        }

        let queryUrl = `/crm/leads?limit=${limit}&offset=${offset}`;

        if (mobileNumber) {
          queryUrl += `&mobileNumber=${encodeURIComponent(mobileNumber)}`;
        }
        if (name) {
          queryUrl += `&name=${encodeURIComponent(name)}`;
        }
        if (status) {
          queryUrl += `&status=${encodeURIComponent(status?.value)}`;
        }
        if (description) {
          queryUrl += `&description=${encodeURIComponent(description)}`;
        }
        if (createdDate && Array.isArray(createdDate)) {
          createdDate.forEach((date) => {
            queryUrl += `&createdDate=${encodeURIComponent(date)}`;
          });
        }
        if (category) {
          queryUrl += `&category=${encodeURIComponent(category)}`;
        }

        return queryUrl;
      },
      transformResponse: (res: ICRMGetLeadsResponse) => {
        const items = res?.items?.map((item: ICRMLeadItem) => ({
          ...item,
          id: item.id
        }));
        return { ...res, items };
      },
      providesTags: (result = { items: [], total: 0 }) => {
        const items = result.items.map(({ id }) => ({ type: 'CRM' as const, id })) ?? [];
        return [...items, { type: 'CRM' as const, id: 'LIST' }];
      }
    }),
    getCrmLeadDetails: builder.query<ICRMGetLeadDetailsResponse, { leadID: number }>({
      query: ({ leadID }) => `/crm/leads/${leadID}`,
      providesTags: (result, error, arg) => [{ type: 'CRM', id: arg.leadID }]
    }),
    createCrmLead: builder.mutation<SuccessResponce, ICreateCrmLeadInput>({
      query: (body) => ({
        url: `/crm/leads`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'CRM', id: 'LIST' }]
    }),

    // 1) Add Remark
    addRemark: builder.mutation<SuccessResponce, IAddRemarkInput>({
      query: ({ leadID, agentID, remarkText, activityType, status }) => {
        return {
          url: `/crm/leads/${leadID}/remarks`,
          method: 'POST',
          body: {
            agentID,
            remarkText,
            activityType: activityType,
            status
          }
        };
      },
      invalidatesTags: (result, error, { leadID }) => [{ type: 'CRM', id: leadID }]
    }),

    // 2) Create Customer Account
    createCustomerAccount: builder.mutation<SuccessResponce, ICreateCustomerAccountInput>({
      query: ({ leadID, leadName, leadMobileNumber, contactEmail, address }) => {
        return {
          url: `/crm/leads/${leadID}/create-customer-account`,
          method: 'POST',
          body: {
            leadName,
            leadMobileNumber,
            contactEmail,
            address
          }
        };
      },
      invalidatesTags: (result, error, { leadID }) => [{ type: 'CRM', id: leadID }]
    }),
    createJob: builder.mutation<SuccessResponce, ICreateJobInput>({
      query: ({ leadID }) => ({
        url: `/crm/leads/${leadID}/create-job`,
        method: 'POST'
      }),
      invalidatesTags: (result, error, { leadID }) => [{ type: 'CRM', id: leadID }]
    }),
    selfAssign: builder.mutation<SuccessResponce, ISelfAssignInput>({
      query: ({ leadID }) => ({
        url: `/crm/leads/${leadID}/self-assign`,
        method: 'POST'
      }),
      invalidatesTags: (result, error, { leadID }) => [{ type: 'CRM', id: leadID }]
    }),
    markLeadAsConverted: builder.mutation<SuccessResponce, IMarkLeadConvertedInput>({
      query: ({ leadID }) => ({
        url: `/crm/leads/${leadID}/mark-converted`,
        method: 'POST'
      }),
      invalidatesTags: (result, error, { leadID }) => [
        { type: 'CRM', id: leadID },
        { type: 'CRM', id: 'LIST' }
      ]
    })
  })
});

export const {
  useListCrmLeadsQuery,
  useLazyListCrmLeadsQuery,
  useGetCrmLeadDetailsQuery,
  useCreateCrmLeadMutation,
  useAddRemarkMutation,
  useCreateCustomerAccountMutation,
  useCreateJobMutation,
  useSelfAssignMutation,
  useMarkLeadAsConvertedMutation
} = crmApi;
