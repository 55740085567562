import { SuccessResponce } from 'utils/constants';
import { api } from './api';

export type CreateClientDTO = {
  clientName: string;
  clientCode: string;
  clientType: 'PACKAGE' | 'SERVICE';
  isEnabled: 'Y' | 'N';
  websiteURL?: string;
  businessRegistrationNumber?: string;
  partnerLogoUrl?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  pincode?: number;
  country?: string;
  emailAddress?: string;
  phoneNumber?: string;
  billingAddress?: string;
  clientServiceCategories?: {
    serviceCategoryID: number;
    basePrice: number;
    kmCovered?: number;
    pricePerExtraKM?: number;
    description?: string;
  }[];
};

export type UpdateClientDTO = Partial<
  Omit<CreateClientDTO, 'clientCode' | 'clientServiceCategories'>
>;

export type CreateClientServiceCategoryDTO = {
  serviceCategoryID: number;
  basePrice: number;
  kmCovered?: number;
  pricePerExtraKM?: number;
  description?: string;
};

export type UpdateClientServiceCategoryDTO = Partial<CreateClientServiceCategoryDTO>;

export type ListClientsQueryParams = {
  page?: number;
  limit?: number;
  search?: string;
  clientType?: 'PACKAGE' | 'SERVICE';
  isEnabled?: 'Y' | 'N';
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
  createdBetween?: string;
  createdBy?: string;
  updatedBy?: string;
};

export type SuccessResponse<T = any> = {
  success: boolean;
  message: string;
  data?: T;
};

type ServiceCategoryDto = {
  serviceCategoryID: number;
  serviceCategoryName: string;
  isCRMOnly?: 'Y' | 'N';
  serviceCategoryDescription?: string;
  serviceCategoryImage?: string;
  serviceCategoryEnabled?: 'Y' | 'N';
};

type ClientServiceCategoryDto = {
  clientServiceCategoryID: number;
  serviceCategoryID: number;
  basePrice: number;
  kmCovered?: number;
  pricePerExtraKM?: number;
  description?: string;
  serviceEnabled: 'Y' | 'N';
  createdDate: string;
  createdBy: string;
  updateDate?: string;
  updatedBy?: string;
  serviceCategory?: ServiceCategoryDto;
};

type ClientDetailsDto = {
  clientID: number;
  clientName: string;
  clientCode: string;
  clientType: 'PACKAGE' | 'SERVICE';
  isEnabled: 'Y' | 'N';
  websiteURL?: string;
  businessRegistrationNumber?: string;
  partnerLogoUrl?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  pincode?: number;
  country?: string;
  emailAddress?: string;
  phoneNumber?: string;
  billingAddress?: string;
  createdDate: string;
  createdBy: string;
  updateDate?: string;
  updatedBy?: string;
  deletedDate?: string;
  clientServiceCategories?: ClientServiceCategoryDto[];
};

export const partnerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createClient: builder.mutation<SuccessResponce, Partial<CreateClientDTO>>({
      query(body) {
        return {
          url: `/client`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['Client']
    }),

    listClients: builder.query<SuccessResponce, ListClientsQueryParams>({
      query(params) {
        return {
          url: '/client',
          method: 'GET',
          params
        };
      },
      providesTags: ['Client']
    }),

    updateClient: builder.mutation<SuccessResponce, { id: number; body: Partial<UpdateClientDTO> }>(
      {
        query({ id, body }) {
          return {
            url: `/client/${id}`,
            method: 'PUT',
            body
          };
        },
        invalidatesTags: ['Client']
      }
    ),

    deleteClient: builder.mutation<SuccessResponce, { id: number }>({
      query({ id }) {
        return {
          url: `/client/${id}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: ['Client']
    }),

    getClientDetails: builder.query<ClientDetailsDto, number>({
      query: (id) => ({
        url: `/client/${id}`,
        method: 'GET'
      }),
      providesTags: ['Client']
    }),

    createClientCategory: builder.mutation<
      SuccessResponce,
      { clientId: number; body: Partial<CreateClientServiceCategoryDTO> }
    >({
      query({ clientId, body }) {
        return {
          url: `/client/${clientId}/category`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['Client']
    }),

    updateClientCategory: builder.mutation<
      SuccessResponce,
      { clientId: number; categoryId: number; body: Partial<UpdateClientServiceCategoryDTO> }
    >({
      query({ clientId, categoryId, body }) {
        return {
          url: `/client/${clientId}/category/${categoryId}`,
          method: 'PUT',
          body
        };
      },
      invalidatesTags: ['Client']
    }),

    deleteClientCategory: builder.mutation<
      SuccessResponce,
      { clientId: number; categoryId: number }
    >({
      query({ clientId, categoryId }) {
        return {
          url: `/client/${clientId}/category/${categoryId}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: ['Client']
    })
  })
});

export const {
  useCreateClientMutation,
  useListClientsQuery,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useCreateClientCategoryMutation,
  useUpdateClientCategoryMutation,
  useDeleteClientCategoryMutation,
  useGetClientDetailsQuery
} = partnerApi;
