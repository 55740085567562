// src/pages/components/CrmLeadsHeader.tsx
import { FC } from 'react';
import { TuneOutlined } from '@mui/icons-material';
import { Button, Grid, IconButton, Typography } from '@mui/material';

export interface ICrmLeadsHeaderProps {
  openFilterModal: () => void;
}

const CrmLeadsHeader: FC<ICrmLeadsHeaderProps> = ({ openFilterModal }) => {
  return (
    <Grid container spacing={3} justifyContent="space-between" sx={{ mb: 4 }}>
      <Grid item>
        <Typography variant="h2">CRM Leads</Typography>
      </Grid>
      <Grid item />
      <Grid
        item
        sx={{ p: 0, paddingLeft: '0px !important', paddingTop: '0px !important' }}
        alignContent="center">
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            {/* <Button size="small" variant="contained" onClick={handleAddLead}>
              Add Lead
            </Button> */}
          </Grid>
          <Grid item>
            <IconButton onClick={openFilterModal}>
              <TuneOutlined sx={{ color: '#D9D9D9' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CrmLeadsHeader;
