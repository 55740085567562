import { Box, Button, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material';
import {
  useAdminRecordRosterPlanEntriesMutation,
  useGetAgentRosterMasterDataQuery,
  useGetAgentRosterPlanQuery
} from 'app/services/shift';
import { useEffect, useState } from 'react';
import CustomDataGrid from './components/test-table';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CreateRosterPlan = () => {
  const [planDate, setPlanDate] = useState<{
    title: string;
    value: string | null;
  }>({
    title: '---',
    value: null
  });

  const nav = useNavigate();

  const [planData, setPlanData] = useState<any>({});
  const [formattedEntries, setFormattedEntries] = useState<any[]>([]);

  const { data: masterData, isLoading: isMasterDataLoading } = useGetAgentRosterMasterDataQuery();

  const { data, isLoading, refetch } = useGetAgentRosterPlanQuery({
    planMonth: planDate?.value ? planDate.value.split('-')[0] : undefined,
    planYear: planDate?.value ? planDate.value.split('-')[1] : undefined
  });

  const [adminRecordRosterPlanEntries, { isLoading: isCreateLoading }] =
    useAdminRecordRosterPlanEntriesMutation();

  const handleMasterDataChange = () => {
    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();

    const planExists: any = masterData?.availablePlans?.filter((plan: any) => {
      return plan.planMonth == currentMonth && plan.planYear == currentYear;
    });

    if (planExists?.length > 0) {
      setPlanDate({
        title: moment().add(1, 'month').format('MMM YY'),
        value: moment().add(1, 'month').format('MM-YYYY')
      });
      refetch();
    } else {
      //set plan date to current month and refetch
      setPlanDate({
        title: moment().format('MMM YY'),
        value: moment().format('MM-YYYY')
      });
    }
  };

  const handleDataChange = () => {
    setPlanData(data);
  };

  useEffect(() => {
    if (masterData) handleMasterDataChange();
  }, [masterData]);

  useEffect(() => {
    if (data) handleDataChange();
  }, [data]);

  //TODO: optimize this logic
  const onPlanChange = (
    value: {
      duty: number;
      dutyName: string;
      leaveReasonID: string;
      leaveReasonName: string;
      otherReasonText: string;
      shift: number;
      shiftName: string;
      selectedCells: { row: string; field: string }[];
      workConditionName: string;
      workConditionColorCode: string;
      workConditionCode: string;
      dutyCode: string;
      dutyColorCode: string;
      leaveReasonCode: string;
      leaveReasonColorCode: string;
      shiftColorCode: string;
    },
    cb?: () => void
  ) => {
    const {
      duty,
      leaveReasonID,
      otherReasonText,
      shift,
      workConditionCode,
      workConditionColorCode,
      workConditionName,
      selectedCells,
      dutyName,
      leaveReasonName,
      shiftName,
      dutyCode,
      dutyColorCode,
      leaveReasonColorCode,
      leaveReasonCode,
      shiftColorCode
    } = value;
    const agentID = selectedCells[0].field;
    const selectedDates = selectedCells.map((cell) => cell.row);

    let dates = JSON.parse(JSON.stringify(planData.dates));

    dates?.forEach((date: any) => {
      if (selectedDates.includes(date.date)) {
        const agentDetailsIndex = date.entries.findIndex((entry: any) => entry.agentID === agentID);

        date.entries[agentDetailsIndex].dutyID = duty;
        date.entries[agentDetailsIndex].dutyName = dutyName;
        date.entries[agentDetailsIndex].leaveReasonID = leaveReasonID;
        date.entries[agentDetailsIndex].leaveReasonName = leaveReasonName;
        date.entries[agentDetailsIndex].otherReasonText = otherReasonText;
        date.entries[agentDetailsIndex].shiftID = shift;
        date.entries[agentDetailsIndex].shiftName = shiftName;
        date.entries[agentDetailsIndex].workConditionCode = workConditionCode;
        date.entries[agentDetailsIndex].workConditionName = workConditionName;
        date.entries[agentDetailsIndex].workConditionColorCode = workConditionColorCode;
        date.entries[agentDetailsIndex].dutyCode = dutyCode;
        date.entries[agentDetailsIndex].dutyColorCode = dutyColorCode;
        date.entries[agentDetailsIndex].leaveReasonCode = leaveReasonCode;
        date.entries[agentDetailsIndex].leaveReasonColorCode = leaveReasonColorCode;
        date.entries[agentDetailsIndex].shiftColorCode = shiftColorCode;
      }
    });

    setPlanData({ ...planData, dates });
    //@ts-ignore
    cb();
  };

  const handleCreatePlan = async () => {
    //TODO: revaluate this logic
    if (planData?.dates?.length > 0) {
      const hasEmptyDays = formattedEntries.some((entry: any) =>
        Object.values(entry).some(
          (value: any) =>
            typeof value === 'object' &&
            !value?.dutyID &&
            !value?.shiftID &&
            !value?.leaveReasonID &&
            !value?.workConditionCode
        )
      );

      if (hasEmptyDays) {
        toast.error("You can't create a plan with empty days");
        return;
      }

      try {
        await adminRecordRosterPlanEntries({
          input: {
            isCreatePlan: true,
            planMonth: moment(planDate.value, 'MM-YYYY').month() + 1,
            planYear: moment(planDate.value, 'MM-YYYY').year(),
            rosterEntries: formattedEntries
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  /*
    const handleCreatePlan = () => {
    if (planData && planData.dates.length > 0) {
      let hasEmptyDays = false;
      for (let i = 0; i < formattedEntries.length; i++) {
        const entry: any = formattedEntries[i];
        const keys = Object.keys(entry);

        for (let j = 0; j < keys.length; j++) {
          if (
            typeof entry[keys[j]] === 'object' &&
            !entry[keys[j]]?.dutyID &&
            !entry[keys[j]]?.shiftID &&
            !entry[keys[j]]?.leaveReasonID &&
            !entry[keys[j]]?.workConditionCode
          ) {
            hasEmptyDays = true;
            break;
          }
          // console.log("inner loop");
        }

        if (hasEmptyDays) {
          break;
        }
        // console.log("outer loop")
      }
    }
  };
  */

  const handleBack = () => {
    nav(-1);
  };

  return (
    <Grid container>
      {isMasterDataLoading || isLoading ? (
        <Box
          width={'100%'}
          height={'100%'}
          display={'flex'}
          justifyContent={'center'}
          minHeight={'70vh'}
          alignItems={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid item xs={12} sx={{ mb: 4 }}>
            <Tooltip title="Go Back" arrow>
              <IconButton
                onClick={handleBack}
                sx={{
                  color: '#1976d2',
                  '&:hover': {
                    backgroundColor: '#f0f8ff'
                  },
                  marginRight: '1rem',
                  padding: 0
                }}>
                <ArrowBackIcon fontSize="medium" />
              </IconButton>
            </Tooltip>

            <Button variant="outlined" size="small" style={{ marginRight: '2rem' }}>
              {planDate.title}
            </Button>

            <LoadingButton
              variant="outlined"
              size="small"
              onClick={handleCreatePlan}
              loading={isCreateLoading}>
              Create Plan
            </LoadingButton>
          </Grid>

          <Grid item xs={12}>
            <CustomDataGrid
              data={planData}
              isLoading={isLoading}
              masterData={masterData}
              onPlanChange={onPlanChange}
              formattedEntries={formattedEntries}
              setFormattedEntries={setFormattedEntries}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default CreateRosterPlan;
