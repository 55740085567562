import { api } from './api';

interface SuccessResponse {
  success: boolean;
  message: string;
}

interface UpdateWorkStatusInput {
  workStatus: 'BREAK_ON' | 'BREAK_OFF';
}
export const customerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateWorkStatus: builder.mutation<SuccessResponse, UpdateWorkStatusInput>({
      query(body) {
        return {
          url: `/admin/agent-roster/break`,

          method: 'POST',

          body
        };
      },
      invalidatesTags: ['WorkStatus']
    })
  })
});

export const { useUpdateWorkStatusMutation } = customerApi;
