import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery
  // retry
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { logout } from 'pages/auth/authSlice';
import { openToastMessage } from 'app/slices/toastMessageSlice';

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  // baseUrl: '/',
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a accessToken in the store, let's use that for authenticated requests
    const accessToken = (getState() as RootState)?.auth?.accessToken;
    // const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }
    return headers;
  }
});

const baseQueryWithCheckUnauthorized: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result: any = await baseQuery(args, api, extraOptions);

  if (result.error) {
    api.dispatch(
      openToastMessage({
        message:
          typeof result.error?.data === 'string' ? result.error?.data : result.error?.data?.message,
        type: 'error',
        key: Math.random().toString(36).substring(7)
      })
    );
  }

  if (result.error && result.error.status === 401) {
    // api.dispatch(
    //   openToastMessage({ message: result.error.data?.message, type: 'error' })
    // );
    api.dispatch(logout());
    // try to get a new token
    // const refreshResult = await baseQuery('/refreshToken', api, extraOptions);
    // if (refreshResult.data) {
    //   // store the new token
    //   api.dispatch(tokenReceived(refreshResult.data));
    //   // retry the initial query
    //   result = await baseQuery(args, api, extraOptions);
    // } else {
    //   api.dispatch(logout());
    // }
  }
  return result;
};

// const baseQueryWithRetry = retry(baseQueryWithCheckUnauthorized, {
//   maxRetries: 3
// });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'splitApi',
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */

  baseQuery: baseQueryWithCheckUnauthorized, // baseQueryWithRetry,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    'Auth',
    'Garage',
    'Location',
    'Promotion',
    'Banner',
    'Files',
    'Category',
    'Service',
    'Document',
    'Vehicle',
    'Service',
    'VehicleBrand',
    'ServiceCategory',
    'Settings',
    'Technician',
    'Messages',
    'Customer',
    'Job',
    'Report',
    'Payment',
    'User',
    'Role',
    'CustomerVehicles',
    'JobQue',
    'AdminRemak',
    'Agreement',
    'JobTypes',
    'Inspection',
    'Language',
    'State',
    'Shift',
    'ShiftDetails',
    'WorkStatus',
    'Dashboard',
    'subscriptionPlans',
    'CRM',
    'RosterTeams',
    'Client',
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({})
});

// export const enhancedApi = api.enhanceEndpoints({
//   endpoints: () => ({
//     getPost: () => 'test',
//   }),
// });
