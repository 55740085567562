import { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import { TuneOutlined } from '@mui/icons-material';
import {
  useDeleteSubscriptionPlanMutation,
  useLazyListSubscriptionPlansQuery
} from 'app/services/subscriptionPlans';
import { DEFAULT_LIMIT } from 'utils/constants';
import { Box, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import { DataGridTheme } from 'utils/theme';
import { motion } from 'framer-motion';
import { pageTransition, pageVariants } from 'utils/motionConfig';
import useThemedFilter, { ThemedFiltersModal } from 'hooks/useThemedFilter';
import CreateSubscriptionPlanModal from './components/CreateSubscriptionPlan';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';
import EditOutlineIcon from '@ant-design/icons/EditOutlined';
import DeleteIcon from '@ant-design/icons/DeleteOutlined';
import { useDispatch } from 'react-redux';
import { openToastMessage } from 'app/slices/toastMessageSlice';
import EditSubscriptionPlanModal from './components/EditSubscriptionPlan';

// Filter template for the filter popup
const filterTemplate: any[] = [
  {
    type: 'select',
    label: 'Status',
    fieldKey: 'isActive',
    options: [
      { label: 'All', value: '' },
      { label: 'Active', value: 'Y' },
      { label: 'Inactive', value: 'N' }
    ],
    whereOption: 'eq'
  },
  {
    type: 'text',
    label: 'Plan Name',
    fieldKey: 'planName',
    whereOption: 'contains'
  },
  {
    type: 'checkbox',
    label: 'Show Deleted Plans',
    fieldKey: 'isDeleted',
    options: [{ label: 'Include Deleted Plans', value: 'Y' }],
    whereOption: 'eq'
  }
];

// Column configuration for DataGrid
const columns: (actions: {
  onDelete?: (row: any) => void;
  onUpdate?: (row: any) => void;
}) => GridColDef[] = ({ onDelete, onUpdate }) => [
  { field: 'planName', headerName: 'Plan Name', minWidth: 150, flex: 1, sortable: false },
  {
    field: 'planDescription',
    headerName: 'Plan Description',
    minWidth: 200,
    flex: 2,
    sortable: false
  },
  { field: 'planPrice', headerName: 'Price', minWidth: 100, flex: 1, sortable: false },
  {
    field: 'planDuration',
    headerName: 'Duration',
    minWidth: 120,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) =>
      `${params.row.planDuration} ${params.row.durationUnit}`
  },
  { field: 'order', headerName: 'Order', minWidth: 80, flex: 1, sortable: false },
  { field: 'versionNumber', headerName: 'Version', minWidth: 80, flex: 1, sortable: false },
  {
    field: 'isActive',
    headerName: 'Status',
    minWidth: 100,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (params.value === 'Y' ? 'Active' : 'Inactive')
  },
  {
    field: 'isDeleted',
    headerName: 'Deleted',
    minWidth: 100,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (params.value === 'Y' ? 'Yes' : 'No')
  },
  { field: 'createdBy', headerName: 'Created By', minWidth: 150, flex: 1, sortable: false },
  { field: 'updatedBy', headerName: 'Updated By', minWidth: 150, flex: 1, sortable: false },
  {
    field: 'updatedDate',
    headerName: 'Last Updated Date',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) =>
      params?.value ? moment(params.value).format('YYYY-MM-DD HH:mm:ss') : ''
  },
  {
    field: 'actions',
    headerName: 'Actions',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any>) => (
      <Grid container spacing={1} display="flex" justifyContent="space-evenly" alignItems="center">
        <Button variant="text" onClick={onUpdate ? () => onUpdate(params.row) : undefined}>
          <EditOutlineIcon />
        </Button>
        <Button variant="text" onClick={onDelete ? () => onDelete(params.row) : undefined}>
          <DeleteIcon />
        </Button>
      </Grid>
    )
  }
];

const SubscriptionPlansListing = () => {
  const [createSubscriptionPlanModalOpen, setCreateSubscriptionPlanModalOpen] = useState(false);
  const [editSubscriptionPlanModalOpen, setEditSubscriptionPlanModalOpen] = useState(false);
  const [deleteSubscriptionPlanModalOpen, setDeleteSubscriptionPlanModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [paginationModel, setPaginationModel] = useState({ pageSize: DEFAULT_LIMIT, page: 0 });

  const [getData, { data, isLoading }] = useLazyListSubscriptionPlansQuery();
  const [deleteSubscriptionPlan, { isLoading: isDeleteLoading }] =
    useDeleteSubscriptionPlanMutation();

  const {
    openFilterModal,
    modalOpen,
    closeFilterModal,
    clearFilters,
    filtersObj,
    setFiltersObj,
    applyFilters,
    handleFilterClear,
    appliedFilters
  } = useThemedFilter({ filterTemplate });

  const dispatch = useDispatch();

  const handleLoadData = () => {
    const filterItems: { [key: string]: any } = {};
    if (appliedFilters) {
      if (appliedFilters?.planName) filterItems.planName = appliedFilters.planName;
      if (appliedFilters?.isActive?.value) filterItems.isActive = appliedFilters.isActive.value;
      if (appliedFilters?.isDeleted) filterItems.isDeleted = appliedFilters.isDeleted;
    }
    getData({
      limit: paginationModel.pageSize,
      offset: paginationModel.page * paginationModel.pageSize,
      ...filterItems
    });
  };

  useEffect(() => {
    handleLoadData();
  }, [appliedFilters]);

  const handleOpenCreateSubscriptionPlanModal = () => {
    setCreateSubscriptionPlanModalOpen(true);
  };

  const handleCloseCreateSubscriptionPlanModal = () => {
    setCreateSubscriptionPlanModalOpen(false);
  };

  const handleOpenDeleteSubscriptionPlanModal = (plan: any) => {
    setDeleteSubscriptionPlanModalOpen(true);
    setSelectedPlan(plan);
  };

  const handleCloseDeleteSubscriptionPlanModal = () => {
    setDeleteSubscriptionPlanModalOpen(false);
    setSelectedPlan(null);
  };

  const handleOpenEditSubscriptionPlanModal = (plan: any) => {
    setEditSubscriptionPlanModalOpen(true);
    setSelectedPlan(plan);
  };

  const handleCloseEditSubscriptionPlanModal = () => {
    setEditSubscriptionPlanModalOpen(false);
    setSelectedPlan(null);
  };

  const handleConfirmDeleteSubscriptionPlan = async () => {
    if (selectedPlan && selectedPlan?.planID) {
      try {
        const res: any = await deleteSubscriptionPlan(selectedPlan.planID);
        if (res?.error?.data) {
          dispatch(
            openToastMessage({
              type: 'error',
              message: res?.error?.data?.message ?? 'Something went wrong'
            })
          );

          return;
        }
        dispatch(
          openToastMessage({
            type: 'success',
            message: res?.data?.message ?? 'Subscription Plan Deleted Successfully'
          })
        );
        handleCloseDeleteSubscriptionPlanModal();
      } catch (err) {
        dispatch(
          openToastMessage({
            type: 'error',
            message: 'Something went wrong'
          })
        );
      }
    }
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageVariants}
      transition={pageTransition}
      style={{ width: '100%' }}>
      <Grid container spacing={3} justifyContent="center" alignItems="center" width={'100%'}>
        {/* Header Section */}
        <Grid
          xs={12}
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={'100%'}>
          {/* Title */}
          <Typography variant="h5" fontWeight="bold">
            Subscription Plans
          </Typography>

          {/* Right Section: Create Plan and Filters */}
          <Box display="flex" gap={2}>
            {/* Filters Button */}
            <IconButton onClick={openFilterModal}>
              <TuneOutlined sx={{ color: '#D9D9D9' }} />
            </IconButton>
          </Box>
        </Grid>

        <Grid xs={4} display="flex" flexGrow={1} justifyContent="right" alignItems="center">
          <Button variant="contained" onClick={handleOpenCreateSubscriptionPlanModal}>
            + Create Plan
          </Button>
        </Grid>

        {/* DataGrid Section */}
        <Grid xs={12} width={'100%'}>
          <Box sx={{ width: '100%', overflow: 'auto' }}>
            <DataGrid
              rows={data?.items ?? []}
              columns={columns({
                onDelete: handleOpenDeleteSubscriptionPlanModal,
                onUpdate: handleOpenEditSubscriptionPlanModal
              })}
              rowCount={data?.total ?? 0}
              loading={isLoading}
              pageSizeOptions={[10, 25, 50]}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel}
              sx={{ ...DataGridTheme }}
              disableRowSelectionOnClick
              disableColumnSelector
              disableDensitySelector
              getRowId={(row) => row.planID}
              localeText={{
                noRowsLabel: 'No subscription plans found.'
              }}
            />
          </Box>
        </Grid>

        {/* Filter Modal */}
        <ThemedFiltersModal
          filtersObj={filtersObj}
          setFiltersObj={setFiltersObj}
          modalOpen={modalOpen}
          closeFilterModal={closeFilterModal}
          filterTemplate={filterTemplate}
          handleFilterClear={handleFilterClear}
          applyFilters={applyFilters}
          clearFilters={clearFilters}
        />
      </Grid>

      <CreateSubscriptionPlanModal
        isOpen={createSubscriptionPlanModalOpen}
        onClose={handleCloseCreateSubscriptionPlanModal}
      />

      <DeleteConfirmationModal
        open={deleteSubscriptionPlanModalOpen}
        onClose={handleCloseDeleteSubscriptionPlanModal}
        onConfirm={handleConfirmDeleteSubscriptionPlan}
        isLoading={isDeleteLoading}
        message="Are you sure you want to delete this subscription plan? This action cannot be undone."
      />

      <EditSubscriptionPlanModal
        isOpen={editSubscriptionPlanModalOpen}
        onClose={handleCloseEditSubscriptionPlanModal}
        plan={selectedPlan}
      />
    </motion.div>
  );
};

export default SubscriptionPlansListing;
