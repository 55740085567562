import React, { useState } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  IconButton,
  Box,
  Paper
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import UserList from './UserList';

const TeamAccordion = ({
  team,
  index,
  allowActions,
  onUserMoved,
  onDeleteTeam,
  handleUpdateTeamName
}: any) => {
  const [editTeamId, setEditTeamId] = useState(null);
  const [teamNameDraft, setTeamNameDraft] = useState(team.name);
  const [expanded, setExpanded] = useState(false);

  const handleSaveTeamName = () => {
    setEditTeamId(null);
    handleUpdateTeamName(team.id, teamNameDraft, null);
  };

  const handleCancelEdit = () => {
    setEditTeamId(null);
  };

  const handleToggleAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <Draggable
      key={team.id}
      draggableId={team.id}
      index={index}
      //@ts-ignore
      type="team"
      isDragDisabled={!allowActions}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Paper sx={{ width: '100%', boxShadow: 3, mb: 2 }}>
            <Accordion expanded={expanded} onChange={handleToggleAccordion}>
              <AccordionSummary
                {...provided.dragHandleProps}
                expandIcon={<ExpandMoreIcon />}
                sx={{ bgcolor: 'background.paper' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexGrow: 1 }}>
                  <DragIndicatorIcon
                    sx={{ color: allowActions ? 'action.active' : 'action.disabled' }}
                  />
                  {editTeamId === team.id ? (
                    <>
                      <TextField
                        value={teamNameDraft}
                        onChange={(e) => setTeamNameDraft(e.target.value)}
                        variant="standard"
                        sx={{ flexGrow: 1 }}
                        disabled={!allowActions}
                      />
                      <IconButton onClick={handleSaveTeamName} disabled={!allowActions}>
                        <CheckIcon />
                      </IconButton>
                      <IconButton onClick={handleCancelEdit} disabled={!allowActions}>
                        <CloseIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <Typography sx={{ flexGrow: 1 }}>{team.name}</Typography>
                      <IconButton onClick={() => setEditTeamId(team.id)} disabled={!allowActions}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => onDeleteTeam(team.id)} disabled={!allowActions}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </AccordionSummary>

              {/* Keep Droppable Active Even When Collapsed */}
              <AccordionDetails
                sx={{
                  minHeight: 80, // Ensure droppable area remains interactive
                  transition: 'all 0.3s ease-in-out',
                  display: expanded ? 'block' : 'none' // Hide visually but keep space for dropping
                }}>
                <UserList team={team} allowActions={allowActions} onUserMoved={onUserMoved} />
              </AccordionDetails>
            </Accordion>
          </Paper>
        </div>
      )}
    </Draggable>
  );
};

export default TeamAccordion;
