import { DEFAULT_LIMIT, SuccessResponce } from 'utils/constants';
import { api } from './api';

export interface CustomerDTO {
  message: string;
  id?: string;
  customerName: string;
  customerMobile: string;
  customerEmail: string;
  customerPhoneVerified: string;
  mediaUrl: string;
}

export interface CreateCustomerDTO {}

export interface CreateCustomerVehicleDTO {
  customerID: string;
  vehicleID: number;
  vehicleModel: string;
  vehicleFuel: string;
  vehicleRegNumber: string;
  vehicleType: string;
  vehicleEnabled: string;
  vehicleKilometer: number;
}

export const customerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listCustomers: builder.query({
      query: (params) => {
        const { limit = DEFAULT_LIMIT, offset = 0, name, status, date } = params;
        let queryUrl = `/admin/customer?limit=${limit}&offset=${offset}`;

        if (typeof name === 'string') {
          queryUrl += `&name=${name}`;
        }
        if (typeof status === 'boolean') {
          queryUrl += `&status=${status ? 'Y' : 'N'}`;
        }

        if (typeof date === 'object') {
          queryUrl += `&date=${date}`;
        }

        return queryUrl;
      },
      transformResponse: (res: any) => {
        let items = res?.items?.map((item: any) => {
          return { ...item, id: item['customerID'] };
        });
        return { ...res, items };
      },
      providesTags: (result = {}) => {
        const items =
          result?.items?.map(({ id }: { id: string }) => ({ type: 'Customer', id } as const)) ?? [];
        return [...items, { type: 'Customer' as const, id: 'LIST' }];
      }
    }),
    getCustomer: builder.query({
      query: (params) => {
        const { id } = params;
        const queryUrl = `/admin/customer/${id}`;
        return queryUrl;
      },
      providesTags: ['Customer']
    }),
    updateCustomer: builder.mutation<SuccessResponce, Partial<CustomerDTO>>({
      query(body) {
        return {
          url: `/admin/customer/${body.id}`,
          method: 'PUT',
          body
        };
      },
      invalidatesTags: ['Customer']
    }),
    getCustomerJobs: builder.query({
      query: (params) => {
        const { limit = DEFAULT_LIMIT, offset = 0, id } = params;
        let queryUrl = `/admin/customer/${id}/job?limit=${limit}&offset=${offset}`;

        return queryUrl;
      },
      providesTags: ['Customer']
    }),
    getCustomerVehicles: builder.query({
      query: (params) => {
        const { limit = DEFAULT_LIMIT, offset = 0, id } = params;
        let queryUrl = `/admin/customer/${id}/vehicle?limit=${limit}&offset=${offset}`;

        return queryUrl;
      },
      providesTags: ['Customer', 'CustomerVehicles']
    }),
    createCustomerVehicle: builder.mutation<any, CreateCustomerVehicleDTO>({
      query(body) {
        return {
          url: `/admin/customer/${body.customerID}/vehicle`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['CustomerVehicles']
    }),
    createCustomer: builder.mutation({
      query(body) {
        return {
          url: `/admin/customer`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['Customer'],
      extraOptions: {
        maxRetries: 0
      }
    }),
    getByPlateNumber: builder.query({
      query: (params) => {
        const { plateNumber } = params;
        const queryUrl = `/admin/utils/vehicle/plate-number?plateNumber=${plateNumber}`;
        return queryUrl;
      }
    })
    //
  })
});

export const {
  useListCustomersQuery,
  useLazyListCustomersQuery,
  useUpdateCustomerMutation,
  useGetCustomerQuery,
  useLazyGetCustomerQuery,
  useGetCustomerJobsQuery,
  useGetCustomerVehiclesQuery,
  useLazyGetCustomerVehiclesQuery,
  useCreateCustomerMutation,
  useCreateCustomerVehicleMutation,
  useLazyGetByPlateNumberQuery
} = customerApi;
