import { useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme, useMediaQuery } from '@mui/material';

import Header from './header';
import SideBar from './sidebar';
import DrawerHeader from './DrawerHeader';

export default function Layout({ children }: { children: any }) {
  // Initially, you might want the drawer open on desktop and closed on mobile.
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState<boolean>(!isMobile);

  const handleDrawerOpen = () => {
    console.log('hello');
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header open={open} handleDrawerOpen={handleDrawerOpen} />
      <SideBar openDrawer={open} handleDrawerClose={handleDrawerClose} />
      <Box
        component="main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flexGrow={1}
        padding={1}
        paddingLeft={2.5}
        margin={1}
        boxShadow={'border-box'}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
