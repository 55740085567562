import React, { useState } from 'react';
import { TextField, CircularProgress } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { useDebouncedStateV2 } from 'hooks/useDebouncedState';
import { useListClientsQuery } from 'app/services/client';

interface Option {
  label: string;
  value: string;
}

interface ClientSelectProps {
  label: string;
  onSelect: (selectedOption: Option) => void;
}

const ClientSelect = ({ label, onSelect }: ClientSelectProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { inputValue: debouncedInput, handleDebouncedInputChange } = useDebouncedStateV2(
    inputValue,
    300
  );

  const { data, isFetching } = useListClientsQuery(
    { search: debouncedInput },
    { skip: !debouncedInput }
  );

  React.useEffect(() => {
    if (data?.data) {
      const formattedOptions = data.data.map((client: any) => ({
        label: client.clientName,
        value: client.clientCode
      }));
      setOptions(formattedOptions);
    }
  }, [data]);

  React.useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  return (
    <Autocomplete
      options={options}
      fullWidth
      loading={loading}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        handleDebouncedInputChange(newInputValue); // Trigger debounced API call
      }}
      onChange={(event, newValue) => {
        if (newValue) {
          onSelect(newValue); // Call the onSelect handler with the selected option
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};

export default ClientSelect;
