import { DEFAULT_LIMIT, ListResponse, QueryParams, SuccessResponce } from 'utils/constants';
import { api } from './api';

export interface ILanguageItem {
  languageID: number;
  languageName: string;
  languageCode: string;
  createdBy: string;
  createdDate: Date;
  updatedBy: string;
  updatedDate: Date;
}

export interface ICreateLanguageInput {
  languageName: string;
  languageCode: string;
}

type LanguageListResponse = ListResponse<ILanguageItem>;

export const languageApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listLanguages: builder.query<LanguageListResponse, QueryParams>({
      query: (params) => {
        const { limit = DEFAULT_LIMIT, offset = 0, languageName, languageCode } = params;
        let queryUrl = `/admin/languages?limit=${limit}&offset=${offset}`;

        if (typeof languageName === 'string') {
          queryUrl += `&languageName=${languageName}`;
        }

        if (typeof languageCode === 'string') {
          queryUrl += `&languageCode=${languageCode}`;
        }

        return queryUrl;
      },
      transformResponse: (res: LanguageListResponse) => {
        let items = res?.items?.map((item: ILanguageItem) => {
          return { ...item, id: item['languageID'] };
        });
        return { ...res, items };
      },
      providesTags: (
        result: any = {
          items: [],
          total: 0
        }
      ) => {
        const items =
          result?.items?.map(({ id }: { id: number }) => ({ type: 'Language', id: id } as const)) ??
          [];
        return [...items, { type: 'Language' as const, id: 'LIST' }];
      }
    }),
    createLanguage: builder.mutation<SuccessResponce, Partial<ICreateLanguageInput>>({
      query(body) {
        return {
          url: `/admin/languages`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['Language']
    }),
    updateLanguage: builder.mutation<
      SuccessResponce,
      Partial<ICreateLanguageInput & { id: string }>
    >({
      query(body) {
        return {
          url: `/admin/languages/${body.id}`,
          method: 'PUT',
          body
        };
      },
      invalidatesTags: ['Language']
    }),
    deleteLanguage: builder.mutation<SuccessResponce, { languageID: number }>({
      query(body) {
        const { languageID } = body;
        return {
          url: `/admin/language/${languageID}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: ['Language']
    })
  })
});

export const {
  useListLanguagesQuery,
  useCreateLanguageMutation,
  useUpdateLanguageMutation,
  useDeleteLanguageMutation
} = languageApi;
