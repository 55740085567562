import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import AutoAidLogo from '../../assets/icons/autoaid-logo.png';
import QuickJob from 'pages/jobs/QuickJob';
import ability, { SUBJECTS } from 'common/casl/ability';
import { useNavigate } from 'react-router-dom';
import { useAdminLogoutMutation } from 'app/services/auth';
import { store } from 'app/store';
import { logout } from 'pages/auth/authSlice';
import { Divider, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material';
import UniversalSearchBar from './UniversalSearchBar';
import WorkStatus from './WorkStatus';
import { useGetAdminProfileQuery } from 'app/services/profileInfo';
import { setProfileData } from './ProfileSlice';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1
}));

const Header = ({ open, handleDrawerOpen }: { open: boolean; handleDrawerOpen: any }) => {
  const [openQuickJob, setOpenQuickJob] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [adminLogout] = useAdminLogoutMutation();

  const lStorage = localStorage.getItem('user');
  const user: any = JSON.parse(lStorage || '{}');

  const history = useNavigate();
  const { refetch } = useGetAdminProfileQuery();
  const dispatch = useDispatch();

  // Responsive hook
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchProfileInfo = async () => {
      const profileInfo = await refetch();
      if (profileInfo.data) {
        dispatch(setProfileData(profileInfo.data));
      }
    };

    fetchProfileInfo();
  }, []);

  const handleOpen = () => setOpenQuickJob(true);
  const handleClose = () => setOpenQuickJob(false);

  const handleUserMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    adminLogout({});
    store.dispatch(logout());
  };

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar
        sx={{
          position: 'relative', // Enables absolute positioning for the center element
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: { xs: 'wrap', md: 'nowrap' },
          px: 2
        }}>
        {/* Left Section: Hamburger Menu (and logo on desktop) */}
        <Box display="flex" alignItems="center" sx={{ minWidth: { xs: 'auto', md: 200 } }}>
          {isMobile && (
            <IconButton onClick={handleDrawerOpen} sx={{ mr: 1 }}>
              <MenuIcon />
            </IconButton>
          )}
          {!isMobile && (
            <Box
              component="img"
              src={AutoAidLogo}
              alt="Auto Aid Logo"
              sx={{
                height: { xs: 30, md: 50 }
              }}
            />
          )}
        </Box>

        {/* Center Section: Universal Search Bar on Desktop, Logo on Mobile */}
        {isMobile ? (
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)'
            }}>
            <Box
              component="img"
              src={AutoAidLogo}
              alt="Auto Aid Logo"
              sx={{
                height: 30
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              width: { xs: '80%', md: '40%' }
            }}>
            <UniversalSearchBar />
          </Box>
        )}

        {/* Right Action Buttons */}
        <Box display="flex" alignItems="center" gap={{ xs: 1, md: 2 }}>
          {/* Quick Job Button (hidden on mobile) */}
          {ability.can('CREATE', SUBJECTS.JOBS) && !isMobile && (
            <Button variant="contained" onClick={handleOpen} sx={{ whiteSpace: 'nowrap' }}>
              Quick Job
            </Button>
          )}

          <IconButton sx={{ color: '#888' }} onClick={() => history('/settings/push-notification')}>
            <NotificationsIcon />
          </IconButton>

          {/* Wrapped User Icon to prevent movement on mobile */}
          <Box
            sx={{
              width: 40,
              height: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <IconButton
              disableRipple
              sx={{
                color: '#888',
                '&:active': { transform: 'none' },
                '&:focus': { outline: 'none' }
              }}
              onClick={handleUserMenuOpen}>
              <PersonIcon />
            </IconButton>
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleUserMenuClose}
            PaperProps={{
              elevation: 4,
              sx: {
                overflow: 'visible',
                mt: 1.5,
                boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
                borderRadius: '8px',
                minWidth: '250px'
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            {/* User Details */}
            <Box px={2} py={1}>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="body1" color="text.primary">
                  {user?.firstName} {user?.lastName}
                </Typography>
                <WorkStatus />
              </Box>
              <Typography variant="body2" color="text.secondary">
                {user?.email}
              </Typography>
            </Box>
            <Divider />
            {/* Logout Button */}
            <MenuItem onClick={handleLogout} sx={{ display: 'flex', justifyContent: 'center' }}>
              Logout
            </MenuItem>
          </Menu>

          {/* Render QuickJob Modal if open */}
          {openQuickJob && (
            <QuickJob title="Add Quick Job" open={openQuickJob} handleClose={handleClose} />
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
