import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TotalJobsIcon from '../../assets/icons/dashboard-total-jobs-card-icon.svg';
import OngoingJobIcon from '../../assets/icons/dashboard-ongoing-jobs-card-icon.svg';
import PendingJobIcon from '../../assets/icons/dashboard-pending-jobs-card-icon.svg';
import CancelledJobIcon from '../../assets/icons/dashboard-cancelled-jobs-card-icon.svg';
import CompletedJobIcon from '../../assets/icons/dashboard-completed-jobs-card-icon.svg';
import TotalJobsIconActive from '../../assets/icons/dashboard-total-jobs-card-icon-active.svg';
import OngoingJobIconActive from '../../assets/icons/dashboard-ongoing-jobs-card-icon-active.svg';
import PendingJobIconActive from '../../assets/icons/dashboard-pending-jobs-card-icon-active.svg';
import CancelledJobIconActive from '../../assets/icons/dashboard-cancelled-jobs-card-icon-active.svg';
import CompletedJobIconActive from '../../assets/icons/dashboard-completed-jobs-card-icon-active.svg';
import ConversionIcon from '../../assets/icons/job-card-conversion-icon.svg';
import ConversionIconActive from '../../assets/icons/job-card-conversion-icon-active.svg';
import GmvIcon from '../../assets/icons/job-card-GMV-icon.svg';
import useThemedFilter, { ThemedFiltersModal } from 'hooks/useThemedFilter';

import { useListServiceCategoriesQuery } from 'app/services/service-categories';
import { Job_Status, jobProviders } from 'utils/constants';
import { useListStaffQuery } from 'app/services/staff';
import { useListStatesQuery } from 'app/services/location';
import { useEffect, useState } from 'react';
import { useSocket } from 'utils/context/SocketContext';
import { motion } from 'framer-motion';
import { pageTransition, pageVariants } from 'utils/motionConfig';
import JobCardsHeader from './components/JobCardsHeader';
import ThemeJobDataTable from 'components/ThemeJobDataTable';
import ThemeStatCard from 'components/ThemeStatCard';
import { useLazyGetJobCardsQuery } from 'app/services/job';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useListClientsQuery } from 'app/services/client';

const JobCard = () => {
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [activeCard, setActiveCard] = useState<string>('');

  const { registerJobEventCallback } = useSocket();
  const [searchParams, setSearchParams] = useSearchParams();

  const [getJobCardsData, { data, isLoading, isFetching }] = useLazyGetJobCardsQuery();

  const { data: stateData, isLoading: stateLoading } = useListStatesQuery({
    limit: 1000,
    offset: 0
  });

  const { data: clientData, isFetching: isClientDataFetching } = useListClientsQuery({});

  const loadClientList = () => {
    if (clientData?.data) {
      return clientData.data.map((client: any) => ({
        label: client.clientName,
        value: client.clientCode
      }));
    } else {
      return [];
    }
  };

  const filterTemplate = [
    {
      type: 'date-range',
      label: 'Date & Time',
      fieldKey: 'createdDate',
      whereOption: 'between'
    },
    {
      type: 'service-category-select',
      label: 'Service Type',
      fieldKey: 'serviceCategoryID',
      isMulti: true,
      whereOption: 'in'
    },
    {
      type: 'multi-select',
      label: 'Status',
      fieldKey: 'jobStatusID',
      whereOption: 'in',
      options: [
        { label: 'Pending', value: Job_Status.PENDING },
        { label: 'Accepted', value: Job_Status.ACCEPTED },
        { label: 'Rejected', value: Job_Status.REJECTED },
        { label: 'Cancelled Customer', value: Job_Status.CANCELLED_CUSTOMER },
        { label: 'Cancelled Technician', value: Job_Status.CANCELLED_TECHNICIAN },
        { label: 'Cancelled Admin', value: Job_Status.CANCELLED_ADMIN },
        { label: 'In Progress', value: Job_Status.IN_PROGRESS },
        { label: 'Completed', value: Job_Status.COMPLETED },
        { label: 'Failed', value: Job_Status.FAILED },
        { label: 'Invoiced', value: Job_Status.INVOICED },
        { label: 'Payment Failed', value: Job_Status.PAYMENT_FAILED },
        { label: 'Paid', value: Job_Status.PAID }
      ]
    },
    {
      type: 'multi-select',
      label: 'Client',
      fieldKey: 'jobSourceProvider',
      options: loadClientList(),
      whereOption: 'in'
    },
    {
      type: 'agent-select',
      label: 'Agents',
      fieldKey: 'agentID',
      whereOption: 'in',
      isMulti: true
    },
    {
      type: 'multi-select',
      label: 'States',
      fieldKey: 'stateID',
      options: stateData?.items.map((el: any) => ({
        label: el.stateName,
        value: el.stateID
      })),
      whereOption: 'in'
    }
  ];

  const {
    openFilterModal,
    closeFilterModal,
    RenderChipsView,
    filtersObj,
    modalOpen,
    setFiltersObj,
    handleFilterClear,
    applyFilters,
    clearFilters,
    filtersQuery,
    appliedFilters
  } = useThemedFilter({
    clearParentFilters: () => setActiveCard(''),
    filterTemplate,
    searchParams,
    setSearchParams
  });

  useEffect(() => {
    interface FiltersType {
      [key: string]: any; // Adjust this type based on your actual filter object structure
    }
    const limit = Number(searchParams.get('limit'));
    if (limit) {
      setLimit(limit);
    }
    const offset = Number(searchParams.get('offset'));
    if (offset) {
      setOffset(offset);
    }
    const rawFilters = searchParams.get('appliedFilters');
    if (rawFilters) {
      const obj: FiltersType = rawFilters ? JSON.parse(rawFilters) : {};
      if (obj.createdDate && Array.isArray(obj.createdDate) && obj.createdDate.length > 0) {
        for (let i = 0; i < obj.createdDate.length; i++) {
          obj.createdDate[i] = moment(obj.createdDate[i] as string).local();
        }
      }
      if (obj) {
        setFiltersObj({ ...obj });
        applyFilters({ ...obj });
      }
    }
  }, []);

  useEffect(() => {
    const makeJobCardActive = () => {
      const activeCard = searchParams.get('active-card');
      if (activeCard) {
        switch (activeCard) {
          case 'ongoing':
            setActiveCard('ongoing');
            break;
          case 'pending':
            setActiveCard('pending');
            break;
          case 'cancelled':
            setActiveCard('cancelled');
            break;
          case 'completed':
            setActiveCard('completed');
            break;
          default:
            setActiveCard('none');
            break;
        }
      }
    };
    makeJobCardActive();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      getJobCardsData({
        pagination: { limit, offset: offset * limit },
        filters: filtersQuery
      });
    }
  }, [limit, offset, filtersQuery]);

  useEffect(() => {
    registerJobEventCallback((data) => {
      if (data.eventType === 'JOB_UPDATE') {
        getJobCardsData(
          {
            pagination: { limit, offset: offset * limit },
            filters: filtersQuery
          },
          false
        );
      }
    });

    return () => {
      registerJobEventCallback(() => {}); // Cleanup callback on unmount
    };
  }, [registerJobEventCallback]);

  const activateCard = (e: 'total' | 'ongoing' | 'pending' | 'cancelled' | 'completed') => {
    let obj = {};
    if (e === activeCard) return;
    switch (e) {
      case 'total':
        setActiveCard('total');
        break;
      case 'ongoing':
        setActiveCard('ongoing');
        obj = {
          jobStatusID: [
            { label: 'Ongoing', value: Job_Status.IN_PROGRESS },
            { label: 'Ongoing', value: Job_Status.ACCEPTED }
          ]
        };
        applyFilters({ ...obj });
        searchParams.set('active-card', 'ongoing');
        searchParams.set('appliedFilters', JSON.stringify(obj));
        setSearchParams(searchParams);
        break;
      case 'pending':
        setActiveCard('pending');
        obj = {
          jobStatusID: [{ label: 'Pending', value: Job_Status.PENDING }]
        };
        applyFilters({ ...obj });
        searchParams.set('active-card', 'pending');
        searchParams.set('appliedFilters', JSON.stringify(obj));
        setSearchParams(searchParams);
        break;
      case 'cancelled':
        setActiveCard('cancelled');
        obj = {
          jobStatusID: [
            { label: 'Cancelled by Customer', value: Job_Status.CANCELLED_CUSTOMER },
            { label: 'Cancelled by Technician', value: Job_Status.CANCELLED_TECHNICIAN },
            { label: 'Cancelled by Admin', value: Job_Status.CANCELLED_ADMIN }
          ]
        };
        applyFilters({ ...obj });
        searchParams.set('active-card', 'cancelled');
        searchParams.set('appliedFilters', JSON.stringify(obj));
        setSearchParams(searchParams);
        break;
      case 'completed':
        setActiveCard('completed');
        obj = {
          jobStatusID: [
            { label: 'Completed', value: Job_Status.COMPLETED },
            { label: 'Payment Failed', value: Job_Status.PAYMENT_FAILED },
            { label: 'Invoiced', value: Job_Status.INVOICED },
            { label: 'Paid', value: Job_Status.PAID }
          ]
        };
        applyFilters({ ...obj });
        searchParams.set('active-card', 'completed');
        searchParams.set('appliedFilters', JSON.stringify(obj));
        setSearchParams(searchParams);
        break;
      default:
        setActiveCard('');
        break;
    }
  };

  const handleTableChange = (e: any) => {
    setLimit(e.pageSize);
    setOffset(e.page);
    searchParams.set('limit', e.pageSize);
    searchParams.set('offset', e.page);
    setSearchParams(searchParams);
  };

  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={pageTransition}
      key={'job-cards-screen-animation'}>
      <Box sx={{ p: 3, minHeight: '88vh' }}>
        <JobCardsHeader openFilterModal={openFilterModal} />

        <Grid container spacing={3} justifyContent="flex-start">
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={appliedFilters.agentID && appliedFilters.agentID.length > 0 ? 1.7 : 2.4}>
            <ThemeStatCard
              title="Total Jobcard"
              count={data?.totalJobs ?? 0}
              icon={TotalJobsIcon}
              activeIcon={TotalJobsIconActive}
              isLoading={isLoading}
              isActive={activeCard === 'total'}
              align="vertical"
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={appliedFilters.agentID && appliedFilters.agentID.length > 0 ? 1.7 : 2.4}>
            <ThemeStatCard
              title="Ongoing Jobcard"
              count={data?.totalOngoingJobs ?? 0}
              icon={OngoingJobIcon}
              activeIcon={OngoingJobIconActive}
              isLoading={isLoading}
              isActive={activeCard === 'ongoing'}
              onClick={() => activateCard('ongoing')}
              align="vertical"
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={appliedFilters.agentID && appliedFilters.agentID.length > 0 ? 1.7 : 2.4}>
            <ThemeStatCard
              title="Pending Jobcard"
              count={data?.totalPendingJobs ?? 0}
              icon={PendingJobIcon}
              activeIcon={PendingJobIconActive}
              isLoading={isLoading}
              isActive={activeCard === 'pending'}
              onClick={() => activateCard('pending')}
              align="vertical"
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={appliedFilters.agentID && appliedFilters.agentID.length > 0 ? 1.7 : 2.4}>
            <ThemeStatCard
              title="Cancelled Jobcard"
              count={data?.totalCancelledJobs ?? 0}
              icon={CancelledJobIcon}
              activeIcon={CancelledJobIconActive}
              isLoading={isLoading}
              isActive={activeCard === 'cancelled'}
              onClick={() => activateCard('cancelled')}
              align="vertical"
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={appliedFilters.agentID && appliedFilters.agentID.length > 0 ? 1.7 : 2.4}>
            <ThemeStatCard
              title="Completed Jobcard"
              count={data?.totalCompletedJobs ?? 0}
              icon={CompletedJobIcon}
              activeIcon={CompletedJobIconActive}
              isLoading={isLoading}
              isActive={activeCard === 'completed'}
              onClick={() => activateCard('completed')}
              align="vertical"
            />
          </Grid>

          {appliedFilters.agentID && appliedFilters.agentID.length > 0 && (
            <>
              <Grid item xs={12} sm={6} md={3} lg={1.7}>
                <ThemeStatCard
                  title="Conversion $"
                  count={data?.conversionPercentage ?? 0}
                  icon={ConversionIcon}
                  activeIcon={ConversionIconActive}
                  isLoading={isLoading}
                  isActive={activeCard === 'conversion'}
                  // onClick={() => activateCard('completed')}
                  align="vertical"
                  percentage={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={1.7}>
                <ThemeStatCard
                  title="GMV"
                  count={data?.gmv ?? 0}
                  icon={GmvIcon}
                  activeIcon={null}
                  isLoading={isLoading}
                  isActive={activeCard === 'gmv'}
                  // onClick={() => activateCard('completed')}
                  align="vertical"
                />
              </Grid>
            </>
          )}
        </Grid>

        <RenderChipsView />

        <ThemedFiltersModal
          filtersObj={filtersObj}
          setFiltersObj={setFiltersObj}
          modalOpen={modalOpen}
          closeFilterModal={closeFilterModal}
          filterTemplate={filterTemplate}
          handleFilterClear={handleFilterClear}
          applyFilters={applyFilters}
          clearFilters={clearFilters}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />

        <ThemeJobDataTable
          data={data?.items}
          onTableChange={handleTableChange}
          page={offset}
          pageSize={limit}
          total={data?.total || 0}
          isLoading={isLoading || isFetching}
          filters={filtersQuery}
        />
      </Box>
    </motion.div>
  );
};

export default JobCard;
