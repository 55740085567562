import { DEFAULT_LIMIT } from 'utils/constants';
import { api } from './api';

export interface CreateVehicleDTO {
  vehicleName: string;
  vehicleBrandID: number;
}

export interface UpdateVehicleDTO {
  // "vehicleName": "1 Series",
  // "vehicleEnabled": "N"
  vehicleID: string;
  vehicleName: string;
  vehicleBrandID: number;
}

export const vehicleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listVehicles: builder.query({
      query: (params) => {
        const { limit = DEFAULT_LIMIT, offset = 0, name, brandID } = params;
        let queryUrl = `/vehicle?limit=${limit}&offset=${offset}`;

        if (name && typeof name === 'string') {
          queryUrl += `&name=${name}`;
        }

        if (typeof brandID === 'number') {
          queryUrl += `&brandID=${brandID}`;
        }

        return queryUrl;
      },
      transformResponse: (res: any) => {
        let items = res?.items?.map((item: any) => {
          return { ...item, id: item['vehicleID'] };
        });
        return { ...res, items };
      },
      providesTags: (result = {}) => {
        const items =
          result?.items?.map(({ id }: { id: string }) => ({ type: 'Vehicle', id } as const)) ?? [];
        return [...items, { type: 'Vehicle' as const, id: 'LIST' }];
      }
    }),
    createVehicle: builder.mutation<CreateVehicleDTO, Partial<CreateVehicleDTO>>({
      query(body) {
        return {
          url: `/vehicle`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['Vehicle']
    }),
    updateVehicle: builder.mutation<UpdateVehicleDTO, Partial<UpdateVehicleDTO>>({
      query(body) {
        return {
          url: `/vehicle/${body.vehicleID}`,
          method: 'PUT',
          body
        };
      },
      // invalidatesTags: (garage) => {
      //   // garage = { success: true, message: 'Updated successfully' };
      //   return [{ type: 'Garage', id: garage?.vehicleID }];
      // },
      invalidatesTags: ['Vehicle']
    })
  })
});

export const {
  useListVehiclesQuery,
  useLazyListVehiclesQuery,
  useCreateVehicleMutation,
  useUpdateVehicleMutation
} = vehicleApi;
