import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  InputBase,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Divider,
  CircularProgress,
  Typography
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import EngineeringIcon from '@mui/icons-material/Engineering';
import WorkIcon from '@mui/icons-material/Work';
import { useNavigate } from 'react-router-dom';
import { useLazyUniversalSearchQuery } from 'app/services/dashboard';
import { colors } from 'utils/themeConstants';

const UniversalSearchBar = () => {
  const [query, setQuery] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [page, setPage] = useState(1);
  const [results, setResults] = useState([]);
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  // Lazy query hook from RTK
  const [trigger, { data, isLoading, isFetching }] = useLazyUniversalSearchQuery();

  const handleSearch = (value: string) => {
    setQuery(value);
    setPage(1);

    if (value) {
      setShowDropdown(true);
      trigger({ q: value, page: 1 });
    } else {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (data) {
      //@ts-ignore
      setResults(data?.items || []);
    }
  }, [data, page]);

  // Debounce function
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (query) {
        trigger({ q: query, page: 1 });
      }
    }, 1000); // Adjust debounce time as needed

    return () => clearTimeout(timeout);
  }, [query, trigger]);

  const handleResultClick = (result: any) => {
    switch (result.type) {
      case 'job':
        navigate(`/jobs/${result.entityID}`);
        break;
      case 'technician':
        navigate(`/technicians/${result.entityID}`);
        break;
      case 'customer':
        navigate(`/customers/${result.entityID}`);
        break;
      default:
        break;
    }
    setQuery('');
    setShowDropdown(false); // Close dropdown on click
  };

  const handleEnter = () => {
    navigate(`/search?query=${query}`);
    setShowDropdown(false);
  };

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleShowMore = () => {
    setPage((prevPage) => prevPage + 1);
    trigger({ q: query, page: page + 1 }, false);
  };

  const totalResults = data?.total || 0;

  return (
    <Box position="relative" sx={{ p: 1 }} ref={dropdownRef}>
      {/* Search Input */}
      <Box
        display="flex"
        alignItems="center"
        sx={{
          backgroundColor: '#FFF',
          borderRadius: '25px',
          p: '4px 8px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          width: '500px'
        }}>
        <IconButton>
          <SearchIcon />
        </IconButton>
        <InputBase
          placeholder="Universal Search Bar"
          sx={{ flex: 1, fontSize: '14px', ml: 1 }}
          value={query}
          onChange={(e) => handleSearch(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleEnter()}
        />
      </Box>

      {/* Loading State */}
      {(isLoading) && showDropdown && (
        <Box
          sx={{
            position: 'absolute',
            background: '#fff',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            mt: 1,
            zIndex: 10,
            width: '100%',
            textAlign: 'center',
            p: 2
          }}>
          <CircularProgress size={24} />
        </Box>
      )}

      {/* No Results */}
      {!isLoading && showDropdown && query && results.length === 0 && (
        <Box
          sx={{
            position: 'absolute',
            background: '#fff',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            mt: 1,
            zIndex: 10,
            width: '100%',
            textAlign: 'center',
            p: 2
          }}>
          <Typography variant="body2" color="textSecondary">
            No results found
          </Typography>
        </Box>
      )}

      {/* Search Results */}
      {!isLoading && showDropdown && results.length > 0 && (
        <Box
          sx={{
            position: 'absolute',
            background: '#fff',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            mt: 1,
            zIndex: 10,
            width: '100%',
            maxHeight: '60vh',
            overflowY: 'scroll'
          }}>
          <List
            sx={{
              paddingY: '0px !important'
            }}>
            {results.map((result: any, index: any) => (
              <ListItem
                button
                key={index}
                onClick={() => handleResultClick(result)}
                sx={{
                  p: '4px 10px', // Reduced padding
                  fontSize: '12px', // Smaller font size
                  '&:hover': {
                    background: colors.gradientPrimary,
                    color: '#fff' // White text on hover
                  },
                  '&:hover .MuiListItemIcon-root, &:hover .MuiListItemText-root, &:hover .MuiTypography-root': {
                    color: '#fff' // White text on hover
                  }
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    width: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  {result.type === 'job' && <WorkIcon />}
                  {result.type === 'technician' && <EngineeringIcon />}
                  {result.type === 'customer' && <PersonIcon />}
                </ListItemIcon>
                <ListItemText
                  primary={result.title}
                  secondary={result.description}
                  primaryTypographyProps={{ fontSize: '14px', fontWeight: '600' }} // Smaller font size for title
                  secondaryTypographyProps={{ fontSize: '12px' }} // Smaller font size for description
                />
              </ListItem>
            ))}
            {totalResults > results.length && <Divider />}
            {totalResults > results.length && (
              <Box textAlign="center" p={1}>
                <Button size="small" onClick={handleShowMore}>
                  Show More
                </Button>
              </Box>
            )}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default UniversalSearchBar;
