import { CircularProgress, Grid, IconButton } from '@mui/material';
import AgentRosterHeader from './components/AgentRosterHeader';
import ThemeStatCard from 'components/ThemeStatCard';
import OngoingJobIcon from '../../assets/icons/dashboard-ongoing-jobs-card-icon.svg';
import OngoingJobIconActive from '../../assets/icons/dashboard-ongoing-jobs-card-icon-active.svg';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {
  useAdminGetAgentRosterQuery,
  useAdminGetRosterPlanReportUrlMutation,
  useGetAgentRosterMasterDataQuery
} from 'app/services/shift';
import moment from 'moment';
import useThemedFilter, { ThemedFiltersModal } from 'hooks/useThemedFilter';
import { useEffect, useState } from 'react';
import useLanguageList from 'hooks/useLanguageList';
import QueryString from 'qs';
import { DownloadOutlined } from '@mui/icons-material';

const filterTemplateTemplate: any = [
  {
    type: 'date-range',
    label: 'Date & Time',
    fieldKey: 'createdDate',
    whereOption: 'between'
  }
];

export default function AgentRoster() {
  const [activeCard, setActiveCard] = useState<string>('');
  const [filterTemplate, setFilterTemplate] = useState<any>(filterTemplateTemplate);
  const [activityFilter, setActivityFilter] = useState<'ACTIVE' | 'INACTIVE' | null>();
  const [getDownloadUrl, { isLoading: reportLoading }] = useAdminGetRosterPlanReportUrlMutation({});

  const {
    openFilterModal,
    closeFilterModal,
    filtersObj,
    modalOpen,
    setFiltersObj,
    handleFilterClear,
    applyFilters,
    clearFilters,
    filtersQuery
  } = useThemedFilter({
    clearParentFilters: () => {
      setActivityFilter(null);
      setActiveCard('');
    },
    filterTemplate
  });

  const nav = useNavigate();

  const handleViewPlan = () => {
    nav('/agent-roster/plan');
  };

  const { data, isLoading, refetch } = useAdminGetAgentRosterQuery({
    queryString: activityFilter ? QueryString.stringify({ activity: activityFilter }) : filtersQuery
  });

  const { data: masterData } = useGetAgentRosterMasterDataQuery();
  const { languageList } = useLanguageList();

  useEffect(() => {
    refetch();
  }, [filtersQuery, activityFilter, refetch]);

  useEffect(() => {
    const handleMasterDataChange = () => {
      const tempFiltersTemplate = [...filterTemplateTemplate];

      if (masterData?.duties) {
        tempFiltersTemplate.push({
          type: 'multi-select',
          label: 'Process',
          fieldKey: 'duty',
          options: masterData?.duties.map((dutyOption: any) => ({
            label: dutyOption.dutyDescription,
            value: dutyOption.dutyID
          })),
          whereOption: 'in'
        });
      }

      if (masterData?.shiftDefinitions) {
        tempFiltersTemplate.push({
          type: 'multi-select',
          label: 'Shift',
          fieldKey: 'shift',
          options: masterData?.shiftDefinitions.map((shiftOption: any) => ({
            label: shiftOption.shiftName,
            value: shiftOption.shiftID
          })),
          whereOption: 'in'
        });
      }

      if (masterData?.workConditions) {
        tempFiltersTemplate.push({
          type: 'multi-select',
          label: 'Status',
          fieldKey: 'workConditionCode',
          options: masterData?.workConditions.map((workConditionOption: any) => ({
            label: workConditionOption.description,
            value: workConditionOption.conditionCode
          })),
          whereOption: 'in'
        });
      }

      if (languageList) {
        tempFiltersTemplate.push({
          type: 'multi-select',
          label: 'Language',
          fieldKey: 'languageID',
          options: languageList?.map((languageOption: any) => ({
            label: languageOption.languageName,
            value: languageOption.languageID
          })),
          whereOption: 'in'
        });
      }

      setFilterTemplate(tempFiltersTemplate);
    };
    handleMasterDataChange();
  }, [masterData, languageList]);

  const handleActiveFilterChange = (e: any) => {
    applyFilters({});
    setActivityFilter(e as 'ACTIVE' | 'INACTIVE' | null);
    if (e === 'ACTIVE') {
      setActiveCard('ACTIVE');
    }

    if (e === 'INACTIVE') {
      setActiveCard('INACTIVE');
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <AgentRosterHeader
          handleViewPlan={handleViewPlan}
          handleOpenFilterModal={openFilterModal}
        />
      </Grid>

      <Grid item xs={12} sx={{ mb: 4 }}>
        <Grid container spacing={1}>
          <Grid
            container
            spacing={2}
            alignContent="center"
            alignItems="center"
            justifyContent="center">
            <Grid item xs={12} sm={4}>
              <ThemeStatCard
                title="Active agents"
                count={data?.activeAgents || 0}
                icon={OngoingJobIcon}
                activeIcon={OngoingJobIconActive}
                isLoading={isLoading}
                isActive={activeCard === 'ACTIVE'}
                onClick={() => handleActiveFilterChange('ACTIVE')}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ThemeStatCard
                title="Inactive agents"
                count={data?.inActiveAgents || 0}
                icon={OngoingJobIcon}
                activeIcon={OngoingJobIconActive}
                isLoading={isLoading}
                isActive={activeCard === 'INACTIVE'}
                onClick={() => handleActiveFilterChange('INACTIVE')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <IconButton
              disabled={reportLoading}
              onClick={() => {
                const availablePlans = masterData?.availablePlans;
                if (availablePlans && availablePlans?.length > 0) {
                  const now = new Date();
                  const currentYear = now.getFullYear();
                  const currentMonth = now.getMonth() + 1; // Months are zero-based

                  const plans = availablePlans?.find(
                    (plan: any) => plan.planYear === currentYear && plan.planMonth === currentMonth
                  );

                  getDownloadUrl({
                    planID: plans?.planID,
                    reportType: 'AGENT_ROSTER_REPORT'
                  }).then((res: any) => {
                    if (res?.data?.data) {
                      window.open(res?.data?.data, '_blank');
                    }
                  });
                }
              }}>
              {reportLoading ? <CircularProgress size={20} /> : <DownloadOutlined />}
            </IconButton>
          </Grid>

          <Grid item xs={12}>
            <DataGrid
              rows={data?.items || []}
              loading={isLoading}
              columns={[
                {
                  field: 'agentName',
                  headerName: 'Name',
                  flex: 1
                },
                {
                  field: 'date',
                  headerName: 'Date',
                  flex: 1
                },
                {
                  field: 'duty',
                  headerName: 'Process',
                  flex: 1
                },
                {
                  field: 'shift',
                  headerName: 'Shift',
                  flex: 1
                },
                {
                  field: 'loginTime',
                  headerName: 'Log in Time',
                  flex: 1,
                  valueFormatter: ({ value }) => {
                    return value ? moment(value).format('hh:mm A') : '';
                  }
                },
                {
                  field: 'logoutTime',
                  headerName: 'Log out Time',
                  flex: 1,
                  valueFormatter: ({ value }) => {
                    return value ? moment(value).format('hh:mm A') : '';
                  }
                },
                {
                  field: 'status',
                  headerName: 'Status',
                  flex: 1
                },
                {
                  field: 'overtimeHours',
                  headerName: 'OT (Hrs)',
                  flex: 1
                }
              ]}
              sx={{
                minHeight: 400,
                border: 'none',
                '& .MuiDataGrid-cell': {
                  borderBottom: 'none'
                },
                '& .MuiDataGrid-row:nth-of-type(odd)': {
                  backgroundColor: '#f9f9f9' // Light gray for odd rows
                },
                '& .MuiDataGrid-row:nth-of-type(even)': {
                  backgroundColor: '#ffffff' // White for even rows
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#E0E9ED', // Light gray header background
                  color: '#333333',
                  fontWeight: '600 !important',
                  textAlign: 'center',
                  borderBottom: '2px solid #e0e0e0',
                  height: '40px !important',
                  minHeight: '40px !important'
                },
                '& .MuiDataGrid-columnHeader': {
                  fontWeight: 'bold',
                  padding: '0 8px'
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  textAlign: 'center',
                  color: '#333333',
                  fontWeight: '600 !important'
                },
                '& .MuiDataGrid-cellContent': {
                  textAlign: 'center'
                },
                '& .MuiDataGrid-row': {
                  height: 60 // Row height
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <ThemedFiltersModal
        filtersObj={filtersObj}
        setFiltersObj={setFiltersObj}
        modalOpen={modalOpen}
        closeFilterModal={closeFilterModal}
        filterTemplate={filterTemplate}
        handleFilterClear={handleFilterClear}
        applyFilters={applyFilters}
        clearFilters={clearFilters}
      />
    </Grid>
  );
}
