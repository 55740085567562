import { useEffect, useLayoutEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Marker, useMapEvents } from 'react-leaflet';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Grid from '@mui/material/Unstable_Grid2';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Typography
} from '@mui/material';
import { Search } from '@mui/icons-material';

import InputField from 'components/forms/InputField';
import ModalDialog from 'components/modalDialog/modalDialog';
import { DEFAULT_LIMIT, jobProviders, jobProviders1, jobSources } from 'utils/constants';
import LeafletMap, { RecenterAutomatically } from 'components/LeafletMap';
import PlacesAutocomplete from 'components/PlacesAutocomplete';
import { useListVehiclesQuery } from 'app/services/vehicle';
import { QuickJobInput, useQuickJobMutation } from 'app/services/job';
import { useLazyGetByPlateNumberQuery } from 'app/services/customer';
import { openToastMessage } from 'app/slices/toastMessageSlice';

import SelectServiceCategory from 'components/forms/ServiceCategorySelect';
import AgentSelect from 'components/forms/AgentSelect';
import ClientSelect from 'components/forms/ClientSelect';

interface QuickJobProps {
  open: boolean;
  handleClose: (success?: any) => void;
  title: string;
}

const ClickableMarker = ({
  position,
  setMarkerPosition,
  setResetPosition
}: {
  position: any;
  setMarkerPosition: any;
  setResetPosition: any;
}) => {
  const handleMarkerDragEnd = (e: any) => {
    const { lat, lng } = e.target.getLatLng();
    setMarkerPosition([lat, lng]);
    setResetPosition(true);
  };

  return (
    <Marker
      position={position}
      draggable={true}
      eventHandlers={{
        dragend: handleMarkerDragEnd
      }}
    />
  );
};

const MapClickHandler = ({ handleMapClick }: { handleMapClick: any }) => {
  useMapEvents({
    click: handleMapClick,
    drag: handleMapClick
  });
  return null;
};

const schema = Yup.object().shape({
  customerName: Yup.string().required('Customer Name is required'),
  customerMobile: Yup.string()
    .required('Customer Phone is required')
    .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits'),
  regNumber: Yup.string()
    .required('Registration Number is required')
    .max(10, 'Please enter a valid registration number'),
  complaint: Yup.string().required('Complaint is required'),
  assignedAgent: Yup.string(),
  location: Yup.string().optional(),
  serviceCategory: Yup.number().required('Category is required').typeError('Category is required'),
  vehicle: Yup.number().required('Vehicle is required').typeError('Vehicle is required'),
  lat: Yup.number().required('Lat is required').typeError('Lat is required'),
  long: Yup.number().required('Long is required').typeError('Long is required'),
  jobSource: Yup.string().max(255).optional(),
  jobProvider: Yup.string().max(255).optional(),
  jobSourceReference: Yup.string().max(255).optional(),
  jobSourceDetails: Yup.string().max(1000).optional()
});

const QuickJob: React.FC<QuickJobProps> = ({ open, handleClose, title }) => {
  const [foundVehicleName, setFoundVehicleName] = useState<string>('');
  const [hideJobProvider, setHideJobProvider] = useState<boolean>(false);
  const [hideJobSourceReference, setHideJobSourceReference] = useState<boolean>(false);
  const [markerPosition, setMarkerPosition] = useState<[number, number]>([
    10.100129386088865, 76.35642101929527
  ]);
  const [resetPosition, setResetPosition] = useState(false);
  const [stateTotal, setStateTotal] = useState(DEFAULT_LIMIT);
  const [autoAssignAgent, setAutoAssignAgent] = useState(false);

  const dispatch = useDispatch();

  const [createQuickJob, { data, isLoading, isSuccess }] = useQuickJobMutation();

  const { data: vehicleData, refetch: resetVehicles } = useListVehiclesQuery({
    limit: stateTotal,
    offset: 0,
    name: foundVehicleName
  });

  const [getByPlateNumber, { data: plateNumberData, isLoading: isPlateNumberDetailsLoading }] =
    useLazyGetByPlateNumberQuery();

  useEffect(() => {
    if (vehicleData) {
      setStateTotal(vehicleData?.total > DEFAULT_LIMIT ? vehicleData?.total : DEFAULT_LIMIT);
    }
  }, [vehicleData]);

  useEffect(() => {
    if (data && data?.success) {
      dispatch(
        openToastMessage({
          type: 'success',
          message: data?.message
        })
      );
      handleClose(true);
    }
  }, [data, dispatch, handleClose, isSuccess]);

  const methods = useForm<QuickJobInput>({
    //@ts-ignore
    resolver: yupResolver(schema),
    mode: 'onTouched'
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset
  } = methods;

  const onSave = (values: any) => {
    if (isLoading) {
      return;
    }
    values = {
      ...values,
      location: values.address
    };
    delete values.address;
    delete values.area;

    if (autoAssignAgent) {
      values.isAutoAssignAgent = true;
      values.assignedAgent = '';
    } else {
      values.isAutoAssignAgent = false;
    }

    createQuickJob(values);
  };

  useEffect(() => {
    const lat = markerPosition[0];
    const lng = markerPosition[1];
    setValue('lat', lat, { shouldDirty: true, shouldValidate: true });
    setValue('long', lng, { shouldDirty: true, shouldValidate: true });
  }, [markerPosition, setValue]);

  const handleMapClick = (e: any) => {
    setResetPosition(true);
    if (e.type === 'drag') return;
    const lat = e?.latlng?.lat ?? 0;
    const lng = e?.latlng?.lng ?? 0;
    setMarkerPosition([lat, lng]);
  };

  useLayoutEffect(() => {
    setValue('jobProvider', '');
    
    if (watch('jobSource') === 'ENQUIRY_CALLS') {
      setHideJobProvider(true);
      setValue('jobProvider', '', { shouldDirty: true, shouldValidate: true });
      trigger('jobProvider');
    } else {
      setHideJobProvider(false);
    }
  }, [watch('jobSource')]);

  useLayoutEffect(() => {
    if (watch('jobSource') === 'ENQUIRY_CALLS' || watch('jobSource') === 'WORK_REQUEST') {
      setHideJobSourceReference(true);
      setValue('jobSourceReference', '', { shouldDirty: true, shouldValidate: true });
      trigger('jobSourceReference');
    } else {
      setHideJobSourceReference(false);
    }
  }, [watch('jobSource')]);

  const handleSearchPlateNumber = async () => {
    await getByPlateNumber({ plateNumber: watch('regNumber') }, false);
    setFoundVehicleName('');
  };

  useEffect(() => {
    if (plateNumberData?.vehicleBrandID) {
      if (plateNumberData?.customerMobile) {
        setValue('customerMobile', plateNumberData?.customerMobile, {
          shouldDirty: true,
          shouldValidate: true
        });
      }

      if (plateNumberData?.customerName) {
        setValue('customerName', plateNumberData?.customerName, {
          shouldDirty: true,
          shouldValidate: true
        });
      }

      if (plateNumberData?.vehicleID) {
        setValue('vehicle', plateNumberData?.vehicleID, {
          shouldDirty: true,
          shouldValidate: true
        });
        setFoundVehicleName(plateNumberData?.vehicleName);
        resetVehicles();
      }
    }
  }, [plateNumberData, plateNumberData?.vehicleBrandID]);

  return (
    <ModalDialog
      open={open}
      cancelName={'Cancel'}
      submitName={'Save'}
      title={title}
      onSubmit={handleSubmit(onSave)}
      onClose={handleClose}
      loading={isLoading}
      disabled={isLoading}
      width="lg"
      extraActions={[
        {
          label: 'Auto assign technician',
          onClick: () => handleSubmit((e: any) => onSave({ ...e, autoAssignTechnician: true }))(),
          variant: 'contained'
        }
      ]}>
      <form autoComplete="off">
        <Grid
          container
          xs={12}
          display="flex"
          alignContent="center"
          justifyItems="center"
          spacing={2}>
          <Grid xs={6} display="flex" flexGrow={1} alignContent="center" justifyItems="center">
            <InputField
              control={control}
              name="customerName"
              margin="normal"
              fullWidth
              id="customerName"
              label="Customer Name"
              type="text"
              helperText={errors.customerName?.message ?? ''}
              error={!!errors.customerName}
            />
          </Grid>

          <Grid xs={6} display="flex" flexGrow={1} alignContent="center" justifyItems="center">
            <InputField
              control={control}
              name="customerMobile"
              margin="normal"
              fullWidth
              id="customerMobile"
              label="Customer Mobile"
              type="text"
              helperText={errors.customerMobile?.message ?? ''}
              error={!!errors.customerMobile}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>

          <Grid xs={6} display="flex" flexGrow={1} alignContent="center" justifyItems="center">
            <Controller
              control={control}
              name="vehicle"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  value={value || null}
                  disablePortal
                  id="select-vehicle"
                  fullWidth
                  options={vehicleData?.items}
                  getOptionKey={(option: any) =>
                    option?.vehicleID + '-' + option?.vehicleName?.replace(/\s/g, '')
                  }
                  renderOption={(props, opt: any) => (
                    <Box
                      component="li"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        padding: 1
                      }}
                      {...props}>
                      <Typography>{opt?.vehicleName}</Typography>
                      <Typography sx={{ fontSize: 14, color: 'primary.main' }}>
                        - {opt?.Brand?.vehicleBrandName}
                      </Typography>
                    </Box>
                  )}
                  getOptionLabel={(option: any) => {
                    if (typeof option === 'number') {
                      const opts = vehicleData?.items.find(
                        (item: any) => item.vehicleID === option
                      );
                      return opts?.vehicleName;
                    }
                    return option.vehicleName;
                  }}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.vehicleID === value?.vehicleID || option?.vehicleID === value
                  }
                  onChange={(event, item: any) => {
                    onChange(item?.vehicleID);
                    setValue('vehicle', item?.vehicleID, {
                      shouldDirty: true,
                      shouldValidate: true
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Vehicle"
                      margin="normal"
                      helperText={errors.vehicle?.message ?? ''}
                      error={!!errors.vehicle}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid xs={6} display="flex" flexGrow={1} alignContent="center" justifyItems="center">
            <InputField
              control={control}
              name="regNumber"
              margin="normal"
              fullWidth
              id="regNumber"
              label="Registration Number"
              type="text"
              helperText={errors.regNumber?.message ?? ''}
              error={!!errors.regNumber}
              loading={isPlateNumberDetailsLoading}
              inputProps={{
                maxLength: 10,
                style: { textTransform: 'uppercase' }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={
                        !watch('regNumber') ||
                        watch('regNumber') === '' ||
                        isPlateNumberDetailsLoading
                      }
                      onClick={handleSearchPlateNumber}
                      edge="end">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid xs={6} display="flex" flexGrow={1} justifyContent="center" alignItems="center">
            <InputField
              control={control}
              select
              name="jobSource"
              margin="normal"
              fullWidth
              id="jobSource"
              label="Job source"
              type="text"
              helperText={errors.jobSource?.message ?? ''}
              error={!!errors.jobSource}
              style={{ textTransform: 'capitalize' }}>
              {jobSources.map((option: string) => (
                <MenuItem
                  key={`strong-source-${option}`}
                  value={option}
                  style={{ textTransform: 'capitalize' }}>
                  {option === 'RSA_PROVIDER'
                    ? 'RSA Provider'
                    : option.replace(/_/g, ' ').toLowerCase()}
                </MenuItem>
              ))}
            </InputField>
          </Grid>

          <Grid xs={6} display="flex" flexGrow={1} alignItems="center" justifyContent="center">
            <InputField
              control={control}
              name="jobSourceDetails"
              multiline
              margin="normal"
              fullWidth
              id="jobSourceDetails"
              label="Job Source Details"
              type="text"
              helperText={errors.jobSourceDetails?.message ?? ''}
              error={!!errors.jobSourceDetails}
            />
          </Grid>

          {!hideJobProvider && (
            <Grid xs={6} display="flex" flexGrow={1} justifyContent="center" alignItems="center">
              {watch('jobSource') === 'RSA_PROVIDER' ? (
                // Use the ClientSelect component for RSA_PROVIDER
                <ClientSelect
                  label="Job Provider"
                  onSelect={(selectedOption) => {
                    // Handle the selected option (e.g., update form state)
                    setValue('jobProvider', selectedOption.value); // Update the form field value
                  }}
                />
              ) : (
                // Use the existing InputField for other job sources
                <InputField
                  control={control}
                  select
                  name="jobProvider"
                  margin="normal"
                  fullWidth
                  id="jobProvider"
                  label="Job provider"
                  type="text"
                  helperText={errors.jobProvider?.message ?? ''}
                  error={!!errors.jobProvider}
                  style={{ textTransform: 'capitalize' }}>
                  {jobProviders1.map((option: string) => (
                    <MenuItem
                      key={`strong-source-${option}`}
                      value={option}
                      style={{ textTransform: 'capitalize' }}>
                      {option.replace(/_/gi, ' ').toLowerCase()}
                    </MenuItem>
                  ))}
                </InputField>
              )}
            </Grid>
          )}

          {!hideJobSourceReference && (
            <Grid xs={6} display="flex" flexGrow={1} alignItems="center" justifyContent="center">
              <InputField
                control={control}
                name="jobSourceReference"
                margin="normal"
                fullWidth
                id="jobSourceReference"
                label="Job Source Reference"
                type="text"
                helperText={errors.jobSourceReference?.message ?? ''}
                error={!!errors.jobSourceReference}
              />
            </Grid>
          )}

          {/* Use the new SelectAgent component for Assigned Agent */}
          <Grid xs={6} display="flex" alignContent="center" justifyItems="center">
            <AgentSelect
              control={control}
              name="assignedAgent"
              label="Assigned Agent"
              required={false}
              error={errors.assignedAgent}
              disabled={autoAssignAgent}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={(event) => {
                    const checked = event.target.checked;
                    setAutoAssignAgent(checked);
                    // Clear the assignedAgent field if auto assign is enabled.
                    if (checked) {
                      setValue('assignedAgent', '');
                    }
                  }}
                  name="test"
                  checked={autoAssignAgent}
                />
              }
              label="Auto assign (Beta)"
              labelPlacement="end"
              sx={{
                mt: 1,
                ml: 2,
                '& .MuiFormControlLabel-label': { whiteSpace: 'nowrap' }
              }}
            />
          </Grid>

          <Grid xs={6} display="flex" flexGrow={1} justifyContent="center" alignItems="center">
            <SelectServiceCategory
              control={control}
              name="serviceCategory"
              required={true}
              error={errors.serviceCategory}
            />
          </Grid>

          <Grid xs={12} display="flex" flexGrow={1} alignItems="center" justifyContent="center">
            <InputField
              control={control}
              name="complaint"
              multiline
              margin="normal"
              fullWidth
              id="complaint"
              label="Job Complaint"
              type="text"
              minRows={3}
              helperText={errors.complaint?.message ?? ''}
              error={!!errors.complaint}
            />
          </Grid>

          <Grid
            xs={6}
            display="flex"
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
            mt={2}>
            <PlacesAutocomplete
              setFormValues={setValue}
              setMarkerPosition={setMarkerPosition}
              resetPosition={resetPosition}
              setResetPosition={setResetPosition}
            />
          </Grid>

          <Grid xs={12} display="flex" flexGrow={1} justifyContent="center" alignItems="center">
            <LeafletMap center={markerPosition}>
              <>
                <ClickableMarker
                  position={markerPosition}
                  setMarkerPosition={setMarkerPosition}
                  setResetPosition={setResetPosition}
                />
                <MapClickHandler handleMapClick={handleMapClick} />
                <RecenterAutomatically lat={markerPosition[0]} lng={markerPosition[1]} />
              </>
            </LeafletMap>
          </Grid>
        </Grid>
      </form>
    </ModalDialog>
  );
};

export default QuickJob;
