import { useListStaffQuery } from 'app/services/staff';
import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';

const customStyles = () => {
  let isActive = false;
  return {
    input: (provided: any, state: any) => ({
      ...provided,
      color: 'white',
      height: '100%',
      lineHeight: '1rem',
      fontSize: '16px',
      fontWeight: '500'
    }),
    control: (provided: any, state: any) => {
      isActive = state.isFocused;
      return {
        ...provided,
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: state.isFocused
          ? 'linear-gradient(90deg, rgba(0,82,120,1) 23%, rgba(0,152,222,1) 95%)'
          : 'white',
        border: '1px solid black',
        borderRadius: '13px',
        padding: '3px 10px',
        cursor: 'pointer',
        position: 'relative',
        boxShadow: state.isFocused ? '0 0 0 3px rgba(0, 0, 0, 0.2)' : 'none',
        ':before': {
          content: '""',
          display: state.isFocused ? 'none' : 'block',
          position: 'absolute',
          top: '-2px',
          left: '-2px',
          right: '-2px',
          bottom: '-2px',
          zIndex: -1,
          borderRadius: '13px',
          padding: '1px',
          WebkitMaskComposite: 'destination-out',
          maskComposite: 'exclude'
        }
      };
    },
    menu: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      zIndex: 999
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: 0,
      fontSize: '16px',
      fontWeight: '500',
      color: 'white'
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: 'transparent',
      color: state.selectProps?.menuIsOpen || isActive ? 'white !important' : 'black !important',
      lineHeight: '1rem'
    }),
    multiValue: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: 'transparent',
      color: state.selectProps?.menuIsOpen || isActive ? 'white !important' : 'black !important',
      lineHeight: '10px'
    }),
    multiValueLabel: (provided: any, state: any) => ({
      ...provided,
      color: state.selectProps?.menuIsOpen || isActive ? 'white !important' : 'black !important'
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: state.isFocused ? 'white' : 'black',
      padding: 0,
      '&:hover': { color: state.isFocused ? 'white' : 'black' }
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: state.isFocused ? 'white' : 'black',
      fontSize: 16,
      fontWeight: 500
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      width: '100%'
    })
  };
};

interface AgentThemedSelectProps {
  value?: { value: number; label: string } | { value: number; label: string }[] | null;
  onChange?: (
    selected: { value: number; label: string } | { value: number; label: string }[] | null
  ) => void;
  placeholder?: string;
  isClearable?: boolean;
  isMulti?: boolean;
}

const AgentThemedSelect: React.FC<AgentThemedSelectProps> = ({
  value = null,
  onChange,
  placeholder = 'Select Agent',
  isClearable = true,
  isMulti = false
}) => {
  const { data: agentData, isLoading: agentsLoading } = useListStaffQuery({
    limit: 1000,
    offset: 0
  });

  const [defaultOptions, setDefaultOptions] = useState<{ value: number; label: string }[]>([]);

  useEffect(() => {
    if (agentData?.items && Array.isArray(agentData.items)) {
      setDefaultOptions(
        agentData.items.map((el: any) => ({
          value: el.adminID,
          label: `${el?.firstName ?? ''} ${el?.lastName ?? ''}`.trim()
        }))
      );
    }
  }, [agentData]);

  const loadOptions = async (inputValue: string) => {
    return defaultOptions.filter((agent) =>
      agent.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      isLoading={agentsLoading}
      isMulti={isMulti}
      value={value}
      onChange={(selected) => {
        //@ts-ignore
        onChange?.(selected);
      }}
      placeholder={placeholder}
      isClearable={isClearable}
      styles={customStyles()}
    />
  );
};

export default AgentThemedSelect;
