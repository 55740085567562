import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, MenuItem, CircularProgress } from '@mui/material';
import { Controller, Control, FieldError } from 'react-hook-form';
import useServiceCategoryList from 'hooks/useServiceCategoryList';
import { GetAllCategoryResponseDTO } from 'pages/services/category/categorySlice';

// Define the expected type for a service category
interface ServiceCategory {
  id: number;
  serviceCategoryName: string;
}

interface SelectServiceCategoryProps {
  control?: Control<any>;
  name?: string;
  label?: string;
  value?: number | ServiceCategory | null;
  onChange?: (selected: ServiceCategory | null) => void;
  required?: boolean;
  error?: FieldError | string;
}

const SelectServiceCategory: React.FC<SelectServiceCategoryProps> = ({
  control,
  name,
  label = 'Service Category',
  value = null,
  onChange,
  required = false,
  error
}) => {
  const { serviceCategoryList, serviceCategoryLoading } = useServiceCategoryList();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedValue, setSelectedValue] = useState<ServiceCategory | null>(null);

  // Ensure data is an array
  const safeCategoryList: GetAllCategoryResponseDTO[] = Array.isArray(serviceCategoryList)
    ? serviceCategoryList
    : [];

  // Convert API response to match ServiceCategory type
  const formattedCategories: ServiceCategory[] = safeCategoryList.map((cat) => ({
    id: cat.serviceCategoryID,
    serviceCategoryName: cat.serviceCategoryName
  }));

  // Automatically match the ID to the corresponding category object
  useEffect(() => {
    if (typeof value === 'number') {
      const matchedCategory = formattedCategories.find((cat) => cat.id === value) ?? null;
      setSelectedValue(matchedCategory);
    } else {
      setSelectedValue(value);
    }
  }, [value, formattedCategories]);

  // Handle selection change
  const handleChange = (_event: any, newValue: ServiceCategory | null) => {
    setSelectedValue(newValue);
    if (onChange) onChange(newValue);
  };

  return serviceCategoryLoading ? (
    <TextField
      fullWidth
      label={label}
      InputProps={{
        endAdornment: <CircularProgress size={20} />
      }}
      disabled
    />
  ) : control && name ? (
    <Controller
      name={name}
      control={control}
      rules={{ required: required ? `${label} is required` : false }}
      render={({ field }) => {
        // Convert stored ID to an object for Autocomplete
        const selected = formattedCategories.find((cat) => cat.id === field.value) ?? null;

        return (
          <Autocomplete
            options={formattedCategories}
            fullWidth
            value={selected}
            getOptionLabel={(option) => option.serviceCategoryName}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onInputChange={(_, value) => setSearchTerm(value)}
            onChange={(_, newValue) => {
              field.onChange(newValue?.id ?? null); // Store only the ID
              handleChange(_, newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={!!error}
                helperText={typeof error === 'string' ? error : error?.message}
                margin="normal"
                fullWidth
              />
            )}
            renderOption={(props, option) => (
              <MenuItem {...props} key={option.id} value={option.id}>
                {option.serviceCategoryName}
              </MenuItem>
            )}
          />
        );
      }}
    />
  ) : (
    <Autocomplete
      options={formattedCategories}
      value={selectedValue}
      fullWidth
      getOptionLabel={(option) => option.serviceCategoryName}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={(_, value) => setSearchTerm(value)}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={!!error}
          helperText={typeof error === 'string' ? error : error?.message}
          fullWidth
        />
      )}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.id} value={option.id}>
          {option.serviceCategoryName}
        </MenuItem>
      )}
    />
  );
};

export default SelectServiceCategory;
