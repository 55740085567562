import { api } from './api';

interface AdminProfileResponse {
    adminID: string;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    password: string;
    adminOldID: string;
    adminPhone: string;
    enabled: string;
    staffID: string;
    adminEnabled: string;
    disabledReason: string;
    disabledDate: string;
    disabledBy: string;
    createdBy: string;
    createdDate: string;
    updatedBy: string;
    updatedDate: string;
    roleID: number;
    districtID: number;
    stateID: number;
    role: {
        roleID: number;
        roleName: string;
        roleDescription: string;
        roleEnabled: string;
        roleCode: number;
        createdDate: string;
        createdBy: string;
        updatedDate: string;
        updatedBy: string;
    };
    breakStatus: string;
}

export const profileInfoApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAdminProfile: builder.query<AdminProfileResponse, void>({
            query() {
                return {
                    url: `/admin/me`,
                    method: 'GET'
                };
            }
        })
    })
});

export const { useGetAdminProfileQuery } = profileInfoApi;
