import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  CircularProgress
} from '@mui/material';

type CustomerForm = {
  leadName: string;
  leadMobileNumber: string;
  contactEmail: string;
  address: string;
};

type CreateCustomerDialogProps = {
  open: boolean;
  onClose: () => void;
  customerForm: CustomerForm;
  setCustomerForm: React.Dispatch<React.SetStateAction<CustomerForm>>;
  onSubmit: () => void;
  isSubmitting: boolean;
};

export const CreateCustomerDialog: React.FC<CreateCustomerDialogProps> = ({
  open,
  onClose,
  customerForm,
  setCustomerForm,
  onSubmit,
  isSubmitting
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="create-customer-dialog-title">
      <DialogTitle id="create-customer-dialog-title">Create Customer Account</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Name"
              variant="outlined"
              size="small"
              fullWidth
              value={customerForm.leadName}
              onChange={(e) => setCustomerForm((prev) => ({ ...prev, leadName: e.target.value }))}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Mobile Number"
              variant="outlined"
              size="small"
              fullWidth
              value={customerForm.leadMobileNumber}
              onChange={(e) =>
                setCustomerForm((prev) => ({ ...prev, leadMobileNumber: e.target.value }))
              }
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Email (Optional)"
              variant="outlined"
              size="small"
              fullWidth
              value={customerForm.contactEmail}
              onChange={(e) =>
                setCustomerForm((prev) => ({ ...prev, contactEmail: e.target.value }))
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Address (Optional)"
              variant="outlined"
              size="small"
              fullWidth
              value={customerForm.address}
              onChange={(e) => setCustomerForm((prev) => ({ ...prev, address: e.target.value }))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          color="primary"
          disabled={isSubmitting || !customerForm.leadName || !customerForm.leadMobileNumber}>
          {isSubmitting ? <CircularProgress size={24} /> : 'Create Customer'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
