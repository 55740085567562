import Select, { components } from 'react-select';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; // Use this icon or customize

const customStyles = () => {
  let isActive = false;
  return {
    input: (provided: any, state: any) => ({
      width: '100%'
    }),
    control: (provided: any, state: any) => {
      isActive = state.isFocused;
      return {
        ...provided,
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: state.isFocused
          ? 'linear-gradient(90deg, rgba(0,82,120,1) 23%, rgba(0,152,222,1) 95%)' // Gradient background on focus
          : 'white',
        border: '1px solid black',
        borderRadius: '13px',
        padding: '3px 10px',
        cursor: 'pointer',
        position: 'relative',
        boxShadow: state.isFocused
          ? '0 0 0 3px rgba(0, 0, 0, 0.2)' // Focus box-shadow
          : 'none',
        ':before': {
          content: '""',
          display: state.isFocused ? 'none' : 'block',
          position: 'absolute',
          top: '-2px',
          left: '-2px',
          right: '-2px',
          bottom: '-2px',
          zIndex: -1,
          borderRadius: '13px',
          padding: '1px', // Inner padding for the gradient border
          WebkitMaskComposite: 'destination-out',
          maskComposite: 'exclude'
        }
      };
    },
    menu: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      zIndex: 999
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: 0,
      fontsize: '16px',
      fontWeight: '500',
      color: 'white' // White text for better contrast
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: 'transparent',
      color: state.selectProps?.menuIsOpen || isActive ? 'white !important' : 'black !important',
      lineHeight: '1rem'
    }),
    multiValue: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: 'transparent',
      color: state.selectProps?.menuIsOpen || isActive ? 'white !important' : 'black !important',
      lineHeight: '10px'
    }),
    multiValueLabel: (provided: any, state: any) => {
      return {
        ...provided,
        color: state.selectProps?.menuIsOpen || isActive ? 'white !important' : 'black !important'
      };
    },
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: state.isFocused ? 'white' : 'black',
      padding: 0,
      '&:hover': { color: state.isFocused ? 'white' : 'black' }
    }),
    indicatorSeparator: () => ({
      display: 'none' // Remove the separator
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: state.isFocused ? 'white' : 'black',
      fontSize: 16,
      fontWeight: 500
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      width: '100%'
    }),
    indicatorContainer: (provided: any, state: any) => ({
      ...provided,
      padding: 0,
      display: 'none !important'
    })
  };
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowForwardIosIcon fontSize="small" />
    </components.DropdownIndicator>
  );
};

export interface IThemedSelectProps {
  options: { value: string; label: string }[];
  handleClear: () => void;
  isMulti: boolean;
  placeholder: string;
  onChange: (e: any) => void;
  onClear: () => void;
  value: any;
  isClearable?: boolean;
}
const ThemedSelect = ({
  options,
  handleClear,
  isMulti,
  placeholder,
  onChange,
  value,
  isClearable
}: IThemedSelectProps) => {
  return (
    <Select
      styles={customStyles()}
      components={{ DropdownIndicator }}
      options={options}
      placeholder={placeholder}
      isMulti={isMulti}
      onChange={onChange}
      isClearable={false}
      isSearchable={false}
      value={value}
    />
  );
};

export default ThemedSelect;
