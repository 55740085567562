import { useState, useCallback, useEffect } from 'react';
import { Box, Chip } from '@mui/material';
import QueryString from 'qs';

export * from './ThemedFiltersModal';

export type FilterOptionType = {
  label: string; // The display label for the option
  value: string | number; // The value corresponding to the option
};

export type FilterTemplateType = {
  type:
    | 'date-range'
    | 'select'
    | 'multi-select'
    | 'radio-button'
    | 'switch'
    | 'text'
    | 'range'
    | 'checkbox'
    | 'service-category-select'
    | 'agent-select';
  label: string;
  fieldKey: string;
  options?: FilterOptionType[];
  whereOption?: 'between' | 'eq' | 'in' | 'range';
  inShowMoreSection?: boolean;
  isMulti?: boolean;
};

const useThemedFilter = ({
  clearParentFilters,
  filterTemplate,
  searchParams,
  setSearchParams
}: any) => {
  const [filtersQuery, setFiltersQuery] = useState<string>('');
  const [appliedFilters, setAppliedFilters] = useState<any>({});
  const [filtersObj, setFiltersObj] = useState<any>({});
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleFilterChange = (e: any) => {
    const { name, value } = e.target;
    setFiltersQuery(value);
    setFiltersObj((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleFilterClear = useCallback(() => {
    setFiltersQuery('');
    setFiltersObj({});
  }, []);

  const openFilterModal = () => {
    setModalOpen(true);
    setFiltersObj(appliedFilters);
  };

  const closeFilterModal = () => {
    setFiltersObj({});
    if (clearParentFilters) clearParentFilters();
    setModalOpen(false);
  };

  const applyFilters = (supplied?: any) => {
    if (supplied) {
      setAppliedFilters(supplied);
      if (modalOpen) closeFilterModal();
    } else {
      setAppliedFilters(filtersObj);
      if (modalOpen) closeFilterModal();
    }
  };

  const clearFilters = useCallback(() => {
    setFiltersObj({});
    setAppliedFilters({});
    if (searchParams && setSearchParams) {
      searchParams.set('appliedFilters', JSON.stringify({}));
      searchParams.set('active-card', 'none');
      setSearchParams(searchParams);
    }
    if (clearParentFilters) clearParentFilters();
    setModalOpen(false);
  }, []);

  const handleChipDelete = (item: any) => {
    setAppliedFilters((prevAppliedFilters: any) => {
      const updatedFilters = { ...prevAppliedFilters };
      const whereOption = getFieldWhereOption(item.key);

      if (updatedFilters[item.key] === item.value) {
        delete updatedFilters[item.key];
      }

      if (whereOption === 'eq') {
        delete updatedFilters[item.key];
      }

      if (Array.isArray(updatedFilters[item.key]) && whereOption === 'in') {
        updatedFilters[item.key] = updatedFilters[item.key].filter(
          (selectedItem: any) => selectedItem.value !== item.value
        );
        // If the array is empty after removal, delete the key
        if (updatedFilters[item.key].length === 0) {
          delete updatedFilters[item.key];
        }
      }

      if (Array.isArray(updatedFilters[item.key]) && whereOption === 'between') {
        delete updatedFilters[item.key];
      }

      if (searchParams && setSearchParams) {
        searchParams.set('appliedFilters', JSON.stringify(updatedFilters));
        searchParams.set('active-card', 'none');
        setSearchParams(searchParams);
      }
      return updatedFilters;
    });
    if (clearParentFilters) clearParentFilters();
  };

  const getFieldWhereOption = (fieldKey: string) => {
    const field = filterTemplate.find((item: any) => item.fieldKey === fieldKey);
    return field?.whereOption;
  };

  const RenderChipsView = useCallback(() => {
    const chips: any[] = [];

    for (const key in appliedFilters) {
      if (typeof appliedFilters[key] === 'string') {
        const whereOpt = getFieldWhereOption(key);
        //@ts-ignore
        const value = appliedFilters[key]?.value || appliedFilters[key];
        const label = filterTemplate.filter((item: any) => item.fieldKey === key)[0]?.label;
        if (whereOpt === 'eq') {
          chips.push({
            label: `${label}: ${value}`,
            value: value,
            key: key
          });
        }
      }

      if (typeof appliedFilters[key] === 'object') {
        const whereOpt = getFieldWhereOption(key);
        //@ts-ignore
        const value = appliedFilters[key]?.value || appliedFilters[key];
        const label = filterTemplate.filter((item: any) => item.fieldKey === key)[0]?.label;
        if (whereOpt === 'eq') {
          chips.push({
            label: `${label}: ${value}`,
            value: value,
            key: key
          });
        }
      }

      if (typeof appliedFilters[key] === 'object' && appliedFilters[key]?.length > 0) {
        const whereOpt = getFieldWhereOption(key);
        if (whereOpt === 'in') {
          appliedFilters[key].forEach((item: any) => {
            chips.push({
              label: item.label,
              value: item.value,
              key: key
            });
          });
        }

        if (whereOpt === 'between') {
          const from = appliedFilters[key]?.[0];
          const to = appliedFilters[key]?.[1];
          chips.push({
            label: `${from.format('DD-MM-YYYY')} - ${to.format('DD-MM-YYYY')}`,
            value: `${from} - ${to}`,
            key: key
          });
        }
      }
    }

    return (
      <Box display="flex" flexWrap="wrap" gap={3} my={2}>
        {chips.map((item, idx) => (
          <Chip label={item.label} key={idx} onDelete={() => handleChipDelete(item)} />
        ))}
      </Box>
    );
  }, [filtersObj, appliedFilters]);

  const handleAppliedFiltersChange = useCallback(
    (appliedFilters: any) => {
      const queryObj: any = {};

      for (const key in appliedFilters) {
        if (typeof appliedFilters[key] === 'string') {
          queryObj[key] = appliedFilters[key];
        }

        if (typeof appliedFilters[key] === 'object' && appliedFilters[key]?.length > 0) {
          const whereOpt = getFieldWhereOption(key);
          if (whereOpt === 'in') {
            queryObj[key] = appliedFilters[key]?.map((item: any) => item.value);
          }

          if (whereOpt === 'between') {
            const from = appliedFilters[key]?.[0];
            const to = appliedFilters[key]?.[1];
            queryObj[key] = [from.toDate(), to.toDate()];
          }
        }
      }

      setFiltersQuery(QueryString.stringify(queryObj));
    },
    [filtersObj]
  );

  useEffect(() => {
    handleAppliedFiltersChange(appliedFilters);
  }, [appliedFilters, handleAppliedFiltersChange]);

  return {
    filtersQuery,
    filtersObj,
    handleFilterChange,
    handleFilterClear,
    openFilterModal,
    closeFilterModal,
    modalOpen,
    RenderChipsView,
    appliedFilters,
    setFiltersObj,
    applyFilters,
    clearFilters
  };
};

export default useThemedFilter;
