import { TuneOutlined } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';

const JobCardsHeader = ({ openFilterModal }: any) => {
  return (
    <Grid container spacing={3} justifyContent="space-between" sx={{ mb: 4 }}>
      <Grid item>
        <Typography variant='h2'>Job Cards</Typography>
      </Grid>
      <Grid item></Grid>
      <Grid
        item
        sx={{ p: 0, paddingLeft: '0px !important', paddingTop: '0px !important' }}
        alignContent={'center'}>
        <IconButton onClick={openFilterModal}>
          <TuneOutlined sx={{ color: '#D9D9D9' }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default JobCardsHeader;
