import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, useTheme, useMediaQuery } from '@mui/material';

const ResponsiveOutlet = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box width={isMobile ? 'calc(100vw - 40px)' : 'calc(100vw - 290px)'} boxSizing="border-box">
      <Outlet />
    </Box>
  );
};

export default ResponsiveOutlet;
