import { api } from './api';

export const reportApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGeneralReport: builder.query({
      query: () => `/admin/report/general`,
      providesTags: ['Report']
    }),
    getEarningsReport: builder.query({
      query: (params) => {
        const { betweenDates, serviceCategoryID } = params;
        let queryUrl = `/admin/report/earnings`;

        if (typeof serviceCategoryID === 'number') {
          queryUrl += `?serviceCategoryID=${serviceCategoryID}`;
        }

        if (typeof betweenDates === 'object') {
          queryUrl += `${typeof serviceCategoryID === 'number' ? '&' : '?'}${betweenDates
            .map((date: any) => `betweenDates=${date}`)
            .join('&')}`;
        }
        return queryUrl;
      },
      transformResponse: (res: any) => {
        let serviceCategoryEarnings = res?.serviceCategoryEarnings?.map((item: any) => {
          return { ...item, id: item['serviceCategoryID'] };
        });
        return { ...res, serviceCategoryEarnings };
      },
      providesTags: ['Report']
    }),
    getOneEarningReport: builder.query({
      query: (params) => {
        const {
          limit,
          offset,
          serviceCategoryID,
          betweenDates,
          technicianID,
          techID,
          technicianName
        } = params;
        let queryUrl = `/admin/report/earnings/${serviceCategoryID}?limit=${limit}&offset=${offset}`;

        if (typeof serviceCategoryID === 'number') {
          queryUrl += `?serviceCategoryID=${serviceCategoryID}`;
        }

        if (typeof techID === 'string') {
          queryUrl += `&techID=${techID}`;
        }

        if (typeof technicianID === 'string') {
          queryUrl += `&technicianID=${technicianID}`;
        }

        if (typeof technicianName === 'string') {
          queryUrl += `&technicianName=${technicianName}`;
        }

        if (typeof betweenDates === 'object') {
          queryUrl += `&${betweenDates.map((date: any) => `betweenDates=${date}`).join('&')}`;
        }
        return queryUrl;
      },
      transformResponse: (res: any) => {
        let items = res?.items?.map((item: any) => {
          return { ...item, id: item['jobID'] };
        });
        return { ...res, items };
      },
      providesTags: ['Report']
    }),
    getKpiReport: builder.query({
      query: (params) => {
        const { betweenDates } = params;
        let queryUrl = '/admin/report/kpi/job';

        if (typeof betweenDates === 'object') {
          queryUrl += `?${betweenDates.map((date: any) => `betweenDates=${date}`).join('&')}`;
        }
        return queryUrl;
      },
      providesTags: ['Report']
    }),
    getMonthwiseReport: builder.query({
      query: (params) => {
        const { betweenDates } = params;
        let queryUrl = '/admin/report/monthwise';

        if (typeof betweenDates === 'object') {
          queryUrl += `?${betweenDates.map((date: any) => `betweenDates=${date}`).join('&')}`;
        }
        return queryUrl;
      },
      transformResponse: (res: any) => {
        let monthlySummary = res?.monthlySummary?.map((item: any) => {
          return { ...item, id: item['date'] };
        });
        return { ...res, monthlySummary };
      },
      providesTags: ['Report']
    }),
    downloadReport: builder.mutation({
      query(body) {
        return {
          url: `/admin/download-report`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body,
          timeout: 120000
        };
      },
      extraOptions: {
        maxRetries: 1
      }
    }),
    getFollowupStatusStats: builder.query({
      query: () => `/admin/report/job/followup-stats`,
      providesTags: ['Report']
    })
  })
});

export const {
  useGetGeneralReportQuery,
  useGetEarningsReportQuery,
  useGetKpiReportQuery,
  useGetOneEarningReportQuery,
  useGetMonthwiseReportQuery,
  useDownloadReportMutation,
  useGetFollowupStatusStatsQuery,
  useLazyGetFollowupStatusStatsQuery
} = reportApi;
