import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Box, CircularProgress, Typography, Stack, IconButton } from '@mui/material';
import { Add as AddIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';

import {
  useGetCrmLeadDetailsQuery,
  useAddRemarkMutation,
  useCreateCustomerAccountMutation,
  useCreateJobMutation,
  useMarkLeadAsConvertedMutation
} from 'app/services/crm';
import { useDispatch } from 'react-redux';
import { openToastMessage } from 'app/slices/toastMessageSlice';
import {
  ActivitiesSection,
  AddRemarkDialog,
  BasicInformation,
  CreateCustomerDialog,
  QualifiedLeadActions
} from './components/LeadDetails';
import QuickJob from 'pages/jobs/QuickJob';

export default function CrmLeadDetails() {
  const dispatch = useDispatch();
  const { leadID } = useParams<{ leadID: string }>();
  const numericLeadID = Number(leadID);
  const nav = useNavigate();

  // API queries & mutations
  const { data, isLoading, isError, refetch } = useGetCrmLeadDetailsQuery(
    { leadID: numericLeadID },
    { skip: !leadID }
  );

  const [addRemark, { isLoading: isAddingRemark }] = useAddRemarkMutation();
  const [createCustomerAccount, { isLoading: isCreatingCustomer }] =
    useCreateCustomerAccountMutation();
  const [markLeadAsConverted, { isLoading: isMarkingConverted }] = useMarkLeadAsConvertedMutation();

  // Local states
  const [remarkText, setRemarkText] = useState('');
  const [selectedActivityType, setSelectedActivityType] = useState<string>('');
  const [newStatus, setNewStatus] = useState<string>('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [quickJobDialogOpen, setQuickJobDialogOpen] = useState(false);

  // Create Customer Dialog
  const [isCustomerDialogOpen, setIsCustomerDialogOpen] = useState(false);
  const [customerForm, setCustomerForm] = useState({
    leadName: '',
    leadMobileNumber: '',
    contactEmail: '',
    address: ''
  });

  // Fetch data on mount/update
  useEffect(() => {
    if (leadID) {
      refetch();
    }
  }, [leadID, refetch]);

  // Loading & error states
  if (isLoading) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError || !data) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography color="error">Failed to load lead details.</Typography>
      </Box>
    );
  }

  // ===================
  // Handlers / Actions
  // ===================

  const handleOpenCustomerDialog = () => {
    // Pre-fill fields from lead if available
    setCustomerForm({
      leadName: data.name || '',
      leadMobileNumber: data.mobileNumber || '',
      contactEmail: '',
      address: ''
    });
    setIsCustomerDialogOpen(true);
  };

  const handleCreateCustomerAccount = async () => {
    try {
      const res = await createCustomerAccount({
        leadID: numericLeadID,
        leadName: customerForm.leadName,
        leadMobileNumber: customerForm.leadMobileNumber,
        contactEmail: customerForm.contactEmail || undefined,
        address: customerForm.address || undefined
      }).unwrap();

      dispatch(
        openToastMessage({
          type: 'success',
          message: res.message ?? 'Customer created successfully'
        })
      );
      setIsCustomerDialogOpen(false);
      refetch();
    } catch (err: any) {
      dispatch(
        openToastMessage({
          type: 'error',
          message: err?.data?.message || 'Failed to create customer account.'
        })
      );
    }
  };

  const handleOpenQuickJobDialog = () => {
    setQuickJobDialogOpen(true);
  };

  const handleCloseQuickJobDialog = async (val: any) => {
    setQuickJobDialogOpen(false);

    if (val === true) {
      try {
        await markLeadAsConverted({ leadID: numericLeadID }).unwrap();

        dispatch(
          openToastMessage({
            type: 'success',
            message: 'Lead marked as converted successfully.'
          })
        );
        refetch();
      } catch (err: any) {
        dispatch(
          openToastMessage({
            type: 'error',
            message: err?.data?.message || 'Failed to mark lead as converted.'
          })
        );
      }
    }
  };

  const handleAddRemark = async () => {
    if (!remarkText.trim() || !selectedActivityType) {
      dispatch(
        openToastMessage({
          type: 'error',
          message: 'Please fill in all required fields.'
        })
      );
      return;
    }

    try {
      await addRemark({
        leadID: numericLeadID,
        agentID: 1001, // e.g. from a logged-in user store
        remarkText,
        activityType: selectedActivityType,
        status: newStatus || undefined
      }).unwrap();

      setRemarkText('');
      setSelectedActivityType('');
      setNewStatus('');
      setIsDialogOpen(false);
      refetch();
      dispatch(
        openToastMessage({
          type: 'success',
          message: 'Remark added successfully'
        })
      );
    } catch (err) {
      dispatch(
        openToastMessage({
          type: 'error',
          message: 'Failed to add remark.'
        })
      );
    }
  };

  const formatDate = (dateStr: string) => moment(dateStr).format('DD-MMM-YYYY, hh:mm A');

  // Destructure data for simpler usage
  const {
    name,
    mobileNumber,
    caseDescription,
    status,
    category,
    createdDate,
    remarks,
    updatedByAgentName,
    updatedDate,
    source
  } = data;

  // ===================
  // Render
  // ===================

  return (
    <Box sx={{ p: { xs: 2, md: 4 }, maxWidth: 1200, mx: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        <IconButton color="primary" onClick={() => nav(-1)}>
          <ArrowBackIcon />
        </IconButton>
        Lead Details
      </Typography>

      {/* Basic Information */}
      <BasicInformation
        name={name}
        mobileNumber={mobileNumber}
        source={source}
        status={status}
        category={category}
        createdDate={createdDate}
        updatedByAgentName={updatedByAgentName}
        updatedDate={updatedDate}
        caseDescription={caseDescription}
        formatDate={formatDate}
      />

      {/* Qualified Lead Actions */}
      {status === 'Qualified' && (
        <QualifiedLeadActions
          isCreatingCustomer={isCreatingCustomer}
          isCreatingJob={isMarkingConverted}
          onCreateCustomerClick={handleOpenCustomerDialog}
          onCreateJobClick={handleOpenQuickJobDialog}
        />
      )}

      {/* Activities header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
          mt: 5
        }}>
        <Typography variant="h4" gutterBottom>
          Activities
        </Typography>
        <IconButton
          color="primary"
          onClick={() => setIsDialogOpen(true)}
          aria-label="add activity"
          sx={{
            backgroundColor: '#e3f2fd',
            '&:hover': { backgroundColor: '#b3e5fc' }
          }}>
          <AddIcon />
        </IconButton>
      </Box>

      {/* Activities Section (Remarks timeline) */}
      <ActivitiesSection remarks={remarks} formatDate={formatDate} />

      {/* Add Remark Dialog */}
      <AddRemarkDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        remarkText={remarkText}
        setRemarkText={setRemarkText}
        selectedActivityType={selectedActivityType}
        setSelectedActivityType={setSelectedActivityType}
        newStatus={newStatus}
        setNewStatus={setNewStatus}
        onSubmit={handleAddRemark}
        isSubmitting={isAddingRemark}
      />

      {/* Create Customer Dialog */}
      <CreateCustomerDialog
        open={isCustomerDialogOpen}
        onClose={() => setIsCustomerDialogOpen(false)}
        customerForm={customerForm}
        setCustomerForm={setCustomerForm}
        onSubmit={handleCreateCustomerAccount}
        isSubmitting={isCreatingCustomer}
      />

      {quickJobDialogOpen && (
        <QuickJob
          open={quickJobDialogOpen}
          handleClose={handleCloseQuickJobDialog}
          title="Create Job"
        />
      )}
    </Box>
  );
}
