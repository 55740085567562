import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

type BasicInformationProps = {
  // Either make each field optional and allow null:
  name?: string | null;
  mobileNumber?: string | null;
  source?: string | null;
  status?: string | null;
  category?: string | null;
  createdDate?: string | null;
  updatedByAgentName?: string | null;
  updatedDate?: string | null;
  caseDescription?: string | null;
  formatDate: (dateStr: string) => string;
};

const sectionStyle = {
  mb: 3,
  p: 3,
  backgroundColor: '#ffffff',
  borderRadius: 2,
  boxShadow: 1
};

export const BasicInformation: React.FC<BasicInformationProps> = ({
  name,
  mobileNumber,
  source,
  status,
  category,
  createdDate,
  updatedByAgentName,
  updatedDate,
  caseDescription,
  formatDate
}) => {
  return (
    <Box sx={sectionStyle}>
      <Typography variant="h6" sx={{ fontWeight: 500, mb: 2 }}>
        Basic Information
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" color="text.secondary">
            Name
          </Typography>
          <Typography variant="body1">{name || 'N/A'}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" color="text.secondary">
            Mobile Number
          </Typography>
          <Typography variant="body1">{mobileNumber || 'N/A'}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" color="text.secondary">
            Source
          </Typography>
          <Typography variant="body1">{source ?? 'N/A'}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" color="text.secondary">
            Status
          </Typography>
          <Typography variant="body1">{status || 'N/A'}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" color="text.secondary">
            Category
          </Typography>
          <Typography variant="body1">{category || 'N/A'}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" color="text.secondary">
            Created Date
          </Typography>
          <Typography variant="body1">{createdDate ? formatDate(createdDate) : 'N/A'}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" color="text.secondary">
            Update By
          </Typography>
          <Typography variant="body1">{updatedByAgentName ?? 'N/A'}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" color="text.secondary">
            Update Date
          </Typography>
          <Typography variant="body1">{updatedDate ? formatDate(updatedDate) : 'N/A'}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2" color="text.secondary">
            Case Description
          </Typography>
          <Typography variant="body1">{caseDescription || 'N/A'}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
