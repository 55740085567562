import { useTheme, useMediaQuery } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

import DrawerHeader from 'layout/DrawerHeader';
import { drawerWidth } from 'utils/constants';
import SidebarMenu from './SidebarMenu';
import DrawerProvider from './drawerProvider';

// Styled Drawer for desktop (permanent variant)
const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    width: drawerWidth
  }),
  ...(!open && {
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  })
}));

export default function Sidebar({
  openDrawer,
  handleDrawerClose
}: {
  openDrawer: boolean;
  handleDrawerClose: () => void;
}): React.ReactElement {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // On mobile use the "temporary" variant using the default MUI Drawer.
  if (isMobile) {
    return (
      <DrawerProvider isDrawerOpen={openDrawer}>
        <MuiDrawer
          variant="temporary"
          open={openDrawer}
          onClose={handleDrawerClose}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            sx: {
              // Ensure the temporary drawer is above other elements
              zIndex: theme.zIndex.modal + 1
            }
          }}>
          <DrawerHeader />
          <SidebarMenu />
        </MuiDrawer>
      </DrawerProvider>
    );
  }

  // On desktop, use the custom styled Drawer (permanent variant).
  return (
    <DrawerProvider isDrawerOpen={openDrawer}>
      <StyledDrawer variant="permanent" open={openDrawer}>
        <DrawerHeader />
        <SidebarMenu />
      </StyledDrawer>
    </DrawerProvider>
  );
}
