//@ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Snackbar,
  CircularProgress
} from '@mui/material';
import { Alert } from '@mui/material';
import {
  useGetRosterTeamsQuery,
  useCreateTeamMutation,
  useDeleteTeamMutation,
  useUpdateRosterTeamMutation,
  useUpdateTeamMemberMutation
} from 'app/services/shift';
import UnassignedUsersList from './UnassignedUsersList';
import TeamList from './TeamList';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import { DragDropContext } from '@hello-pangea/dnd';

const TeamManagementDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { data: teamData, isLoading: teamDataLoading } = useGetRosterTeamsQuery();
  const [createTeam, { isLoading: isCreateTeamLoading }] = useCreateTeamMutation();
  const [deleteTeam, { isLoading: isDeleteTeamLoading }] = useDeleteTeamMutation();
  const [updateTeamMember, { isLoading: iseUpdateTeamMemberLoading }] =
    useUpdateTeamMemberMutation();
  const [updateRosterTeam, { isLoading: isUpdateTeamLoading }] = useUpdateRosterTeamMutation();

  const [teams, setTeams] = useState([]);
  const [unassignedUsers, setUnassignedUsers] = useState([]);
  const [editTeamId, setEditTeamId] = useState(null);
  const [teamNameDraft, setTeamNameDraft] = useState('');
  const [deleteTeamId, setDeleteTeamId] = useState(null);
  const [allowActions, setAllowActions] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Transform API data into the expected format
  useEffect(() => {
    if (teamData) {
      const transformedTeams = teamData.teams.map((team) => {
        return {
          id: team.teamID.toString(),
          name: team.teamName,
          users: team.agents
            .map((agent) => ({
              adminID: agent.agentID,
              adminName: agent.agentName,
              order: agent.teamAgentOrder
            }))
            .sort((a, b) => a.order - b.order)
        };
      });

      const transformedUnassignedUsers = teamData.unassignedAgents.map((agent) => ({
        adminID: agent.agentID,
        adminName: agent.agentName
      }));

      setTeams(transformedTeams);
      setUnassignedUsers(transformedUnassignedUsers);
    }
  }, [teamData]);

  // Callback function when a user is moved
  const onUserMoved = (agentId, teamId, order) => {
    handleUpdateTeamMember(agentId, teamId, order);
  };

  const handleCreateNewTeam = async () => {
    if (!allowActions) return;

    // Extract existing team numbers
    const teamNumbers = teams
      .map((team) => {
        const match = team.name.match(/Team (\d+)/);
        return match ? parseInt(match[1], 10) : null;
      })
      .filter((num) => num !== null)
      .sort((a, b) => a - b);

    // Find the smallest missing number
    let newTeamNumber = 1;
    for (let i = 0; i < teamNumbers.length; i++) {
      if (teamNumbers[i] !== i + 1) {
        newTeamNumber = i + 1;
        break;
      }
      newTeamNumber = teamNumbers.length + 1;
    }

    const newTeamName = `Team ${newTeamNumber}`;

    try {
      const response = await createTeam({ teamName: newTeamName }).unwrap();
      const newTeam = {
        id: response.teamID.toString(),
        name: response.teamName,
        users: []
      };
      setTeams([...teams, newTeam]);
      setSnackbar({ open: true, message: 'Team created successfully!', severity: 'success' });
    } catch (error) {
      console.error('Failed to create team:', error);
      setSnackbar({
        open: true,
        message: 'Failed to create team. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleDeleteTeam = async (teamId) => {
    if (!allowActions) return;
    try {
      await deleteTeam({ teamID: teamId }).unwrap();
      setTeams(teams.filter((team) => team.id !== teamId));
      setSnackbar({ open: true, message: 'Team deleted successfully!', severity: 'success' });
      setDeleteTeamId(null);
    } catch (error) {
      console.error('Failed to delete team:', error);
      setSnackbar({
        open: true,
        message: 'Failed to delete team. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleUpdateTeam = async (teamId, teamName?: string, teamOrder?: number) => {
    if (!allowActions) return;
    setAllowActions(false);

    try {
      await updateRosterTeam({ teamID: teamId, teamName: teamName, teamOrder: teamOrder }).unwrap();
      setSnackbar({ open: true, message: 'Team updated successfully!', severity: 'success' });
      setAllowActions(true);
    } catch (error) {
      setAllowActions(true);
      console.error('Failed to update team:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update team. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleUpdateTeamName = async (teamId, teamName) => {
    if (!allowActions) return;
    setAllowActions(false);

    try {
      await updateRosterTeam({ teamID: teamId, teamName: teamName }).unwrap();
      setSnackbar({ open: true, message: 'Team updated successfully!', severity: 'success' });
      setAllowActions(true);
    } catch (error) {
      setAllowActions(true);
      console.error('Failed to update team:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update team. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleUpdateTeamMember = async (agentId, teamId, order) => {
    if (!allowActions) return;
    try {
      await updateTeamMember({
        agentID: agentId,
        teamID: teamId === 'unassigned' ? null : Number(teamId),
        order: teamId === 'unassigned' ? null : order + 1
      }).unwrap();
      setSnackbar({
        open: true,
        message: 'Team member updated successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Failed to update team member:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update team member. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (teamDataLoading) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>Team Management</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  const onDragEnd = async (result) => {
    if (!allowActions) return; // Disable drag-and-drop if actions are not allowed

    const { source, destination, type } = result;

    // If dropped outside a droppable area
    if (!destination) return;

    // Reorder teams
    if (type === 'team') {
      const newTeams = [...teams];
      const [removed] = newTeams.splice(source.index, 1);
      newTeams.splice(destination.index, 0, removed);
      setTeams(newTeams);

      await handleUpdateTeam(result.draggableId, undefined, result.destination.index + 1);
      return;
    }

    // Handle user movements
    if (type === 'user') {
      const sourceIsUnassigned = source.droppableId === 'unassigned';
      const destinationIsUnassigned = destination.droppableId === 'unassigned';
      let user = null;

      // Moving from unassigned to a team
      if (sourceIsUnassigned && !destinationIsUnassigned) {
        user = unassignedUsers[source.index];
        const teamIndex = teams.findIndex((t) => t.id === destination.droppableId);
        const updatedTeams = [...teams];
        updatedTeams[teamIndex].users.splice(destination.index, 0, user);
        setTeams(updatedTeams);
        setUnassignedUsers(unassignedUsers.filter((u) => u.adminID !== user.adminID));
      }
      // Moving from a team to unassigned
      else if (!sourceIsUnassigned && destinationIsUnassigned) {
        const teamIndex = teams.findIndex((t) => t.id === source.droppableId);
        const updatedTeams = [...teams];
        const [removedUser] = updatedTeams[teamIndex].users.splice(source.index, 1);
        setTeams(updatedTeams);
        setUnassignedUsers([...unassignedUsers, removedUser]);
        user = removedUser;
      }
      // Moving between teams or within the same team
      else if (!sourceIsUnassigned && !destinationIsUnassigned) {
        const sourceTeamIndex = teams.findIndex((t) => t.id === source.droppableId);
        const destTeamIndex = teams.findIndex((t) => t.id === destination.droppableId);
        const sourceTeam = [...teams[sourceTeamIndex].users];
        const destTeam = [...teams[destTeamIndex].users];
        const [removed] = sourceTeam.splice(source.index, 1);

        const updatedTeams = [...teams];
        updatedTeams[sourceTeamIndex].users = sourceTeam;

        if (source.droppableId === destination.droppableId) {
          sourceTeam.splice(destination.index, 0, removed);
        } else {
          destTeam.splice(destination.index, 0, removed);
          updatedTeams[destTeamIndex].users = destTeam;
        }
        user = removed;

        setTeams(updatedTeams);
      }
      if (user?.adminID) onUserMoved(user.adminID, destination.droppableId, destination.index);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Team Management</DialogTitle>
      <DragDropContext onDragEnd={onDragEnd}>
        <DialogContent>
          {/* Unassigned Users List */}
          <UnassignedUsersList
            unassignedUsers={unassignedUsers}
            allowActions={allowActions}
            onUserMoved={onUserMoved}
          />

          {/* Create Team Button */}
          <Button
            variant="contained"
            onClick={handleCreateNewTeam}
            sx={{ mb: 3 }}
            disabled={!allowActions || isCreateTeamLoading}>
            {isCreateTeamLoading ? <CircularProgress size={24} /> : 'Create New Team'}
          </Button>

          {/* Teams List */}
          <TeamList
            teams={teams}
            allowActions={allowActions}
            onUserMoved={onUserMoved}
            onDeleteTeam={(teamId) => setDeleteTeamId(teamId)}
            handleUpdateTeamName={handleUpdateTeamName}
          />
        </DialogContent>

        {/* Delete Confirmation Dialog */}
        <DeleteConfirmationDialog
          open={Boolean(deleteTeamId)}
          onClose={() => setDeleteTeamId(null)}
          onConfirm={() => handleDeleteTeam(deleteTeamId)}
          isDeleteTeamLoading={isDeleteTeamLoading}
          allowActions={allowActions}
        />

        {/* Snackbar for Notifications */}
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </DragDropContext>
    </Dialog>
  );
};

export default TeamManagementDialog;
