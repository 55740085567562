export const colors = {
  primary: 'rgba(11,81,116,1)',
  primaryContrastText: 'rgba(255,255,255,1)',
  secondary: 'rgba(214,150,82,1)',
  secondaryContrastText: 'rgba(255,255,255,1)',
  error: 'rgba(255,58,110,1)',
  warning: 'rgba(255,162,29,1)',
  info: 'rgba(62,201,214,1)',
  success: 'rgba(111,217,67,1)',
  backgroundDefault: 'rgba(250,250,250,1)',
  backgroundPaper: 'rgba(255,255,255,1)',
  textPrimary: 'rgba(41,50,64,1)',
  textSecondary: 'rgba(108,117,125,1)',
  inputBorder: '#005278',
  gradientPrimary: 'linear-gradient(90deg, rgba(0,82,120,1) 23%, rgba(0,152,222,1) 95%)',
  gradientPrimaryHover: 'linear-gradient(90deg, rgba(0,82,120,1) 23%, rgba(0,152,222,1) 95%)',
};

export const spacing = {
  inputPadding: '0px 0px',
  buttonPadding: '5px 20px',
  dialogPadding: '16px',
  inputContainerPadding: '7.5px 12px',
};

export const fontSizes = {
  h1: '30px',
  h2: '24px',
  h3: '20px',
  h4: '16px',
  body1: '16px',
  body2: '14px',
  button: '14px',
  input: '16px',
};

export const borderRadius = {
  input: '12px',
  button: '6px',
  dialog: '24px',
  calendar: '12px',
};

export const borderWidth = {
  input: '1px'
}