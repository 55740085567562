import { useUpdateWorkStatusMutation } from 'app/services/work-status';
import React, { useEffect, useState } from 'react';
import CustomSwitch from 'components/switch/Switch';
import { useSelector } from 'react-redux';
import { selectProfile, setProfileData } from './ProfileSlice';
import { useGetAdminProfileQuery } from 'app/services/profileInfo';
import { useDispatch } from 'react-redux';
import { Box, Tooltip } from '@mui/material';

function WorkStatus() {
  const [isBreakUpdateFinished, setIsBreakUpdateFinished] = useState(true);

  const [updateWorkStatus] = useUpdateWorkStatusMutation();
  const { refetch } = useGetAdminProfileQuery();
  const dispatch = useDispatch();

  const profile = useSelector(selectProfile);

  useEffect(() => {
    return () => {};
  }, [updateWorkStatus, profile?.breakStatus]);

  const breakHandler = async (isChecked: boolean) => {
    setIsBreakUpdateFinished(false);
    const data = await updateWorkStatus({ workStatus: isChecked ? 'BREAK_ON' : 'BREAK_OFF' });
    const profileInfo = await refetch();
    if (profileInfo.data) {
      dispatch(setProfileData(profileInfo.data));
    }
    setIsBreakUpdateFinished(true);
  };

  return (
    <Tooltip title={profile?.breakStatus === 'BREAK_ON' ? 'Break is ON' : 'Break is OFF'}>
      <Box display="flex" alignItems="center">
        <CustomSwitch
          breakHandler={breakHandler}
          isLoading={!isBreakUpdateFinished}
          isChecked={profile?.breakStatus === 'BREAK_ON' ? true : false}
        />
      </Box>
    </Tooltip>
  );
}

export default WorkStatus;
