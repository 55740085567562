import { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import Routers from 'routers';
import Toast from 'components/toast';
import { getFirebaseToken, onMessageListener } from './firebase';
import './index.css';
import {
  useRegisterDeviceMutation,
  useSubscribeToTopicMutation,
  useUnsubscribeFromTopicMutation
} from 'app/services/messaging';
import { getToken, logInCheck } from 'pages/auth/authSlice';
import TimeOutHandler from 'pages/auth/TimeOutHandler';
import { MessagePayload } from 'firebase/messaging';
import { theme } from 'utils/theme';

// const NODE_ENV = process.env.REACT_APP_NODE_ENV;
const baseUrl = process.env.REACT_APP_PUBLIC_URL || '/';

function ToastDisplay({
  notification,
  onDismiss
}: {
  notification?: MessagePayload;
  onDismiss: () => void;
}) {
  const history = useNavigate();

  return (
    <Box
      id="toast-container"
      display="flex"
      gap={1}
      flexGrow={1}
      maxWidth="100%"
      minWidth={300}
      height="54px"
      alignItems="center">
      <Box display="flex" flexGrow={1} justifyContent="flex-start" alignItems="flex-start">
        <Typography
          fontSize="16px"
          fontWeight={400}
          onClick={() => {
            // if (notification?.data?.jobID)
            //   history(`/jobs/${notification?.data?.jobID}`);
            if (notification?.data?.moduleID)
              history(
                `/${notification?.data?.module.toLowerCase()}/${notification?.data?.moduleID}`
              );
            onDismiss();
          }}
          sx={{ cursor: 'pointer' }}>
          {notification?.notification?.body}
        </Typography>
      </Box>
      <Box display="flex" flexGrow={1} justifyContent="flex-end" alignItems="flex-start">
        <IconButton onClick={onDismiss}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

function App() {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [notification, setNotification] = useState<MessagePayload | null>(null);
  // const [showNotificationBanner, setShowNotificationBanner] = useState(
  //   Notification.permission === 'granted'
  // );
  const isLoggedIn = useSelector(logInCheck);
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [subscribeToTopic] = useSubscribeToTopicMutation();
  const [unsubscribeFromTopic] = useUnsubscribeFromTopicMutation();
  const [registerDevice] = useRegisterDeviceMutation();
  const token = useSelector(getToken);

  useEffect(() => {
    if (Notification.permission === 'granted') {
      try {
        getFirebaseToken()
          .then((firebaseToken: any) => {
            if (firebaseToken) {
              if (token) {
                subscribeToTopic({ token: firebaseToken });
                registerDevice(firebaseToken);
                // setShowNotificationBanner(true);
              } else {
                unsubscribeFromTopic({ token: firebaseToken });
                // setShowNotificationBanner(false);
              }
            }
          })
          .catch((err: any) =>
            console.error('An error occured while retrieving firebase token. ', err)
          );
      } catch (err) {
        console.log(err);
      }
    } else {
      Notification.requestPermission()
        .then(() => {})
        .catch((e) => console.log(e));
    }

    return () => {};
  }, [registerDevice, subscribeToTopic, token, unsubscribeFromTopic]);

  useEffect(() => {
    onMessageListener()
      .then((payload: MessagePayload) => {
        setNotification(payload);
      })
      .catch((err) => console.log('failed: ', err));

    return () => {};
  });

  const notify = useCallback(() => {
    if (notification) {
      toast((t) => (
        <ToastDisplay notification={notification} onDismiss={() => toast.dismiss(t.id)} />
      ));
    }
  }, [notification]);

  useEffect(() => {
    if (notification && notification?.messageId && isLoggedIn) {
      notify();
    }
  }, [isLoggedIn, notification, notification?.messageId, notify]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toast />
      <BrowserRouter basename={baseUrl}>
        <Toaster
          position="top-right"
          toastOptions={{
            duration: 2 * 60 * 60 * 1000, // 2 houre then remove
            style: {
              backgroundColor: '#E3FCEF'
            },
            error: {
              style: {
                display: 'flex',
                alignItems: 'center',
                padding: '8px 16px',
                backgroundColor: '#f44336', // Red color for error
                color: '#fff',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                fontSize: '14px',
                fontFamily: 'Arial, sans-serif',
                maxWidth: '300px'
              },
              duration: 2000
            }
          }}
        />
        <Routers />
      </BrowserRouter>
      {/* {!showNotificationBanner ? (
        <Box
          sx={{
            position: 'absolute',
            // bottom: 2,
            right: 0,
            marginLeft: 'auto',
          }}
        >
          <IconButton>
            <CircleNotificationsOutlinedIcon
              fontSize='large'
              color='error'
              sx={{ fontSize: '52px' }}
            />
          </IconButton>
        </Box>
      ) : null} */}
      {isLoggedIn && <TimeOutHandler />}
    </ThemeProvider>
  );
}

export default App;
