import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from '@mui/material/utils/debounce';

const useDebouncedState = () => {
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const debouncedSetValue = useMemo(
    () => debounce((value: string) => setInputValue(value.trim()), 500),
    []
  );

  const handleDebouncedInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      debouncedSetValue(event.target.value);
    },
    [debouncedSetValue]
  );

  return { inputValue, handleDebouncedInputChange };
};

export default useDebouncedState;

export const useDebouncedStateV2 = (initialValue: string = '', delay: number = 300) => {
  const [inputValue, setInputValue] = useState<string>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<string>(initialValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  const handleDebouncedInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  return { inputValue, debouncedValue, handleDebouncedInputChange };
};
