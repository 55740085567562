import React from 'react';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';

type QualifiedLeadActionsProps = {
  isCreatingCustomer: boolean;
  isCreatingJob: boolean;
  onCreateCustomerClick: () => void;
  onCreateJobClick: () => void;
};

export const QualifiedLeadActions: React.FC<QualifiedLeadActionsProps> = ({
  isCreatingCustomer,
  isCreatingJob,
  onCreateCustomerClick,
  onCreateJobClick
}) => {
  return (
    <Box
      sx={{
        mt: 4,
        p: 2,
        backgroundColor: '#e3f2fd',
        borderRadius: 2,
        boxShadow: 1
      }}>
      <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
        Qualified Lead Actions
      </Typography>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          color="success"
          onClick={onCreateCustomerClick}
          disabled={isCreatingCustomer}>
          {isCreatingCustomer ? <CircularProgress size={24} /> : 'Create Customer Account'}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={onCreateJobClick}
          disabled={isCreatingJob}>
          {isCreatingJob ? <CircularProgress size={24} /> : 'Create Job'}
        </Button>
      </Stack>
    </Box>
  );
};
