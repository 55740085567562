import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, MenuItem, CircularProgress } from '@mui/material';
import { Controller, Control, FieldError } from 'react-hook-form';
import useStaffList from 'hooks/useStaffList';

// Define the expected type for an agent
interface Agent {
  id: number;
  name: string;
}

interface AgentSelectProps {
  control?: Control<any>;
  name?: string;
  label?: string;
  value?: number | Agent | null;
  onChange?: (selected: Agent | null) => void;
  required?: boolean;
  error?: FieldError | string;
  disabled?: boolean; // Add this line
}

const AgentSelect: React.FC<AgentSelectProps> = ({
  control,
  name,
  label = 'Agent',
  value = null,
  onChange,
  required = false,
  error,
  disabled
}) => {
  // API call to fetch the staff list
  const { staffList, staffListLoading } = useStaffList();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedValue, setSelectedValue] = useState<Agent | null>(null);

  // Format the staff list to match the Agent interface
  const formattedAgents: Agent[] = staffList.map((staff: any) => ({
    id: staff.adminID,
    name: `${staff.firstName} ${staff.lastName}`.trim()
  }));

  // Update the local selected value when the incoming value changes
  useEffect(() => {
    if (typeof value === 'number') {
      const matchedAgent = formattedAgents.find((agent) => agent.id === value) || null;
      setSelectedValue(matchedAgent);
    } else {
      setSelectedValue(value as Agent);
    }
  }, [value, formattedAgents]);

  // Handle the change event from the Autocomplete
  const handleChange = (_event: any, newValue: Agent | null) => {
    setSelectedValue(newValue);
    if (onChange) onChange(newValue);
  };

  // Show a loading spinner if the staff list is loading
  if (staffListLoading) {
    return (
      <TextField
        fullWidth
        label={label}
        InputProps={{
          endAdornment: <CircularProgress size={20} />
        }}
        disabled
      />
    );
  }

  // Render using react-hook-form Controller if control and name are provided
  return control && name ? (
    <Controller
      name={name}
      control={control}
      rules={{ required: required ? `${label} is required` : false }}
      render={({ field }) => {
        // Convert the stored ID into an Agent object
        const selected = formattedAgents.find((agent) => agent.id === field.value) || null;
        return (
          <Autocomplete
            options={formattedAgents}
            fullWidth
            disabled={disabled} // Add this here
            value={selected}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onInputChange={(_, value) => setSearchTerm(value)}
            onChange={(_, newValue) => {
              field.onChange(newValue?.id ?? null); // Store only the agent ID
              handleChange(_, newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                disabled={disabled} // And also pass disabled here
                error={!!error}
                helperText={typeof error === 'string' ? error : error?.message}
                margin="normal"
                fullWidth
              />
            )}
            renderOption={(props, option) => (
              <MenuItem {...props} key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            )}
          />
        );
      }}
    />
  ) : (
    <Autocomplete
      options={formattedAgents}
      value={selectedValue}
      fullWidth
      disabled={disabled} // Pass the prop here
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={(_, value) => setSearchTerm(value)}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={!!error}
          helperText={typeof error === 'string' ? error : error?.message}
          fullWidth
          disabled={disabled} // And here as well
        />
      )}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      )}
    />
  );
};

export default AgentSelect;
