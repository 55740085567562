import React, { createContext, useContext, useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { useSelector } from 'react-redux';
import { logInCheck } from 'pages/auth/authSlice';

interface SocketContextType {
  registerJobEventCallback: (callback: (data: any) => void) => void;
}

const SocketContext = createContext<SocketContextType>({
  registerJobEventCallback: () => {}
});

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const socketRef = useRef<Socket | null>(null);
  const jobEventCallbackRef = useRef<(data: any) => void>();
  const isLoggedIn = useSelector(logInCheck); // Get login state from Redux

  useEffect(() => {
    if (isLoggedIn && !socketRef.current) {
      console.log('User is logged in, connecting socket...');
      const token = localStorage.getItem('accessToken');
      if (token) {
        const socket = io(process.env.REACT_APP_BASE_URL, {
          extraHeaders: {
            Authorization: `Bearer ${token}`
          }
        });

        socketRef.current = socket;

        socket.on('connect', () => {
          console.log('Socket connected');
        });

        socket.on('disconnect', () => {
          console.log('Socket disconnected');
        });

        socket.on('admin-job-event', (data) => {
          if (jobEventCallbackRef.current) {
            jobEventCallbackRef.current(data);
          }
        });
      }
    } else if (!isLoggedIn && socketRef.current) {
      console.log('User is logged out, disconnecting socket...');
      socketRef.current.disconnect();
      socketRef.current = null;
    }

    return () => {
      // Cleanup on unmount
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [isLoggedIn]); // Re-run when isLoggedIn changes

  const registerJobEventCallback = (callback: (data: any) => void) => {
    jobEventCallbackRef.current = callback;
  };

  return (
    <SocketContext.Provider value={{ registerJobEventCallback }}>{children}</SocketContext.Provider>
  );
};
