import React from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot
} from '@mui/lab';
import { Chip } from '@mui/material';

type Remark = {
  activityID: number;
  agentID: number;
  action?: string;
  notes: string;
  activityDate: string;
};

type ActivitiesSectionProps = {
  remarks: Remark[] | null | undefined;
  formatDate: (dateStr: string) => string;
};

const sectionStyle = {
  mb: 3,
  p: 3,
  backgroundColor: '#ffffff',
  borderRadius: 2,
  boxShadow: 1
};

export const ActivitiesSection: React.FC<ActivitiesSectionProps> = ({
  remarks,
  formatDate
}) => {
  return (
    <Box sx={sectionStyle}>
      {remarks && remarks.length > 0 ? (
        <Timeline position="alternate">
          {remarks.map((remark, index) => (
            <TimelineItem key={remark.activityID}>
              <TimelineOppositeContent
                sx={{
                  m: 'auto 0',
                  flex: 0.3,
                  textAlign: 'right'
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  {formatDate(remark.activityDate)}
                </Typography>
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineDot
                  color={remark.action === 'Important' ? 'secondary' : 'primary'}
                  variant={remark.action === 'Important' ? 'filled' : 'outlined'}
                />
                {index !== remarks.length - 1 && <TimelineConnector />}
              </TimelineSeparator>

              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 2,
                    borderLeft: `4px solid ${
                      remark.action === 'Important' ? '#f50057' : '#1976d2'
                    }`,
                    backgroundColor: '#fdfdfd',
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: 6
                    }
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                      Agent ID: {remark.agentID}
                    </Typography>
                    {remark.action && (
                      <Chip
                        label={remark.action}
                        color={remark.action === 'Important' ? 'error' : 'info'}
                        size="small"
                      />
                    )}
                  </Stack>
                  <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                    {remark.notes}
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      ) : (
        <Typography variant="body2" color="text.secondary">
          No remarks yet.
        </Typography>
      )}
    </Box>
  );
};