import React from 'react';
import { Droppable } from '@hello-pangea/dnd';
import { List } from '@mui/material';
import TeamAccordion from './TeamAccordion';

const TeamList = ({ teams, allowActions, onUserMoved, onDeleteTeam, handleUpdateTeamName }: any) => {
  return (
    <Droppable droppableId="teams" type="team">
      {(provided) => (
        <List {...provided.droppableProps} ref={provided.innerRef}>
          {teams.map((team: any, index: any) => (
            <TeamAccordion
              key={team.id}
              team={team}
              index={index}
              allowActions={allowActions}
              onUserMoved={onUserMoved}
              onDeleteTeam={onDeleteTeam}
              handleUpdateTeamName={handleUpdateTeamName}
            />
          ))}
          {provided.placeholder}
        </List>
      )}
    </Droppable>
  );
};

export default TeamList;
