import { api } from './api';

interface UploadUrlResponse {
  url: string;
  fileKey: string;
}

export const filesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUploadUrl: builder.query({
      query: (params) => {
        const { fileExt } = params;
        let queryUrl = `/storage/upload-url`;
        if (typeof fileExt === 'string') {
          queryUrl += `?fileExt=${fileExt}`;
        }
        return queryUrl;
      },
      transformResponse: (response: UploadUrlResponse) => response
    }),
    getDownloadUrl: builder.query({
      query: ({ fileKey }: { fileKey: string }) => ({
        url: `/storage/download-url/${fileKey}`,
        responseHandler: (response) => response.text()
      })
      // transformResponse: (response: string, meta, arg) => response,
    }),
    createTechniciansFromExcel: builder.mutation({
      query(body) {
        return {
          url: `/admin/technician/upload`,
          method: 'POST',
          body
        };
      }
    }),
    configUpload: builder.mutation({
      query(body) {
        return {
          url: `/admin/autoaid-config/upload`,
          method: 'POST',
          // body,
          body: { data: body },
          formData: true,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };
      }
    }),
    uploadAuditJobs: builder.mutation({
      query(body) {
        return {
          url: `/admin/job/upload-audit-sheet`,
          method: 'POST',
          body
        };
      }
    })
  })
});

export const {
  useLazyGetDownloadUrlQuery,
  useLazyGetUploadUrlQuery,
  useConfigUploadMutation,
  useCreateTechniciansFromExcelMutation,
  useUploadAuditJobsMutation
} = filesApi;
