// motionConfig.js
export const pageVariants = {
  initial: { opacity: 0, x: 50 }, // Start fully transparent, slide in from the right
  animate: { opacity: 1, x: 0 },  // Fade in and position in place
  exit: { opacity: 0, x: -50 },   // Fade out and slide to the left
};

export const pageTransition = {
  duration: 0.6, // Adjust the duration for a smoother effect
  ease: 'easeInOut', // Smooth ease-in-out transition
};