import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import useServiceCategoryList from 'hooks/useServiceCategoryList';

const customStyles = () => {
  let isActive = false;
  return {
    input: (provided: any, state: any) => ({
      ...provided,
      color: 'white',
      height: '100%',
      lineHeight: '1rem',
      fontsize: '16px',
      fontWeight: '500'
    }),
    control: (provided: any, state: any) => {
      isActive = state.isFocused;
      return {
        ...provided,
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: state.isFocused
          ? 'linear-gradient(90deg, rgba(0,82,120,1) 23%, rgba(0,152,222,1) 95%)' // Gradient background on focus
          : 'white',
        border: '1px solid black',
        borderRadius: '13px',
        padding: '3px 10px',
        cursor: 'pointer',
        position: 'relative',
        boxShadow: state.isFocused
          ? '0 0 0 3px rgba(0, 0, 0, 0.2)' // Focus box-shadow
          : 'none',
        ':before': {
          content: '""',
          display: state.isFocused ? 'none' : 'block',
          position: 'absolute',
          top: '-2px',
          left: '-2px',
          right: '-2px',
          bottom: '-2px',
          zIndex: -1,
          borderRadius: '13px',
          padding: '1px', // Inner padding for the gradient border
          WebkitMaskComposite: 'destination-out',
          maskComposite: 'exclude'
        }
      };
    },
    menu: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      zIndex: 999
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: 0,
      fontsize: '16px',
      fontWeight: '500',
      color: 'white'
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: 'transparent',
      color: state.selectProps?.menuIsOpen || isActive ? 'white !important' : 'black !important',
      lineHeight: '1rem'
    }),
    multiValue: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: 'transparent',
      color: state.selectProps?.menuIsOpen || isActive ? 'white !important' : 'black !important',
      lineHeight: '10px'
    }),
    multiValueLabel: (provided: any, state: any) => {
      return {
        ...provided,
        color: state.selectProps?.menuIsOpen || isActive ? 'white !important' : 'black !important'
      };
    },
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: state.isFocused ? 'white' : 'black',
      padding: 0,
      '&:hover': { color: state.isFocused ? 'white' : 'black' }
    }),
    indicatorSeparator: () => ({
      display: 'none' // Remove the separator
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: state.isFocused ? 'white' : 'rgba(41, 50, 64, 0.45)',
      fontSize: 16,
      fontWeight: 500
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      width: '100%'
    }),
    clearIndicator: (provided: any, state: any) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    })
  };
};

interface ServiceCategoryThemedSelectProps {
  value?: { value: number; label: string } | { value: number; label: string }[] | null;
  onChange?: (
    selected: { value: number; label: string } | { value: number; label: string }[] | null
  ) => void;
  placeholder?: string;
  isClearable?: boolean;
  isMulti?: boolean;
  handleClear?: () => void;
}

const ServiceCategoryThemedSelect: React.FC<ServiceCategoryThemedSelectProps> = ({
  value = null,
  onChange,
  placeholder = 'Select Service Category',
  isClearable = true,
  isMulti = false
}) => {
  const { serviceCategoryList, serviceCategoryLoading } = useServiceCategoryList();
  const [defaultOptions, setDefaultOptions] = useState<{ value: number; label: string }[]>([]);

  useEffect(() => {
    if (Array.isArray(serviceCategoryList)) {
      setDefaultOptions(
        serviceCategoryList.map((cat) => ({
          value: cat.serviceCategoryID,
          label: cat.serviceCategoryName
        }))
      );
    }
  }, [serviceCategoryList]);

  const loadOptions = async (inputValue: string) => {
    return defaultOptions.filter((cat) =>
      cat.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      isLoading={serviceCategoryLoading}
      isMulti={isMulti}
      value={value}
      onChange={(selected) => {
        console.log('Selected Value:', selected);
        //@ts-ignore
        onChange?.(selected);
      }}
      placeholder={placeholder}
      isClearable={isClearable}
      styles={customStyles()}
    />
  );
};

export default ServiceCategoryThemedSelect;
