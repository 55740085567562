import { DEFAULT_LIMIT } from 'utils/constants';
import { api } from './api';

export interface CreateVehicleDTO {
  vehicleName: string;
  vehicleBrandID: number;
}

export interface UpdateVehicleDTO {
  // "vehicleName": "1 Series",
  // "vehicleEnabled": "N"
  vehicleID: string;
  vehicleName: string;
  vehicleBrandID: number;
}

export const clientApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listClients: builder.query({
      query: (params) => {
        const {
          limit = DEFAULT_LIMIT,
          offset = 0,
          clientType,
          createdBetween,
          search,
          isEnabled,
          createdBy,
          updatedBy
        } = params;

        let queryUrl = `/client?limit=${limit}&offset=${offset}`;

        if (typeof search === 'string') {
          queryUrl += `&search=${search}`;
        }

        if (clientType) {
          queryUrl += `&clientType=${clientType.value}`;
        }

        if (createdBetween && createdBetween.length) {
          queryUrl += `&createdBetween=${JSON.stringify(createdBetween)}`;
        }

        // if (district && district.length) {
        //   let dis = district.map((d: any) => d.value).join(',');
        //   queryUrl += `&district=${dis}`;
        // }

        // if (state && state.length) {
        //   let st = state.map((s: any) => s.value).join(',');
        //   queryUrl += `&state=${st}`;
        // }

        if (isEnabled) {
          queryUrl += `&isEnabled=${isEnabled.value}`;
        }

        if (createdBy && createdBy.length) {
          let cb = createdBy.map((c: any) => c.value).join(',');
          queryUrl += `&createdBy=${cb}`;
        }

        if (updatedBy && updatedBy.length) {
          let ub = updatedBy.map((u: any) => u.value).join(',');
          queryUrl += `&updatedBy=${ub}`;
        }

        return queryUrl;
      },
      transformResponse: (res: any) => {
        return { ...res };
      },
      providesTags: ['Client']
    }),
    createVehicle: builder.mutation<CreateVehicleDTO, Partial<CreateVehicleDTO>>({
      query(body) {
        return {
          url: `/vehicle`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['Vehicle']
    }),
    updateVehicle: builder.mutation<UpdateVehicleDTO, Partial<UpdateVehicleDTO>>({
      query(body) {
        return {
          url: `/vehicle/${body.vehicleID}`,
          method: 'PUT',
          body
        };
      },
      // invalidatesTags: (garage) => {
      //   // garage = { success: true, message: 'Updated successfully' };
      //   return [{ type: 'Garage', id: garage?.vehicleID }];
      // },
      invalidatesTags: ['Vehicle']
    })
  })
});

export const {
  useListClientsQuery,
  useLazyListClientsQuery,
  useCreateVehicleMutation,
  useUpdateVehicleMutation
} = clientApi;
