import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import { Box, Tooltip } from '@mui/material';
import { Props } from 'react-select';

interface BasicSwitchProps {
  breakHandler: (isChecked: boolean) => void;
  isLoading?: boolean;
  isChecked?: boolean;
}

const BasicSwitch: React.FC<BasicSwitchProps> = ({ breakHandler, isLoading, isChecked }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    breakHandler(event.target.checked);
  };

  const label = { inputProps: { 'aria-label': 'Basic switch' } };

  return (
    <Switch
      {...label}
      checked={isChecked} // Controlled state
      onChange={handleChange} // Change handler
      disabled={isLoading}
    />
  );
};

export default BasicSwitch;
