import React from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { List, ListItem, Avatar, ListItemText, Box, Typography } from '@mui/material';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

const UserList = ({
  team,
  allowActions,
  onUserMoved
}: {
  team: any;
  allowActions: any;
  onUserMoved: any;
}) => {
  return (
    <Droppable droppableId={team.id} type="user">
      {(provided) => (
        <List ref={provided.innerRef} {...provided.droppableProps} dense sx={{ minHeight: 60 }}>
          {team.users.length > 0 ? (
            team.users
              .map((user: any, index: any) => (
                <Draggable
                  key={user.adminID}
                  draggableId={user.adminID}
                  index={index}
                  //@ts-ignore
                  type="user"
                  isDragDisabled={!allowActions}>
                  {(provided) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        border: '1px solid',
                        borderRadius: 1,
                        mb: 1,
                        bgcolor: 'background.paper',
                        cursor: allowActions ? 'grab' : 'not-allowed',
                        opacity: allowActions ? 1 : 0.5
                      }}>
                      <Avatar sx={{ width: 24, height: 24, mr: 2 }}>{user.adminName[0]}</Avatar>
                      <ListItemText primary={user.adminName} />
                    </ListItem>
                  )}
                </Draggable>
              ))
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
                py: 2,
                color: 'text.secondary'
              }}>
              <PeopleOutlineIcon fontSize="large" />
              <Typography variant="body2">No users in this team</Typography>
            </Box>
          )}
          {provided.placeholder}
        </List>
      )}
    </Droppable>
  );
};

export default UserList;
