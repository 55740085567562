import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface SubscriptionPlanListItemDto {
  planID: number;
  planName: string;
  planDescription: string;
  planPrice: number;
  planDuration: number;
  durationUnit: 'DAYS' | 'MONTHS' | 'YEARS';
  eligibility: string;
  order: number;
  notesDisclaimer: string;
  versionNumber: number;
  isActive: 'Y' | 'N';
  isDeleted: 'Y' | 'N';
  createdBy: string;
  createdDate: Date;
  updatedBy?: string;
  updatedDate?: Date;
}

export type SubscriptionPlanListDto = {
  total: number | null;
  items: SubscriptionPlanListItemDto[];
};
const initialState: SubscriptionPlanListDto = { total: null, items: [] };

const subscriptionPlanSlice = createSlice({
  name: 'subscriptionPlans',
  initialState,
  reducers: {
    setSubscriptionPlanData: (
      state,
      action: PayloadAction<{
        total: number;
        items: SubscriptionPlanListItemDto[];
      }>
    ) => {
      const { total, items } = action.payload;
      state.total = total;
      state.items = items;
    }
  }
});

export const { setSubscriptionPlanData } = subscriptionPlanSlice.actions;

export const selectAllSubscriptionPlan = (state: RootState) => state.subscriptionPlan.items;

export default subscriptionPlanSlice.reducer;
