import React, { useRef } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { AddCircleOutline, DeleteOutline } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill CSS
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useServiceCategoryList from 'hooks/useServiceCategoryList';
import { useCreateSubscriptionPlanMutation } from 'app/services/subscriptionPlans';
import { useAppDispatch } from 'app/store';
import { openToastMessage } from 'app/slices/toastMessageSlice';
import SelectServiceCategory from 'components/forms/ServiceCategorySelect';

const schema = Yup.object().shape({
  planName: Yup.string().required('Plan Name is required'),
  planDescription: Yup.string().required('Plan Description is required'),
  planPrice: Yup.number()
    .required('Plan Price is required')
    .typeError('Enter a valid number')
    .min(1, 'Plan Price must be greater than 0'),
  planDuration: Yup.number()
    .required('Plan Duration is required')
    .typeError('Enter a valid number')
    .min(1, 'Plan Duration must be greater than 0'),
  durationUnit: Yup.string().required('Select a duration unit'),
  eligibility: Yup.string().optional(),
  order: Yup.number().required('Order is required').typeError('Enter a valid number'),
  notesDisclaimer: Yup.string().optional(),
  items: Yup.array()
    .of(
      Yup.object()
        .shape({
          category: Yup.number().nullable(),
          title: Yup.string().nullable(),
          usageLimit: Yup.number()
            .required('Usage is required')
            .typeError('Enter a valid number')
            .min(1, 'Usage must be greater than 0'),
          distanceLimit: Yup.number()
            .required('Distance is required')
            .typeError('Enter a valid number')
            .min(1, 'Distance must be greater than 0')
        })
        .test(
          'category-or-title',
          'An item must have either a Category or a Title, but not both',
          (item: any) => (item.category && !item.title) || (!item.category && item.title)
        )
    )
    .min(1, 'At least one item is required')
    .test('unique-category', 'Duplicate categories are not allowed', (items) => {
      const categories: any = items?.map((item: any) => item.category).filter(Boolean);
      return new Set(categories).size === categories.length;
    })
    .test('unique-title', 'Duplicate titles are not allowed', (items) => {
      const titles: any = items?.map((item: any) => item.title).filter(Boolean);
      return new Set(titles).size === titles.length;
    })
});

interface SubscriptionPlanFormProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateSubscriptionPlanModal: React.FC<SubscriptionPlanFormProps> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      planName: '',
      planDescription: '',
      planPrice: 1,
      planDuration: 1,
      durationUnit: 'DAYS',
      eligibility: '',
      order: 1,
      notesDisclaimer: '',
      items: [{ category: null, usageLimit: 1, distanceLimit: 0, title: '' }]
    }
  });

  const { fields, append, remove } = useFieldArray({ control, name: 'items' });

  // Fetch service categories
  const { serviceCategoryList, serviceCategoryLoading } = useServiceCategoryList();

  const [createSubscriptionPlan, { isLoading }] = useCreateSubscriptionPlanMutation();

  const onSubmit = async (data: any) => {
    try {
      await createSubscriptionPlan(data).unwrap();
      dispatch(
        openToastMessage({
          type: 'success',
          message: data?.message ?? 'Subscription Plan Created Successfully'
        })
      );
      reset();
      onClose(); // Close modal on success
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="create-plan-modal" maxWidth="md">
      <DialogTitle variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
        Create Subscription Plan
      </DialogTitle>
      <DialogContent>
        <form autoComplete="off" style={{ width: '100%', padding: '15px 0' }}>
          <Grid container spacing={2}>
            {/* Plan Details */}
            <Grid item xs={6}>
              <Controller
                name="planName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Plan Name"
                    fullWidth
                    error={!!errors.planName}
                    helperText={errors.planName?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="planPrice"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Plan Price"
                    type="number"
                    fullWidth
                    error={!!errors.planPrice}
                    helperText={errors.planPrice?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="planDuration"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Plan Duration"
                    type="number"
                    fullWidth
                    error={!!errors.planDuration}
                    helperText={errors.planDuration?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="durationUnit"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Duration Unit"
                    select
                    fullWidth
                    error={!!errors.durationUnit}
                    helperText={errors.durationUnit?.message}>
                    <MenuItem value="DAYS">Days</MenuItem>
                    <MenuItem value="MONTHS">Months</MenuItem>
                    <MenuItem value="YEARS">Years</MenuItem>
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="planDescription"
                control={control}
                render={({ field }) => (
                  <Box>
                    <Typography variant="subtitle1" mb={1}>
                      Plan Description
                    </Typography>
                    <ReactQuill
                      {...field}
                      onChange={field.onChange}
                      value={field.value}
                      theme="snow"
                    />
                    {errors.planDescription && (
                      <Typography variant="body2" color="error">
                        {errors.planDescription.message}
                      </Typography>
                    )}
                  </Box>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="eligibility"
                control={control}
                render={({ field }) => (
                  <Box>
                    <Typography variant="subtitle1" mb={1}>
                      Eligibility
                    </Typography>
                    <ReactQuill
                      {...field}
                      onChange={field.onChange}
                      value={field.value}
                      theme="snow"
                    />
                    {errors.eligibility && (
                      <Typography variant="body2" color="error">
                        {errors.eligibility.message}
                      </Typography>
                    )}
                  </Box>
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="order"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Order"
                    type="number"
                    fullWidth
                    error={!!errors.order}
                    helperText={errors.order?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
              <Divider textAlign="left" sx={{ flexGrow: 1 }}>
                Plan Items
              </Divider>
              <IconButton
                onClick={() =>
                  append({ category: null, usageLimit: 1, distanceLimit: 0, title: '' })
                }
                color="primary"
                sx={{ ml: 1 }}>
                <AddCircleOutline />
              </IconButton>
            </Grid>

            {fields.map((item, index) => (
              <Grid container spacing={2} key={item.id} alignItems="center" mb={1}>
                {/* Category and Title in the same row */}
                <Grid item xs={4}>
                  {/* <Controller
                    name={`items.${index}.category`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Category"
                        select
                        fullWidth
                        error={!!errors?.items?.[index]?.category}
                        helperText={errors?.items?.[index]?.category?.message}
                        disabled={serviceCategoryLoading}>
                        {serviceCategoryLoading ? (
                          <MenuItem disabled>
                            <CircularProgress size={20} />
                          </MenuItem>
                        ) : (
                          serviceCategoryList?.map((option: any) => (
                            <MenuItem
                              key={option.serviceCategoryID}
                              value={option.serviceCategoryID}>
                              {option.serviceCategoryName}
                            </MenuItem>
                          ))
                        )}
                      </TextField>
                    )}
                  /> */}
                  <SelectServiceCategory
                    control={control} // React Hook Form control
                    name={`items.${index}.category`} // Dynamic field name
                    label="Category"
                    required
                    error={errors?.items?.[index]?.category}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Controller
                    name={`items.${index}.title`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Title"
                        fullWidth
                        error={!!errors?.items?.[index]?.title}
                        helperText={errors?.items?.[index]?.title?.message}
                      />
                    )}
                  />
                </Grid>

                {/* Usage and Distance Fields */}
                <Grid item xs={1.5}>
                  <Controller
                    name={`items.${index}.usageLimit`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Usage"
                        type="number"
                        fullWidth
                        error={!!errors?.items?.[index]?.usageLimit}
                        helperText={errors?.items?.[index]?.usageLimit?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={1.5}>
                  <Controller
                    name={`items.${index}.distanceLimit`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Distance"
                        type="number"
                        fullWidth
                        error={!!errors?.items?.[index]?.distanceLimit}
                        helperText={errors?.items?.[index]?.distanceLimit?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={1}>
                  <IconButton onClick={() => remove(index)}>
                    <DeleteOutline />
                  </IconButton>
                </Grid>
              </Grid>
            ))}

            <Grid item xs={12}>
              {errors?.items?.root?.message && (
                <Typography variant="body2" color="error">
                  {errors.items.root.message}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              {errors?.items?.length && (
                <Typography variant="body2" color="error">
                  {errors?.items?.[0]?.message
                    ? errors?.items?.[0]?.message
                    : errors?.items?.[1]?.message
                    ? errors?.items?.[1]?.message
                    : ''}
                </Typography>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} display="flex" justifyContent="flex-end" gap={2}>
            <Button variant="text" onClick={onClose} disabled={isLoading}>
              Cancel
            </Button>
            <Button variant="contained" disabled={isLoading} onClick={handleSubmit(onSubmit)}>
              {isLoading ? 'Creating...' : 'Create Plan'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSubscriptionPlanModal;
