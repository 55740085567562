import { DEFAULT_LIMIT } from 'utils/constants';
import { api } from './api';

export const locationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listStates: builder.query({
      query: ({ limit = DEFAULT_LIMIT, offset = 0 }) =>
        `/states?limit=${limit}&offset=${offset}`,
      transformResponse: (res: any) => {
        let items = res?.items?.map((item: any) => {
          return { ...item, id: item['stateID'] };
        });
        return { ...res, items };
      },
      providesTags: (result = {}) => {
        const items =
          result?.items?.map(
            ({ id }: { id: string }) => ({ type: 'Location', id } as const)
          ) ?? [];
        return [...items, { type: 'Location' as const, id: 'LIST' }];
      },
    }),
    listDistrict: builder.query({
      query: (params) => {
        const { limit = DEFAULT_LIMIT, offset = 0, stateID } = params;
        let queryUrl = `/district?limit=${limit}&offset=${offset}`;

        if (typeof stateID === 'number') {
          queryUrl += `&stateID=${stateID}`;
        }

        return queryUrl;
      },
      transformResponse: (res: any) => {
        let items = res?.items?.map((item: any) => {
          return { ...item, id: item['districtID'] };
        });
        return { ...res, items };
      },
      providesTags: (result = {}) => {
        const items =
          result?.items?.map(
            ({ id }: { id: string }) => ({ type: 'Location', id } as const)
          ) ?? [];
        return [...items, { type: 'Location' as const, id: 'LIST' }];
      },
    }),
  }),
});

export const {
  useListStatesQuery,
  useListDistrictQuery,
  useLazyListDistrictQuery,
} = locationApi;
