import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Tooltip, CircularProgress } from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import useThemedFilter, { ThemedFiltersModal } from 'hooks/useThemedFilter';
import CrmLeadsHeader from './components/CrmLeadsHeader';
import { useListCrmLeadsQuery, useSelfAssignMutation } from 'app/services/crm';
import { CRM_LEAD_STATUS } from 'utils/constants';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ability from 'common/casl/ability';
import { openToastMessage } from 'app/slices/toastMessageSlice';
import { pageTransition, pageVariants } from 'utils/motionConfig';

const statusOptions = Object.values(CRM_LEAD_STATUS).map((status) => ({
  label: status,
  value: status
}));

const defaultFilterTemplate: any = [
  {
    type: 'date-range',
    label: 'Created Date',
    fieldKey: 'createdDate',
    whereOption: 'between'
  },
  {
    type: 'text',
    label: 'Mobile Number',
    fieldKey: 'mobileNumber',
    whereOption: 'eq'
  },
  {
    type: 'text',
    label: 'Name',
    fieldKey: 'name',
    whereOption: 'eq'
  },
  {
    type: 'text',
    label: 'Case Description',
    fieldKey: 'description',
    whereOption: 'eq'
  },
  {
    type: 'select',
    label: 'Status',
    fieldKey: 'status',
    options: statusOptions,
    whereOption: 'eq'
  },
  {
    type: 'select',
    label: 'Category',
    fieldKey: 'category',
    options: [
      { label: 'Service Request', value: 'Service Request' },
      { label: 'Subscription', value: 'Subscription' },
      { label: 'Enquiry', value: 'Enquiry' }
    ],
    whereOption: 'eq'
  }
];

export default function CrmLeads() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: limit
  });
  const [filterTemplate, setFilterTemplate] = useState<any>(defaultFilterTemplate);
  const updatePermission = ability.can('UPDATE', 'CRM');

  // State to track which leads are being self-assigned
  const [assigningLeads, setAssigningLeads] = useState<Set<number>>(new Set());

  const {
    openFilterModal,
    closeFilterModal,
    filtersObj,
    modalOpen,
    setFiltersObj,
    handleFilterClear,
    applyFilters,
    clearFilters,
    filtersQuery,
    appliedFilters,
    RenderChipsView
  } = useThemedFilter({
    filterTemplate,
    clearParentFilters: () => {
      refetch();
    }
  });

  const { data, isLoading, refetch, isFetching } = useListCrmLeadsQuery({
    limit,
    offset,
    ...appliedFilters
  });

  const [selfAssign, { isLoading: isAssigning }] = useSelfAssignMutation();

  useEffect(() => {
    refetch();
  }, [filtersQuery, refetch]);

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model);
    setOffset(model.page * model.pageSize);
    setLimit(model.pageSize);
  };

  useEffect(() => {
    refetch();
  }, [paginationModel.page, paginationModel.pageSize, refetch]);

  const handleViewClick = (id: any) => {
    navigate(`/crm/leads/${id}`);
  };

  const handleSelfAssign = async (id: number) => {
    if (assigningLeads.has(id)) return;

    try {
      setAssigningLeads((prev) => new Set(prev).add(id));

      await selfAssign({ leadID: id }).unwrap();

      dispatch(
        openToastMessage({
          type: 'success',
          message: 'Lead successfully self-assigned.'
        })
      );

      refetch();
    } catch (error: any) {
      dispatch(
        openToastMessage({
          type: 'error',
          message: error?.data?.message || 'Failed to self-assign lead.'
        })
      );
    } finally {
      setAssigningLeads((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Lead ID',
      flex: 0.5,
      minWidth: 80,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'mobileNumber',
      headerName: 'Mobile Number',
      flex: 1,
      minWidth: 150,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'caseDescription',
      headerName: 'Case Description',
      flex: 2,
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 1,
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      flex: 1,
      minWidth: 150,
      valueFormatter: ({ value }) => (value ? moment(value).format('DD-MMM-YYYY') : ''),
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'updatedDate',
      headerName: 'Updated Date',
      flex: 1,
      minWidth: 150,
      valueFormatter: ({ value }) => (value ? moment(value).format('DD-MMM-YYYY') : ''),
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'updatedByAgentName',
      headerName: 'Updated By',
      flex: 1,
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: ({ value }) => (value ? value : '-')
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 120,
      align: 'center',
      renderCell: (params) => {
        const isAssigning = assigningLeads.has(params.row.id);
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            {updatePermission && !params?.row?.assignedAgentID && (
              <Tooltip title="Self-Assign Lead">
                <span>
                  {/* The span wrapper is needed to handle disabled tooltip */}
                  <IconButton
                    onClick={() => handleSelfAssign(params.row.id)}
                    disabled={isAssigning}
                    size="small">
                    {isAssigning ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Icon icon="oi:action-undo" width="1.3rem" height="1.3rem" />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
            )}
            <Tooltip title="View Lead Details">
              <IconButton onClick={() => handleViewClick(params.row.id)} size="small">
                <VisibilityOutlinedIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
      sortable: false,
      disableColumnMenu: true
    }
  ];

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageVariants}
      transition={pageTransition}
      key={'crm-leads-home-screen-animation'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CrmLeadsHeader openFilterModal={openFilterModal} />
        </Grid>
        <Grid
          item
          xs={12}
          padding={0}
          p={0}
          sx={{ padding: 0, paddingTop: '0 !important', paddingLeft: '0 !important' }}>
          <RenderChipsView />
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            pagination
            paginationMode="server"
            pageSizeOptions={[5, 10, 20, 50]}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            rows={data?.items || []}
            rowCount={data?.total || 0}
            loading={isLoading || isFetching}
            columns={columns}
            getRowId={(row) => row.id}
            sx={{
              border: 'none',
              '& .MuiDataGrid-cell': { borderBottom: 'none' },
              '& .MuiDataGrid-row:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
              '& .MuiDataGrid-row:nth-of-type(even)': { backgroundColor: '#ffffff' },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#E0E9ED',
                color: '#333333',
                fontWeight: '600 !important',
                textAlign: 'center',
                borderBottom: '2px solid #e0e0e0'
              },
              '& .MuiDataGrid-columnHeader': { fontWeight: 'bold', padding: '0 8px' },
              '& .MuiDataGrid-cellContent': { textAlign: 'center' }
            }}
            localeText={{
              noRowsLabel: Object.keys(appliedFilters)?.length
                ? 'No leads match your search criteria.'
                : 'No leads found'
            }}
          />
        </Grid>
        <ThemedFiltersModal
          filtersObj={filtersObj}
          setFiltersObj={setFiltersObj}
          modalOpen={modalOpen}
          closeFilterModal={closeFilterModal}
          filterTemplate={filterTemplate}
          handleFilterClear={handleFilterClear}
          applyFilters={applyFilters}
          clearFilters={clearFilters}
        />
      </Grid>
    </motion.div>
  );
}
