import React from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { Box, Paper, Avatar, Typography } from '@mui/material';

const UnassignedUsersList = ({ unassignedUsers, allowActions, onUserMoved }: any) => {
  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Unassigned Users
      </Typography>
      <Droppable droppableId="unassigned" direction="horizontal" type="user">
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              display: 'flex',
              gap: 2,
              overflowX: 'auto',
              py: 2,
              mb: 3,
              border: '1px dashed',
              borderRadius: 1,
              minHeight: 80,
              alignItems: 'center',
              px: 2
            }}>
            {unassignedUsers.map((user: any, index: any) => (
              <Draggable
                key={user.adminID}
                draggableId={user.adminID}
                index={index}
                //@ts-ignore
                type="user"
                isDragDisabled={!allowActions}>
                {(provided) => (
                  <Paper
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={{
                      p: 1,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      minWidth: 120,
                      cursor: allowActions ? 'grab' : 'not-allowed',
                      opacity: allowActions ? 1 : 0.5
                    }}>
                    <Avatar sx={{ width: 24, height: 24 }}>{user.adminName[0]}</Avatar>
                    <Typography>{user.adminName}</Typography>
                  </Paper>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </>
  );
};

export default UnassignedUsersList;
