//@ts-nocheck
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography
} from '@mui/material';

const DeleteConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  isDeleteTeamLoading,
  allowActions
}: any) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Team</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this team?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={!allowActions || isDeleteTeamLoading}>
          Cancel
        </Button>
        <Button onClick={onConfirm} color="error" disabled={!allowActions || isDeleteTeamLoading}>
          {isDeleteTeamLoading ? <CircularProgress size={24} /> : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
