import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, CircularProgress, Input, Paper } from '@mui/material';
import axios from 'axios';

import { UploadIcon } from 'components/icons';
import { useLazyGetDownloadUrlQuery, useLazyGetUploadUrlQuery } from 'app/services/files';
import { openToastMessage } from 'app/slices/toastMessageSlice';

interface UploadFieldProps {
  onSuccess: (name: string) => void;
  fileKey?: string;
  width?: string;
  height?: string;
  readOnly?: boolean;
}

const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

const UploadField: React.FC<UploadFieldProps> = ({
  fileKey,
  onSuccess,
  width = '150px',
  height = '150px',
  readOnly = false
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [imgLink, setImgLink] = useState<any>(null);

  const dispatch = useDispatch();
  const [getUploadURl] = useLazyGetUploadUrlQuery();
  const [getDownloadUrl] = useLazyGetDownloadUrlQuery();

  useEffect(() => {
    const fetchImagePreview = async () => {
      try {
        if (fileKey) {
          const imagePreview = await getDownloadUrl({ fileKey: fileKey });
          return imagePreview?.data;
        }
      } catch (error) {
        console.error('Error fetching image preview:', error);
      }
    };

    const updateImagePreview = async () => {
      const imageData = await fetchImagePreview();
      setImgLink(imageData);
    };

    if (fileKey && urlRegex.test(fileKey)) {
      setImgLink(fileKey);
    } else updateImagePreview();

    return () => {};
  }, [getDownloadUrl, fileKey]);

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setProgress(1);
    setLoading(() => true);
    const fileType = file?.type.split('/')[0];
    const fileExt = file?.type;

    if (fileType !== 'image') {
      dispatch(
        openToastMessage({
          type: 'error',
          message: 'Only images will be accepted'
        })
      );
      return;
    }

    if (file && file.size > 1024 * 1024) {
      dispatch(
        openToastMessage({
          type: 'error',
          message: 'File size must not exceed 1 MB.'
        })
      );
      return;
    }

    try {
      const { data } = await getUploadURl({ fileExt });
      setLoading(() => false);

      if (data?.url) {
        const response = await axios.put(data.url, file, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            setProgress(Math.floor((progressEvent?.progress || 0) * 100));
          }
        });

        const imagePreview = await getDownloadUrl({ fileKey: data.fileKey });
        setImgLink(imagePreview.data);

        if (response.status === 200 || response.status === 201) {
          onSuccess(data.fileKey);
        } else {
          // Handle the case where the upload did not succeed
          dispatch(
            openToastMessage({
              type: 'error',
              message: 'File upload failed'
            })
          );
          console.error('File upload failed', response);
        }
      }
    } catch (error) {}
  };

  return (
    <Box
      component={Paper}
      variant="outlined"
      sx={{
        width,
        height,
        position: 'relative',
        border: '1px dashed black',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
      }}
      // onClick={() => document.getElementById('fileInput')?.click()}
    >
      {!readOnly && (
        <Input
          type="file"
          // id='fileInput'
          onChange={handleUpload}
          inputProps={{ accept: '.jpg, .jpeg, .png' }}
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            opacity: 0,
            zIndex: 555,
            cursor: 'default'
          }}
        />
      )}

      {imgLink ? (
        <Box
          component="img"
          src={imgLink}
          alt="img"
          sx={{
            height: '100%',
            width: '100%',
            left: 0,
            top: 0,
            objectFit: 'contain',
            zIndex: 554
          }}
        />
      ) : progress === 0 ? (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 553,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <UploadIcon />
        </Box>
      ) : loading ? (
        <CircularProgress />
      ) : (
        <CircularProgress variant="determinate" value={progress} />
      )}
    </Box>
  );
};

export default UploadField;
